import { useCallback, useEffect, useMemo } from 'react';

import { WithPagination } from 'lib/entities';
import { $Object } from 'lib/object';

import { useRequest } from './useRequest';
import { useStateHandlers } from './useStateHandlers';
import { useIntersectionObserver } from './useIntersectionObserver';

interface UseMappingDataOptions<T> {
  targetRef: React.RefObject<HTMLElement | undefined | null>;
  request: (params: $Object) => Promise<WithPagination<T[]>>;
  pageSize?: number;
}

export function useMappingData<T>({ targetRef, request, pageSize = 50 }: UseMappingDataOptions<T>) {
  const [state, setState] = useStateHandlers({ page: 1 });

  const apiCall = useRequest<WithPagination<T[]>>({
    data: { results: [] },
  });

  const loadInitialData = useCallback(() => {
    apiCall.request(request({ page_size: pageSize, page: state.page }), state.page > 1 ? { concat: 'results' } : {});
  }, [state.page, pageSize]);

  useEffect(() => {
    loadInitialData();
  }, [loadInitialData]);

  const hasMore = useMemo(() => !apiCall.loading && Math.ceil(apiCall.data?.count / pageSize) > state.page, [
    apiCall.loading,
    apiCall.data,
    state.page,
  ]);

  useIntersectionObserver({
    targetRef,
    onIntersect: () => setState(prevState => ({ page: ++prevState.page })),
    enabled: hasMore,
  });

  return {
    ...apiCall,
    reset: () => {
      setState({ page: 1 });
      loadInitialData();
    },
  };
}
