import { useIntl } from 'estafette-intl';
import { OrdersApi } from 'lib/api';
import { UTMFieldSelect, UTMFieldSelectProps } from './UTMFieldSelect';

interface UTMMediumSelectProps extends Omit<UTMFieldSelectProps, 'fieldName' | 'apiCall' | 'itemName'> {}

export const UTMMediumSelect = (props: UTMMediumSelectProps) => {
  const { t } = useIntl();
  return (
    <UTMFieldSelect fieldName="utm_medium" apiCall={OrdersApi.getUtmMedium} itemName={t('utmMedium')} {...props} />
  );
};
