import { Request } from '../../index';

export const SubscriptionsApi = {
  list: (data: object) => {
    return Request.get('/education/dashboard/subscriptions/', data);
  },
  create: (data: object) => {
    return Request.post('/education/dashboard/subscriptions/', data);
  },
  bulkCreate: (data: any) => {
    return Request.post('/education/dashboard/subscriptions/bulk-create/', data);
  },
  byId: (id: string, data: object) => {
    return Request.get(`/education/dashboard/subscriptions/${id}/`, { ...data });
  },
  update: (id: string, data: object) => {
    return Request.patch(`/education/dashboard/subscriptions/${id}/`, { ...data });
  },
  partialUpdate: (id: string, data: object) => {
    return Request.patch(`/education/dashboard/subscriptions/${id}/`, { ...data });
  },
  delete: (id: string) => {
    return Request.delete(`/education/dashboard/subscriptions/${id}/`);
  },
  programSubscriptions: (id: string, data: object) => {
    return Request.get(`/education/dashboard/programs/subscriptions/`, data);
  },
  usersSubscriptions: (data: object) => {
    return Request.get(`/education/dashboard/all_subscribed_users/`, data);
  },
  userSubscriptions: (id: string, data: object) => {
    return Request.get(`/education/dashboard/users/${id}/subscriptions/`, data);
  },
  subscriberById: (programId: string, userId: string, data: object) => {
    return Request.get(`/education/dashboard/programs/${programId}/subscribed_users/${userId}/`, data);
  },
};
