import React, { useContext, useEffect } from 'react';
import moment from 'moment';
import { Button, message, Modal, Row, Select } from 'antd';
import { useIntl } from 'estafette-intl';
import { ProgramComment } from 'lib/entities/comments/programs';
import { EODComment } from 'lib/entities/comments/eod';
import { MobileComment } from '../MobileComment/MobileComment';
import { UserShort } from 'lib/api/users/users.types';
import { useStateHandlers } from 'hooks';
import { CommentsEpisodes, CommentsPrograms, EpisodeReplies } from 'lib/api';
import { ReplyTypes } from '../../index.type';
import { EpisodeReply } from 'lib/api/episode-replies/episodeReplies.types';

import { ProfileContext, UsersContext } from 'contexts';

import './MobilePreview.scss';

interface Props {
  comment: ProgramComment | EODComment | EpisodeReply;
  selectedAuthor?: UserShort;
  lastCommentText: string;
  onRefresh?: () => void;
  onReplySave?: () => void;
  type: ReplyTypes;
  onCommentChange?: (comment: EpisodeReply) => void;
  noPublish?: boolean;
}

interface State {
  localComment: ProgramComment | EODComment | EpisodeReply;
  showSetReviewResponsibleModal: boolean;
  reviewResponsibleId?: number;
  reviewLoading: boolean;
  replyLoading: boolean;
}

export const MobilePreview = ({
  comment,
  selectedAuthor,
  lastCommentText,
  onRefresh,
  onReplySave,
  type,
  onCommentChange,
  noPublish,
}: Props) => {
  const {
    admin: { data: admin },
  } = useContext(ProfileContext);
  const { councilors } = useContext(UsersContext);
  const { t } = useIntl();

  const [state, setState] = useStateHandlers<State>({
    localComment: comment,
    showSetReviewResponsibleModal: false,
    reviewLoading: false,
    replyLoading: false,
  });

  useEffect(() => {
    const generateNewComment = () => {
      setState({
        localComment: {
          ...('replies' in comment
            ? comment
            : { ...comment, author: comment.parent.author, text: comment.parent.text }),
          replies: [
            ...('replies' in comment ? comment.replies : []),
            {
              id: 0,
              text: lastCommentText,
              created_at: moment()
                .add(1, 'm')
                .toISOString(),
              modified_at: moment().toISOString(),
              author: selectedAuthor || {
                id: 0,
                full_name: admin.full_name,
                first_name: '',
                last_name: '',
                email: admin.email,
                groups: admin.groups,
                photo: admin.photo,
                is_staff: true,
              },
              created_by: {
                id: 0,
                full_name: admin.full_name,
                first_name: '',
                last_name: '',
                email: admin.email,
                groups: admin.groups,
                photo: admin.photo,
                is_staff: true,
              },
              archive: false,
              photos: [],
              published: true,
            },
          ],
        },
      });
    };

    generateNewComment();
  }, [comment, lastCommentText, selectedAuthor]);

  const onReviewResponsibleModalChange = () =>
    setState(prevState => ({ showSetReviewResponsibleModal: !prevState.showSetReviewResponsibleModal }));
  const onReviewResponsibleChange = (id: number) => setState({ reviewResponsibleId: id });

  const onReviewResponsibleSubmit = async () => {
    setState({ reviewLoading: true });

    const responsibleId =
      ('reply_review_responsible' in comment &&
        comment.reply_review_responsible &&
        comment.reply_review_responsible.id) ||
      state.reviewResponsibleId;

    if (responsibleId) {
      const savedReply = await onSubmit();

      EpisodeReplies.sentForReview(savedReply.id, { reply_review_responsible: responsibleId })
        .then(reply => {
          onCommentChange && onCommentChange(reply);
          onReplySave && onReplySave();
          onRefresh && onRefresh();

          message.success('Reply was send');
        })
        .catch(() => message.error('Server error'))
        .finally(() => setState({ reviewLoading: false }));
    }
  };

  const onPublish = async () => {
    setState({ replyLoading: true });

    const savedReply = await onSubmit();

    if (type !== 'eod') {
      EpisodeReplies.publish(savedReply.id)
        .then(reply => {
          onCommentChange && onCommentChange(reply);
          onReplySave && onReplySave();
          onRefresh && onRefresh();

          message.success('Reply was published');
        })
        .catch(() => message.error('Server error'))
        .finally(() => setState({ replyLoading: false }));
    } else {
      onReplySave && onReplySave();
      onRefresh && onRefresh();

      message.success('Reply was published');
    }
  };

  const onSubmit = async () =>
    noPublish
      ? comment
      : await (type === ReplyTypes.eod ? CommentsEpisodes : CommentsPrograms).createReply(
          { text: lastCommentText, author: selectedAuthor?.id },
          comment.id,
        );

  return (
    <>
      {state.showSetReviewResponsibleModal && (
        <Modal
          visible={true}
          onCancel={onReviewResponsibleModalChange}
          footer={null}
          className="m-mobile-preview__responsible-modal"
          closable={false}
          title={t('chooseAReviewResponsible')}
        >
          <Select
            className="m-mobile-preview__responsible-select"
            placeholder={t('selectA', { item: t('responsible') })}
            onChange={onReviewResponsibleChange}
          >
            {councilors.data?.data.map(i => (
              <Select.Option key={i.id} value={i.id}>
                {i.full_name} ({i.email})
              </Select.Option>
            ))}
          </Select>

          <Row type="flex" justify="center">
            <Button
              onClick={onReviewResponsibleSubmit}
              disabled={!state.reviewResponsibleId}
              className="m-mobile-preview__responsible-submit"
              type="primary"
              loading={state.reviewLoading}
            >
              {t('send')}
            </Button>
          </Row>
        </Modal>
      )}

      <div className="m-mobile-preview">
        <div className="m-mobile-comments__wrapper">
          <MobileComment comment={state.localComment} language={comment.language} />

          {'replies' in state.localComment &&
            state.localComment.replies.length > 0 &&
            state.localComment.replies.map(i => <MobileComment comment={i} language={comment.language} isReply />)}
        </div>

        <Row className="m-mobile-preview__reply">
          <Button
            htmlType="submit"
            onClick={
              'reply_review_responsible' in comment &&
              comment.reply_review_responsible &&
              comment.reply_review_responsible.id
                ? onReviewResponsibleSubmit
                : onReviewResponsibleModalChange
            }
            type="primary"
          >
            {t('sentForReview')}
          </Button>

          {!noPublish && (
            <Button htmlType="submit" onClick={onPublish} type="primary" loading={state.replyLoading} className="ml-15">
              {t(type !== 'eod' ? 'publish' : 'reply')}
            </Button>
          )}
        </Row>
      </div>
    </>
  );
};
