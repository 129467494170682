import Loadable from 'react-loadable';
import { LoadingPlaceholder } from 'ui/atoms';

import { Route } from 'lib/router/routes';

export const faq: Route[] = [
  {
    path: '/faq/list',
    text: 'list',
    component: Loadable({
      loader: () => import('../../../../features/faq/pages/FaqList'),
      loading: LoadingPlaceholder,
      delay: 300,
    }),
    children: [],
    groups: [],
  },
  {
    path: '/faq/categories',
    text: 'categories',
    component: Loadable({
      loader: () => import('../../../../features/faq/pages/FaqCategoriesList'),
      loading: LoadingPlaceholder,
      delay: 300,
    }),
    children: [],
    groups: [],
  },

  {
    path: '/faq/add',
    text: null,
    icon: 'question-circle',
    component: Loadable({
      loader: () => import('../../../../features/faq/pages/AddFaqPage'),
      loading: LoadingPlaceholder,
      delay: 300,
    }),
    children: [],
    groups: [],
  },
  {
    path: '/faq/edit/:id',
    text: null,
    icon: 'question-circle',
    component: Loadable({
      loader: () => import('../../../../features/faq/pages/EditFaqPage'),
      loading: LoadingPlaceholder,
      delay: 300,
    }),
    children: [],
    groups: [],
  },
  {
    path: '/faq/categories/add',
    text: null,
    icon: 'question-circle',
    component: Loadable({
      loader: () => import('../../../../features/faq/pages/AddFaqCategoryPage'),
      loading: LoadingPlaceholder,
      delay: 300,
    }),
    children: [],
    groups: ['warehouse_admin'],
  },
  {
    path: '/faq/categories/edit/:id',
    text: null,
    icon: 'question-circle',
    component: Loadable({
      loader: () => import('../../../../features/faq/pages/EditFaqCategoryPage'),
      loading: LoadingPlaceholder,
      delay: 300,
    }),
    children: [],
    groups: ['warehouse_admin'],
  },
];
