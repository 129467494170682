import { useMemo } from 'react';

import { Order } from 'lib/entities/payments-management/orders';

export const useOrderPaymentInfo = (orders: Order[]) =>
  useMemo(() => {
    const results = orders.filter(({ billing_status }) => !['error', 'refunded', 'archived'].includes(billing_status));

    return (
      results?.find(({ billing_status }) => billing_status === 'active') ||
      results?.find(({ billing_status }) => billing_status === 'suspended') ||
      results?.find(({ billing_status }) => billing_status === 'completed') ||
      (results && results[0])
    );
  }, [orders]);
