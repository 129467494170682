import Loadable from 'react-loadable';
import { LoadingPlaceholder } from 'ui/atoms';

import { Route } from 'lib/router/routes';

export const pBot: Route[] = [
  {
    path: '/p-bot',
    text: 'config',
    icon: 'setting',
    component: Loadable({
      loader: () => import('../../../../features/p-bot/pages/pBotPage'),
      loading: LoadingPlaceholder,
      delay: 300,
    }),
    children: [],
  },
  {
    path: '/p-bot/edit/:id',
    text: null,
    icon: 'setting',
    component: Loadable({
      loader: () => import('../../../../features/p-bot/pages/pBotEditPage'),
      loading: LoadingPlaceholder,
      delay: 300,
    }),
    children: [],
  },
  {
    path: '/p-bot/add',
    text: null,
    icon: 'setting',
    component: Loadable({
      loader: () => import('../../../../features/p-bot/pages/pBotAddPage'),
      loading: LoadingPlaceholder,
      delay: 300,
    }),
    children: [],
  },
  {
    path: '/p-bot-export',
    text: 'export',
    icon: 'export',
    component: Loadable({
      loader: () => import('../../../../features/p-bot/pages/pBotExportPage'),
      loading: LoadingPlaceholder,
      delay: 300,
    }),
    children: [],
  },
  {
    path: '/p-bot-statistic',
    text: 'statistics',
    icon: 'table',
    component: Loadable({
      loader: () => import('../../../../features/p-bot/pages/pBotStatisticPage'),
      loading: LoadingPlaceholder,
      delay: 300,
    }),
    children: [],
  },
];
