import { forwardRef, useEffect, useMemo } from 'react';
import { Row, Select, Spin } from 'antd';
import { useIntl } from 'estafette-intl';
import { SelectProps } from 'antd/lib/select';

import { useRequest, useStateHandlers } from 'hooks';
import { InfiniteScroll } from 'ui/molecules';
import { OrdersApi } from 'lib/api';
import { pageSize } from 'lib/globalVars';
import { WithPagination } from 'lib/entities';
import { Order } from 'lib/entities/payments-management/orders';

interface StateType {
  page: number;
  query?: string;
  orders: number | number[];
}

const { Option } = Select;

interface OrdersSelectProps extends SelectProps<number | number[]> {
  query?: string;
  parentsNodeClassName?: string;
  userId?: string;
  onOrderChange?: (orders?: Order[]) => void;
  isAutoSetup?: boolean;
}

export const OrdersSelect = forwardRef<Select<number | number[]>, OrdersSelectProps>(
  ({ query, parentsNodeClassName, userId, onOrderChange, isAutoSetup, ...props }, ref) => {
    const { t } = useIntl();
    const orders = useRequest<WithPagination<Order[]>>({ data: {} });
    const [state, setState] = useStateHandlers<StateType>({ page: 1, orders: [], query });

    useEffect(() => {
      orders.request(
        OrdersApi.list({
          page_size: pageSize,
          page: state.page,
          search: state.query,
          user_id: userId,
        }),
        state.page > 1 ? { concat: 'results' } : {},
      );
    }, [state.page, state.query, userId]);

    useEffect(() => {
      if (!isAutoSetup || orders.data.results?.length !== 1 || state.query) return;
      props.onChange?.(+orders.data.results[0].id, <></>);
    }, [orders.data.results, isAutoSetup]);

    useEffect(() => {
      setState({ query });
    }, [query]);

    useEffect(() => {
      const selectedIds = Array.isArray(props.value) ? props.value : [props.value];
      const ord = orders.data.results?.filter(({ id }) => selectedIds.includes(+id));

      onOrderChange?.(ord);
    }, [props.value]);

    const hasMore = useMemo(() => !orders.loading && Math.ceil(orders.data.count / pageSize) > state.page, [
      orders.loading,
      orders.data,
      state.page,
    ]);

    const onPageChange = () => {
      if (hasMore) {
        setState(prevState => ({ page: ++prevState.page }));
      }
    };

    const onSearch = (query?: string) => {
      setState({ page: 1, query });
    };

    const onChange = (orders: number | number[]) => {
      setState({ orders });
    };

    return (
      <Select
        ref={ref}
        loading={orders.loading}
        placeholder={t('pleaseSelectAn', { item: t('order').toLowerCase() })}
        showSearch
        allowClear
        filterOption={() => true}
        onSearch={onSearch}
        onChange={onChange}
        style={{ width: '100%' }}
        onDropdownVisibleChange={() => onSearch()}
        dropdownClassName={parentsNodeClassName}
        dropdownRender={menu => (
          <InfiniteScroll
            onLoadMore={onPageChange}
            parentsNodeClassName={parentsNodeClassName}
            nodeClassName="ant-select-dropdown-menu"
            hasMore={true}
          >
            {menu}
            {orders.loading && (
              <Row type="flex" justify="center">
                <Spin style={{ height: 30 }} />
              </Row>
            )}
          </InfiniteScroll>
        )}
        {...props}
      >
        {orders.data.results?.map(({ id, price }) => (
          <Option key={id} value={id}>
            {price.product?.name ? `ID: ${id}, Name: ${price.product?.name} (${price.language?.toUpperCase()})` : id}
          </Option>
        ))}
      </Select>
    );
  },
);
