import { forwardRef, useEffect, useMemo } from 'react';
import { Row, Select, Spin, Tooltip } from 'antd';
import { SelectProps } from 'antd/lib/select';
import { useRequest, useStateHandlers } from 'hooks';
import { InfiniteScroll } from 'ui/molecules';
import { pageSize } from 'lib/globalVars';
import { $Object } from 'lib/object';

interface StateType {
  page: number;
  query?: string;
  types: number | number[];
}

const { Option } = Select;

interface InfiniteScrollSelectProps extends SelectProps<number | number[] | undefined> {
  query?: string;
  parentsNodeClassName?: string;
  orderId?: string;
  isAutoSetup?: boolean;
  defaultLabel?: $Object[];
  getListApi: (data: $Object) => Promise<any>;
  searchKey?: string;
  customParams?: $Object;
  optionLabel?: string;
  optionSubLabel?: string;
  hasTooltip?: boolean;
  onResetType?: (existType: boolean) => void;
}

export const InfiniteScrollSelect = forwardRef<Select<number | number[]>, InfiniteScrollSelectProps>(
  (
    {
      query,
      parentsNodeClassName,
      isAutoSetup,
      defaultLabel,
      getListApi,
      searchKey,
      customParams,
      optionLabel = 'name',
      optionSubLabel = 'language',
      onResetType,
      hasTooltip = false,
      ...props
    },
    ref,
  ) => {
    const queryRequest = useRequest<any>({ data: [] });

    const [state, setState] = useStateHandlers<StateType>({ page: 1, types: [], query });

    useEffect(() => {
      queryRequest?.request(
        getListApi?.({
          page_size: pageSize,
          page: state.page,
          ...(searchKey && { [searchKey]: state.query }),
          ...customParams,
        }),
        state.page > 1 ? { concat: 'results' } : {},
      );
    }, [state.page, state.query]);

    useEffect(() => {
      if (!isAutoSetup || queryRequest.data?.count !== 1 || state.query) return;
      props.onChange?.(queryRequest?.data?.results[0].id, <></>);
    }, [queryRequest?.data, isAutoSetup]);

    useEffect(() => {
      setState({ query });
    }, [query]);

    useEffect(() => {
      const value = Array.isArray(props.value) ? props.value : [props.value];

      const existType = props?.value
        ? queryRequest?.data?.results?.some(({ id }: $Object) => value.includes(id))
        : true;

      onResetType?.(existType);
    }, [queryRequest.data]);

    const hasMore = useMemo(
      () => !queryRequest?.loading && Math.ceil(queryRequest?.data?.count / pageSize) > state?.page,
      [queryRequest.loading, queryRequest.data, state.page, defaultLabel],
    );

    const onPageChange = () => {
      if (hasMore) {
        setState(prevState => ({ page: ++prevState.page }));
      }
    };

    const onSearch = (query?: string) => {
      setState({ page: 1, query });
    };

    const defaultOptions = useMemo(() => {
      if (state?.query?.length) {
        return defaultLabel?.filter(type => type?.name?.toLowerCase().includes(state?.query?.toLowerCase() as string));
      }
      return defaultLabel;
    }, [state.query, defaultLabel]);

    const options = useMemo(() => {
      const combinedTypes = [
        ...(defaultOptions || []),
        ...(queryRequest?.data?.results || queryRequest?.data?.data || []),
      ];

      const uniqueIds = new Set();

      const filteredOptions = combinedTypes?.filter(type => {
        if (!uniqueIds?.has(type?.id)) {
          uniqueIds?.add(type?.id);
          return true;
        } else {
          return false;
        }
      });

      return filteredOptions;
    }, [queryRequest?.data, defaultOptions]);

    return (
      <Select
        ref={ref}
        loading={queryRequest.loading}
        showSearch={!!searchKey}
        allowClear
        filterOption={() => true}
        onSearch={onSearch}
        style={{ width: '100%' }}
        onDropdownVisibleChange={() => onSearch()}
        dropdownClassName={parentsNodeClassName}
        dropdownRender={menu => (
          <InfiniteScroll onLoadMore={onPageChange} isDirectChild hasMore={true}>
            {menu}

            {queryRequest.loading && (
              <Row type="flex" justify="center">
                <Spin style={{ height: 30 }} />
              </Row>
            )}
          </InfiniteScroll>
        )}
        {...props}
      >
        {options?.map(option => {
          const label = `${option?.[optionLabel]}${optionSubLabel && ` (${option?.[optionSubLabel]?.toUpperCase()})`}`;

          return (
            <Option key={option.id} value={option.id}>
              <Tooltip title={hasTooltip && label}>{label}</Tooltip>
            </Option>
          );
        })}
      </Select>
    );
  },
);
