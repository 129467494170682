import { Request } from '../index';

export const FamilyApi = {
  members: (params: object) => {
    return Request.get('/family/dashboard/members/', params);
  },
  updateMember: (id: number, data: object) => {
    return Request.patch(`/family/dashboard/members/${id}/`, data);
  },
  delete: (data: object) => {
    return Request.post('/family/dashboard/delete_members/', data);
  },
};
