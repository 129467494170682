import { message } from 'antd';
import moment from 'moment';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import { RangePickerValue } from 'antd/lib/date-picker/interface';
import { default as queryString } from 'query-string';
import { AxiosError } from 'axios';

import { getProperty, deepKeys } from 'dot-prop';

import { TFunction } from 'types';

import { $Object } from './object';
import { includePaymentMethods, languages } from './globalVars';
import { UserGroups } from './entities/profile';
import { PaymentTypes, SavedPayment } from './entities/payments-management/merchants';

export interface withPagination<T> {
  count: number;
  next: string;
  previous: string;
  results: T[];
}

interface SetFieldsErrorOptions {
  parentName?: string;
  emptyField?: boolean;
  deep?: boolean;
  keepValues?: boolean;
}

export const setFieldsError = (
  fieldsError: any,
  form: WrappedFormUtils,
  { parentName = '', emptyField = false, deep = false, keepValues = false }: SetFieldsErrorOptions = {},
) => {
  const { getFieldsValue, setFields } = form;
  const currentField = getFieldsValue && getFieldsValue();
  const newFields: any = {};

  const currentFieldsKeys = currentField && Object.keys(currentField);
  const fieldsErrorKeys = Object.keys(fieldsError || {});

  const searchKeys = emptyField ? currentFieldsKeys : JSON.stringify(currentField);

  const existingKeys = fieldsErrorKeys.filter(i => searchKeys?.includes(i));

  if (existingKeys.length > 0) {
    !deep
      ? Object.keys(fieldsError).forEach(key => {
          newFields[parentName ? `${parentName}.${key}` : key] = {
            value: keepValues ? getProperty(currentField, parentName ? `${parentName}.${key}` : key) : undefined,
            errors: Array.isArray(fieldsError[key]) && fieldsError[key].map((msg: string) => new Error(msg)),
          };
        })
      : deepKeys(fieldsError).forEach(key => {
          newFields[
            key
              .replace(/\[[0-9]+\]$/, '')
              .replace('[', '.')
              .replace(']', '')
          ] = {
            errors: [new Error(getProperty(fieldsError, key))],
          };
        });

    setFields(newFields);
  } else {
    const missingKeys = fieldsErrorKeys.filter(i => !currentFieldsKeys?.includes(i));

    return getLastKeys(missingKeys.reduce((acc, i) => ({ ...acc, [i]: fieldsError[i] }), {}));
  }
};

export const getApiError = (thrownError: any) => thrownError?.apiError?.error;
export const getAxiosErrorData = (thrownError: any) => (thrownError as AxiosError)?.response?.data;

type GetMessageFromErrorOptions = {
  fallbackToSomethingWentWrong?: boolean;
};

export const getMessageFromError = (
  error: any,
  t: TFunction,
  options?: GetMessageFromErrorOptions,
): string | string[] | null => {
  if (error?.detail?.reason === 'already_exists') {
    return t('itemAlreadyExists');
  }
  if (error?.detail) {
    return error?.detail;
  }
  if (options?.fallbackToSomethingWentWrong) {
    return t('somethingWentWrong');
  }
  return null;
};

export const getUnknownError = (errorObj: any) => {
  const errorKeys = [];

  for (const key in errorObj) {
    if (Array.isArray(errorObj?.hasOwnProperty(key))) {
      errorKeys.push(...errorObj[key]);
    } else {
      errorKeys.push(errorObj[key]);
    }
  }

  return errorKeys;
};

/**
 * Extracts errors from API and sets the fields error for each field correspondingly
 *
 * @example
 *
 * setFieldsErrorSimple({
 *  delivery_address: {
 *    contact_phone: ["This phone nr is not valid"]
 *  }
 * }, from)
 *
 * // The resulting fields
 * {
 *   delivery_address.contact_phone: {
 *     value: `<keeps the value>`,
 *     errors: [new Error("This phone nr is not valid")]
 *   }
 * }
 */
export const setFieldsErrorSimple = (fieldsError: any, form: WrappedFormUtils) => {
  const erroredFieldsKeys = limitedDeepKeys(fieldsError, value => value?.constructor === Object);
  const getErrors = (errorMessages: string | string[]) => {
    if (!(errorMessages instanceof Array)) errorMessages = [errorMessages];
    return errorMessages.map(message => new Error(message));
  };

  const formFieldsEntries = erroredFieldsKeys.map(fieldKey => [
    fieldKey,
    {
      value: form.getFieldValue(fieldKey),
      errors: getErrors(getProperty(fieldsError, fieldKey) || []),
    },
  ]);

  form.setFields(Object.fromEntries(formFieldsEntries));
};

interface NotifyOrSetFieldsErrorOptions {
  error: any;
  t: TFunction;
  form: WrappedFormUtils;
  parentName?: string;
  keepValues?: boolean;
}

export const notifyOrSetFieldsError = ({ error, t, form }: NotifyOrSetFieldsErrorOptions) => {
  const messageFromError = getMessageFromError(error, t);
  const unknownErrorsMessages = getUnknownError(error);

  if (messageFromError) {
    message.error(messageFromError);
    return;
  }

  if (unknownErrorsMessages) {
    message.error(unknownErrorsMessages?.[0]);
  }

  if (error) {
    setFieldsErrorSimple(error, form);

    return;
  }

  message.error(t('somethingWentWrong'));
};

const getLastKeys = (obj: $Object): {} =>
  Object.keys(obj).reduce(
    (acc, i) => (obj[i] instanceof Object && !obj[i].length ? getLastKeys(obj[i]) : { ...acc, [i]: obj[i] }),
    {},
  );

export const timeToInteger = (time: string) => {
  if (time) {
    if (time.includes('s')) return parseInt(time);
    let parts = time.split(':').map(item => parseInt(item));

    return (parts[0] !== 0 ? parts[0] * 3600 : 0) + (parts[1] !== 0 ? parts[1] * 60 : 0) + parts[2];
  }
  return 0;
};

export const withSpace = (words: string[]) => {
  return words.map((item: string) => item + ' ');
};

export const contains = (target: string, pattern: string[]) => {
  let value = 0;
  pattern.forEach(function(word) {
    if (target.includes(word)) {
      value = 1;
    }
  });
  return value === 1;
};

export const maxLengthValidation = (newText: string) => {
  if (newText && newText.length < 5000) {
    return newText;
  }
  return newText.slice(0, 5000);
};

export const getWebsiteRoute = (uri: string) => `${process.env.REACT_APP_WEBSITE_URL}${uri}`;

export const putIfExist = (val?: React.ReactNode) => (val ? val : '—');
export const formatIfValid = (date: string | null | undefined, format = dateTimeFormat) =>
  date ? moment(date).format(format) : '—';

export function parseQuery(queryString: string): $Object {
  if (queryString !== '') {
    const query: $Object = {};
    const pairs = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&');

    let i = 0;
    for (i; i < pairs.length; i += 1) {
      const pair = pairs[i].split('=');

      if (query[decodeURIComponent(pair[0])]) {
        Array.isArray(query[decodeURIComponent(pair[0])])
          ? query[decodeURIComponent(pair[0])].push(decodeURIComponent(pair[1] || ''))
          : (query[decodeURIComponent(pair[0])] = [
              query[decodeURIComponent(pair[0])],
              decodeURIComponent(pair[1] || ''),
            ]);
      } else {
        query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
      }
    }

    return query;
  }

  return {};
}

export const inArray = (word: string, array: string[]) => array.find(i => i === word);

export const ifArrayInArray = (firstArray: string[], secondArray: string[]) =>
  firstArray.find(firstItem => !!secondArray.find(secondArray => secondArray === firstItem));

export const getLanguageName = (lang: string) => {
  const langName = languages.find(i => i === lang);

  if (langName) {
    return langName;
  }

  return;
};

export const capitalize = (s: string) => s.charAt(0).toUpperCase() + s.slice(1);

export const objToQuery = (obj: $Object, options: { array?: 'withKeys' | 'withComa' } = {}) => {
  const result = Object.keys(obj).map(i => {
    if (obj[i] instanceof Array && options.array === 'withKeys') {
      return obj[i].map((value: string) => `${encodeURIComponent(i)}=${encodeURIComponent(value)}`).join('&');
    }

    return `${encodeURIComponent(i)}=${encodeURIComponent(obj[i])}`;
  });

  return `${result.length > 0 ? '?' : ''}${result.join('&')}`;
};

export const getHtmlPage = (content: string) =>
  `<head><meta name="viewport" content="width=device-width, initial-scale=1.0"><link rel="preload" href="https://tribes-web.devebs.net/fonts/SFProDisplay-Medium.ttf" as="font" crossorigin> <style> img{max-width:100%;}</style></head> <body>${content}</body>`;

export function keys<O extends $Object>(o: O): (keyof O)[] {
  return Object.keys(o) as (keyof O)[];
}

export function getFirstElement<E>(arr: E[]): E | undefined {
  return arr.length ? arr[0] : undefined;
}

export const hoursToDays = (num: number) => {
  const days = Number((num / 24).toFixed());
  const hours = Number((num % 24).toFixed());

  return `${days ? `${days}d` : ''} ${hours ? `${hours}h` : ''}`;
};

export const replaceParamsInSearch = (
  search: string,
  params: { [key: string]: string | number | string[] | number[] },
): string => {
  const queryString = new URLSearchParams(search);
  return `?${new URLSearchParams({ ...queryString, ...params }).toString()}`;
};

export const hasPage = (search: string): boolean => {
  const queryString = new URLSearchParams(search);
  return Boolean(queryString.get('page'));
};

export const copyToClipboard = (str: string): Promise<void> => {
  if (navigator.clipboard) {
    try {
      return navigator.clipboard.writeText(str);
      // eslint-disable-next-line no-empty
    } catch (err) {}
  }

  return Promise.resolve(deprecatedCopyToClipboard(str));

  function deprecatedCopyToClipboard(str: string): void {
    const el = document.createElement('textarea');

    el.value = str;
    document.body.appendChild(el);

    el.select();

    document.execCommand('copy');
    document.body.removeChild(el);
  }
};

export const convertToSlug = (value: string) => {
  return value
    .toLowerCase()
    .replace(/ /g, '-')
    .replace(/[^\w-]+/g, '');
};

export const getPriorityRole = (groups: UserGroups[]): UserGroups | undefined => {
  if (groups.includes(UserGroups.administrator)) {
    return UserGroups.administrator;
  }

  if (groups.includes(UserGroups.moderator)) {
    return UserGroups.moderator;
  }

  if (groups.includes(UserGroups.contentCreator)) {
    return UserGroups.contentCreator;
  }

  if (groups.includes(UserGroups.consilier)) {
    return UserGroups.consilier;
  }

  if (groups.includes(UserGroups.warehouseAdmin)) {
    return UserGroups.warehouseAdmin;
  }

  if (groups.includes(UserGroups.customerCare)) {
    return UserGroups.customerCare;
  }

  return;
};

export const addToURL = (url: string, params: { [key: string]: any }): string => {
  const parsedUrl = queryString.parseUrl(url);

  return queryString.stringifyUrl(
    { ...parsedUrl, query: { ...parsedUrl.query, ...params } },
    {
      skipEmptyString: true,
      skipNull: true,
    },
  );
};

export const b64EncodeUnicode = (str: string | number) => {
  return encodeURIComponent(btoa(str.toString()));
};

export const slugify = (str: string) =>
  str
    .toLowerCase()
    .trim()
    .replace(/[^\w\s-]/g, '')
    .replace(/[\s_-]+/g, '-')
    .replace(/^-+|-+$/g, '');

const limitedDeepKeys = (
  object: object,
  // Avoid going through dates objects or other instances of classes
  shouldGoDeeper = (value: any) => [Object, Array].includes(value?.constructor),
) => {
  const result: string[] = [];
  const goThrough = (object: object, path = '') => {
    Object.entries(object).forEach(([key, value]) => {
      if (shouldGoDeeper(value)) {
        goThrough(value, path ? path + '.' + key : key);
      } else {
        result.push(path ? path + '.' + key : key);
      }
    });
  };
  goThrough(object);
  return result;
};

export const resetErrorFromServer = (form: WrappedFormUtils, changedValues: $Object) => {
  limitedDeepKeys(changedValues).forEach(name => {
    if (!form.getFieldError(name)) return;
    form.setFields({ [name]: { errors: undefined } });
  });
};

export const formatDateFromString = (text: string, reg = /\{(.*?)\}/g) => {
  const format = 'YYYY-MM-DD HH:mm:ss';

  return text.replaceAll(reg, (init, content) => (moment(content).isValid() ? moment(content).format(format) : init));
};

export const setDefaultDateRange = (
  dateGte?: string | null,
  dateLte?: string | null,
  dateForm = 'YYYY-MM-DD',
): RangePickerValue | undefined =>
  dateGte !== undefined ? [moment(dateGte, dateForm), moment(dateLte, dateForm)] : undefined;

export const getPaymentsByGroup = (paymentMethods: SavedPayment[]) =>
  Object.entries(
    paymentMethods.reduce((prev, next) => {
      if (!includePaymentMethods.includes(next.type)) return prev;
      prev[next.type] = prev[next.type] ? [...prev[next.type], next] : [next];
      return prev;
    }, {} as Record<PaymentTypes, SavedPayment[]>),
  );

export const changePageAfterDelete = ({
  pageSize,
  page,
  total,
  onPageChange,
  refetch,
}: {
  pageSize: number;
  page: number;
  total: number;
  onPageChange: (page: number) => void;
  refetch: () => void;
}) => {
  const isLastElementOnTheList = pageSize * page - (total - 1) === pageSize;
  if (isLastElementOnTheList && total > 1) {
    onPageChange(page - 1);
  } else {
    refetch();
  }
};

export const trimText = (text?: string, maxLength = 420, emptyText = '—') =>
  text ? (text.length > maxLength ? `${text.substring(0, maxLength)}...` : `${text}`) : emptyText;

export const separateStreet = (street?: string) => {
  if (!street) return [];
  const check = street.split(/(?<=^\S+)\s/);

  if (/\d/.test(check[0])) {
    return check;
  } else {
    const lastIndex = street.lastIndexOf(' ');

    if (lastIndex !== -1) {
      const before = street.slice(0, lastIndex);
      const after = street.slice(lastIndex + 1);

      if (/\d/.test(after)) {
        return [after, before.replace(',', '')];
      }
    }
  }

  return [street];
};

export const dateTimeFormat = 'HH:mm DD/MM/YYYY';
export const inputDateFormat = 'YYYY-MM-DD';

export const getProgramExpiredPeriod = (expirePeriod?: string, expireDate?: string) => {
  if (expirePeriod && expireDate) {
    return `expires on ${formatIfValid(expireDate, inputDateFormat)}`;
  }
  if (!expirePeriod) {
    return 'lifetime';
  }

  if (expirePeriod && !expireDate) {
    return 'expired';
  }
};
