import { axios } from './axios';

export const SessionApi = {
  login: (data = {}) => {
    return axios.post('/users/login', data);
  },

  refresh: (data = {}) => {
    return axios.post('/users/refresh', data);
  },

  loginConfirm: (data = {}) => {
    return axios.post('/users/login/sms', data);
  },

  resendConfirm: (data = {}) => {
    return axios.post('/users/dashboard/send-verification-token/', data);
  },
};
