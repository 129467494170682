import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import { $Object } from 'lib/object';
import { capitalize, hasPage, replaceParamsInSearch } from 'lib/utils';
import { message } from 'antd';

interface Options {
  loading?: boolean;
  data?: any;
  errors?: any;
  notReloadPage?: boolean;
  resetDataOnError?: boolean;
  hideMessageErrors?: boolean;
}

interface Params {
  concat?: boolean | string;
  loading?: boolean;
}

export interface RequestResponse<T> {
  request: requestType<T>;
  data: T;
  setData: Dispatch<SetStateAction<T>>;
  loading: boolean;
  errors: {
    [key: string]: string | string[];
  };
}

export type requestType<T> = (fn: Promise<T>, params?: Params) => Promise<T | void>;

export function useRequest<T>(options?: Options): RequestResponse<T> {
  const history = useHistory();
  const [data, setData] = useState((options && options.data) || []);
  const [errors, setErrors] = useState<{ [key: string]: string | string[] }>((options && options.errors) || {});
  const [loading, setLoading] = useState<boolean>((options && options.loading) || false);

  const lastCallRef = useRef<object>();

  const request = async (fn: Promise<T>, params?: Params): Promise<T | void> => {
    setErrors({});

    if (!params || (params && params.loading !== false)) {
      setLoading(true);
    }

    const thisCall = {}; // Get a unique reference each time
    lastCallRef.current = thisCall;

    return fn
      .then(response => {
        /**
         * Prevent a previosly unfinished request to override the data
         */
        if (lastCallRef.current !== thisCall) return;

        if (params && params.concat) {
          const concatResponse = _concat(params.concat, data, response as $Object);

          setData(concatResponse);
          return;
        }

        setData(response);
        return response;
      })
      .catch(error => {
        /**
         * Prevent a previosly unfinished request to show errors
         */
        if (lastCallRef.current !== thisCall) return;

        const { apiError } = error;
        if (apiError?.status === 404 && !options?.notReloadPage) {
          if (hasPage(history.location.search)) {
            history.push(history.location.pathname + replaceParamsInSearch(history.location.search, { page: 1 }));
            window.location.reload();
          }
        }
        if (apiError?.error) {
          setErrors(apiError.error);
        }
        if (options?.resetDataOnError) {
          setData((options && options.data) || []);
        }
      })
      .finally(() => {
        /**
         * Prevent a previosly unfinished request to stop loading
         */
        if (lastCallRef.current !== thisCall) return;

        setLoading(false);
      });
  };

  useEffect(() => {
    if (!Object.keys(errors).length || options?.hideMessageErrors) return;

    message.error(_getErrors(errors));
  }, [errors, options?.hideMessageErrors]);

  return {
    request,
    data,
    errors,
    loading,
    setData,
  };
}

const _concat = (concat: boolean | string, data: $Object, response: $Object): any => {
  if (typeof concat === 'string') {
    return { ...data, [concat]: [...(data[concat] || []), ...(response[concat] || [])] };
  }

  if (Array.isArray(data) && Array.isArray(response)) {
    return [...data, ...response];
  }

  return null;
};

const _getErrors = (err: $Object) => {
  const errors: string[] = [];

  const call = (err: $Object) => {
    Object.entries(err).forEach(([key, value]) => {
      if ((Array.isArray(value) && typeof value[0] === 'string') || typeof value === 'string') {
        errors.push(`${capitalize(key)}: ${value}`);
      } else if (typeof err === 'object') {
        call(err);
      }
    });
  };

  call(err);

  return errors;
};
