import { createContext, ReactNode, useContext, useEffect } from 'react';
import { useRequest } from 'hooks';
import { AvailableSizes } from 'lib/api/sales/sales.type';
import { SalesApi, UsersApi } from 'lib/api';
import { UserGroups } from 'lib/entities/profile';
import { ProfileContext } from './ProfileContext';

export const SettingsContext = createContext<{
  sizes: { data: AvailableSizes; loading: boolean };
  groups: { data: { name: UserGroups }[]; loading: boolean };
}>({
  sizes: { data: { product_sizes: [], clothing_sizes: [] }, loading: false },
  groups: { data: [], loading: false },
});

export const SettingsProvider = ({ children }: { children: ReactNode }) => {
  const sizes = useRequest<AvailableSizes>();
  const groups = useRequest<{ name: UserGroups }[]>({ data: [] });

  const {
    groups: { isAdministrator, isConsilier, isCustomerCare, isWarehouseAdmin },
  } = useContext(ProfileContext);

  const allowGroups = [isAdministrator].some(value => value);
  const allowSizes = [isAdministrator, isCustomerCare, isConsilier, isWarehouseAdmin].some(value => value);

  useEffect(() => {
    allowSizes && sizes.request(SalesApi.availableSizes());
    allowGroups && groups.request(UsersApi.getAdminRoles());
  }, [allowGroups, allowSizes]);

  return (
    <SettingsContext.Provider
      value={{
        sizes: { data: sizes.data, loading: sizes.loading },
        groups: { data: groups.data, loading: groups.loading },
      }}
      children={children}
    />
  );
};
