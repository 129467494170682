import { Request } from '../../index';

export const ProductsApi = {
  list: (data: object) => {
    return Request.get('/sales/dashboard/products/', data);
  },
  create: (data: object) => {
    return Request.post('/sales/dashboard/products/', data);
  },
  byId: (id: number, data = {}) => {
    return Request.get(`/sales/dashboard/products/${id}/`, { ...data });
  },
  update: (id: string, data: object) => {
    return Request.patch(`/sales/dashboard/products/${id}/`, { ...data });
  },
  delete: (id: string) => {
    return Request.delete(`/sales/dashboard/products/${id}/`);
  },
  createBundle: (data: object) => {
    return Request.post(`/sales/dashboard/products/get-or-create-bundle/`, { ...data });
  },
};
