import { Request } from '../../../index';

export const BenefitsApi = {
  list: (data = {}) => {
    return Request.get('/sales/dashboard/price-benefits/', data);
  },
  create: (data: object) => {
    return Request.post('/sales/dashboard/price-benefits/', data);
  },
  bulkCreate: (data: object[]) => {
    return Request.post('/sales/dashboard/price-benefits/bulk-create/', data);
  },
  byId: (id: number) => {
    return Request.get(`/sales/dashboard/price-benefits/${id}/`);
  },
  update: (id: number, data: object) => {
    return Request.patch(`/sales/dashboard/price-benefits/${id}/`, data);
  },
  delete: (id: number) => {
    return Request.delete(`/sales/dashboard/price-benefits/${id}/`);
  },
};
