import { Icon } from 'antd';

interface PasswordExpiredDataProps {
  days: number;
}

export const PasswordExpiredData = ({ days }: PasswordExpiredDataProps) => {
  return (
    <span>
      <Icon type="lock" />{' '}
      <b>
        Password expires in: <span style={{ color: days > 5 ? 'blue' : 'red' }}>{days}</span> days
      </b>
    </span>
  );
};
