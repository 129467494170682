import React from 'react';
import { notification } from 'antd';
import { useIntl } from 'estafette-intl';

import { copyToClipboard } from 'lib/utils';

import './CopyLink.scss';

interface Props {
  link: string;
  className?: string;
  inline?: boolean;
  item?: string;
}

export const CopyLink: React.FC<Props> = ({ link, className = '', item = 'url', children, inline }) => {
  const { t } = useIntl();

  const copy = (text: string) => {
    copyToClipboard(text)
      .then(() => {
        notification.success({
          message: t('success'),
          description: t('successfullyCopiedToClipboard', { item: t(item) }),
        });
      })
      .catch(() => {
        notification.error({ message: t('failedToCopy'), description: t('accesDenied') });
      });
  };

  return (
    <div className={`copy-link ${className} ${inline ? 'inline' : ''}`} onClick={() => copy(link)}>
      {children}
    </div>
  );
};
