import { DeletePaymentMethod } from 'lib/entities/payments-management/merchants';
import { Request } from '../../index';
import { $Object } from '../../object';

export const UsersApi = {
  list: (data: object) => {
    return Request.get('/users/all-admin', data);
  },
  create: (data: object) => {
    return Request.post('/users/create', data);
  },
  byId: (id: string) => {
    return Request.get(`/users/info/${id}`);
  },
  update: (id: string, data: object) => {
    return Request.put(`/users/${id}/`, data);
  },
  block: (id: number) => {
    return Request.post(`/users/block/${id}`);
  },
  unblock: (id: number) => {
    return Request.delete(`/users/block/${id}`);
  },
  changePassword: (data: object) => {
    return Request.post('/users/change-password', data);
  },
  changeKnownPassword: (data: object) => {
    return Request.post('/users/change-known-password', data);
  },
  restorePassword: (data: object) => {
    return Request.post('/users/restore-password', data);
  },
  changeUserPassword: (data: object, id: number) => {
    return Request.post(`/users/dashboard/users/${id}/set-password/`, data);
  },
  listSimpleUsers: (data: object) => {
    return Request.get('/users/', data);
  },
  listAdminUsers: (data: object) => {
    return Request.get('/users/all-admin', data);
  },
  createAdmin: (data: object) => {
    return Request.post(`/users/create`, data);
  },
  getAdmin: (id: string, data = {}) => {
    return Request.get(`/users/dashboard/users/${id}/`, data);
  },
  updateAdmin: (id: number, data: object) => {
    return Request.patch(`/users/dashboard/users/${id}/`, data);
  },
  subscriptions: (id: string, data: object) => {
    return Request.get(`/education/dashboard/users/${id}/subscriptions/`, data);
  },
  addToGroup: (name: string, data: object) => {
    return Request.post(`/users/dashboard/groups/${name}/add_user/`, data);
  },
  getChildren: (data: object) => {
    return Request.get(`/children/dashboard/list`, data);
  },
  delete: (id: number) => {
    return Request.post(`/users/dashboard/users/${id}/delete/`);
  },
  paymentMethods: (data: object) => {
    return Request.get(`/sales/dashboard/payment-methods/`, data);
  },
  deletePaymentMethods: (data: DeletePaymentMethod) => {
    return Request.post('/sales/dashboard/payment-methods/delete_payment_method/', data);
  },
  changeEmail: (id: number, data: object) => {
    return Request.post(`/users/dashboard/users/${id}/change-email/`, data);
  },
  externalInvitations: (data: object) => {
    return Request.get('/users/dashboard/invites/', data);
  },
  updateExternalInvitations: (id: number, data: object) => {
    return Request.patch(`/users/dashboard/invites/${id}/`, data);
  },
  deleteExternalInvitations: (id: number) => {
    return Request.delete(`/users/dashboard/invites/${id}/`);
  },
  listDeletedUsers: (data: object) => {
    return Request.get('/users/dashboard/deleted-users/', data);
  },
  removeGiftOrder: (id: number) => {
    return Request.post(`/sales/dashboard/user-gifts/${id}/remove-order/`);
  },
  recreateGiftOrder: (id: number) => {
    return Request.post(`/sales/dashboard/user-gifts/${id}/create-order/`);
  },
  setEmergencyPin: (data: object) => {
    return Request.post(`/users/phones/emergency-pin/`, data);
  },
  transformToUser: (id: number, data: object) => {
    return Request.post(`/users/dashboard/users/${id}/guest-to-user/`, data);
  },
  deleteInactive: () => {
    return Request.post(`/users/dashboard/users/delete-inactive/`);
  },
  usersRetention: (data = {}) => {
    return Request.get(`/users/dashboard/user-retention/`, data);
  },
  getAdminRoles: (data = {}) => {
    return Request.get(`/users/dashboard/groups/`, data);
  },
  userSmsCleanThrottling: (data = {}) => {
    return Request.post(`/users/phones/sms-clean-throttling/`, data);
  },
  getNotifications: ({ user_id, ...data }: any) => {
    return Request.get(`/notifications/dashboard/user/${user_id}/`, data);
  },
  impersonateUser: (id: number) => Request.post(`/users/dashboard/users/${id}/impersonate/`),
  getImpersonateLogs: (data: $Object) => Request.get(`/users/dashboard/impersonate-users/`, data),
  getUserNotes: (data: $Object) => Request.get(`/users/dashboard/user-notes/`, data),
  addUserNote: (data: $Object) => Request.post(`/users/dashboard/user-notes/`, data),
  updateUserNote: (id: number, data: $Object) => Request.patch(`/users/dashboard/user-notes/${id}/`, data),
  deleteUserNote: (id: number) => Request.delete(`/users/dashboard/user-notes/${id}/`),
  getAll: (data: object) => {
    return Request.get('/users/dashboard/users/', data);
  },
  resyncUsersCrm: (id: number) => Request.post(`/users/dashboard/users/${id}/crm-resync/`),
  resyncDeletedUsersCrm: (id: number) => Request.post(`/users/dashboard/deleted-users/${id}/crm-resync/`),
  getMergeUsers: (data: $Object) => {
    return Request.get(`/users/dashboard/merge/`, data);
  },
  setMergeUsers: (data: $Object) => {
    return Request.post(`/users/dashboard/merge/`, data);
  },
  setOtpEmergency: (data: $Object) => {
    return Request.post(`/users/dashboard/email/emergency-pin/`, data);
  },
};
