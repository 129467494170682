import { PropsWithChildren } from 'react';
import { Alert, Spin } from 'antd';
import { SpinProps } from 'antd/lib/spin';
import { useIntl } from 'estafette-intl';

interface AccessProps extends SpinProps {
  allowAccess: boolean;
}

export const Access = <P,>({ children, allowAccess, ...props }: AccessProps & PropsWithChildren<P>) => {
  const { t } = useIntl();

  return (
    <Spin style={{ backgroundColor: 'white' }} {...props}>
      {!allowAccess ? <Alert message={t('accessDenied')} type="error" showIcon description /> : children}
    </Spin>
  );
};
