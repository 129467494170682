import { Request } from '../../index';

export const DisbursementsApi = {
  list: (data: object) => {
    return Request.get('/sales/dashboard/disbursements/', data);
  },
  accept: (id: number, data: object) => {
    return Request.post(`/sales/dashboard/disbursements/${id}/accept/`, data);
  },
  getItems: (data: object) => {
    return Request.get('/sales/dashboard/disbursements/items/', data);
  },
};
