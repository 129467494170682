import { Request } from '../../index';

export const FaqCategoriesApi = {
  list: (data = {}) => {
    return Request.get('/content/faq-category/', data);
  },
  create: (data: object) => {
    return Request.post('/content/faq-category/', { ...data });
  },
  byId: (id: string) => {
    return Request.get(`/content/faq-category/${id}/`);
  },
  delete: (id: string) => {
    return Request.delete(`/content/faq-category/${id}/`);
  },
  update: (id: string, data: object) => {
    return Request.patch(`/content/faq-category/${id}/`, { ...data });
  },
};
