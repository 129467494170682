import { UserGroups } from 'lib/entities/profile';


export const adminMobileTranslations = {
  [UserGroups.administrator]: {
    en: "Customer Care Fairy",
    ro: "Specialist Suport Parents",
    pl: "Customer Care Fairy",
    pt: "Customer Care Fairy",
    ru: "Специалист по работе с клиентами",
    de: "Unsere gute Fee von der Kundenbetreuung",
  },
  [UserGroups.customerCare]: {
    en: "Customer Care Fairy",
    ro: "Customer Care Fairy",
    pl: "Customer Care Fairy",
    pt: "Customer Care Fairy",
    ru: "Специалист по работе с клиентами",
    de: "Unsere gute Fee von der Kundenbetreuung",
  },
  [UserGroups.moderator]: {
    en: "Parenting Expert",
    ro: "Expert în Parenting",
    pl: "Ekspert w dziedzinie parentingu",
    pt: "Ekspert w dziedzinie parentingu",
    ru: "Эксперт парентинга",
    de: "Expertin für Kindererziehung",
  },
  [UserGroups.contentCreator]: {
    en: "Parenting Expert",
    ro: "Expert în Parenting",
    pl: "Ekspert w dziedzinie parentingu",
    pt: "Ekspert w dziedzinie parentingu",
    ru: "Эксперт парентинга",
    de: "Expertin für Kindererziehung",
  },
  [UserGroups.consilier]: {
    en: "All About Parenting Adviser",
    ro: "Specialist All About Parenting",
    pl: "Doradca All About Parenting",
    pt: "Doradca All About Parenting",
    ru: "Консультант All About Parenting",
    de: "Expertin für Kindererziehung",
  },
  [UserGroups.warehouseAdmin]: {
    en: "Warehouse admin",
    ro: "Warehouse admin",
    pl: "Warehouse admin",
    pt: "Warehouse admin",
    ru: "Warehouse admin",
    de: "Warehouse admin",
  }
}