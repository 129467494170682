import { PaymentTypes, SavedPaymentSubType } from './entities/payments-management/merchants';
import { $Object } from './object';

import ApplePay from 'assets/images/apple-pay.svg';
import GooglePay from 'assets/images/google-pay.svg';

export const childAges = ['0-2', '2-4', '4-6', '6-8', '8-10', '10-12', '12-14', '14-16', '16-18'];

export const paymentType = ['free', 'trial', 'premium'];

export const languages = ['ro', 'en', 'ru', 'pl', 'pt', 'en-us', 'de', 'uk'] as const;

export const pBotLanguages = ['ro', 'en', 'en-us', 'ru', 'pl', 'pt', 'de'] as const;

export const pageType = ['eod', 'education_episode'];

export const pageSize = 20;
export const minPageSize = 10;

export const priceType = ['one_time', 'installment', 'recurring'];

export const currencySymbol: $Object = {
  GBP: '£',
  USD: '$',
  EUR: '€',
  UAH: '₴',
  RON: 'lei',
};

export const uploadMediaRoute = `${process.env.REACT_APP_MEDIA_URL}/file/upload`;

export const filtersVersion = 4;

export const ACCEPT_IMAGE_FORMAT = 'image/jpeg, image/png, image/webp, image/avif';
export const ACCEPT_IMAGE_AND_SVG_FORMAT = [ACCEPT_IMAGE_FORMAT, 'image/svg+xml'].join(', ');

export const webinarCountries = ['RO', 'PL', 'PT', 'GB'];

export const includePaymentMethods: PaymentTypes[] = [
  PaymentTypes.card,
  PaymentTypes.sepa_debit,
  PaymentTypes.us_bank_account,
];

export const cardSubType: Record<SavedPaymentSubType, string> = {
  [SavedPaymentSubType.APPLE_PAY]: ApplePay,
  [SavedPaymentSubType.GOOGLE_PAY]: GooglePay,
};
