import { useEffect } from 'react';
import { parsePhoneNumber } from 'react-phone-number-input';

import { CommonApi } from 'lib/api';
import { useRequest } from './useRequest';

interface CountryCode {
  alpha_2: string;
  name: string;
}

interface UseCountryByPhoneProps {
  countries?: CountryCode[];
}

export const useCountryByPhone = (options?: UseCountryByPhoneProps) => {
  const countries = useRequest<CountryCode[]>({ data: [] });

  useEffect(() => {
    !options?.countries ? countries.request(CommonApi.countries()) : countries.setData(options.countries);
  }, []);

  const getCountryByPhoneNumber = (phone?: string) => {
    if (!phone || !countries?.data) return;

    const parsedPhone = parsePhoneNumber(phone);
    const defaultCountryCode = parsedPhone?.country;

    return countries.data?.find(i => i.alpha_2 === defaultCountryCode);
  };

  return { getCountryByPhoneNumber };
};
