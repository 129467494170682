import { Select } from 'antd';

const { Option } = Select;

const defaultPerPage = [10, 25, 50];

export interface PerPageFilterProps<T extends Record<string, number | undefined>> {
  perPage?: number[];
  defaultPageSize?: number;
  onFiltersChange: (newValues: T) => void;
  filterKey?: keyof T;
  width?: string | number;
}

export function PerPageFilter<T extends Record<string, number | undefined>>({
  perPage = defaultPerPage,
  defaultPageSize = defaultPerPage[0],
  onFiltersChange,
  filterKey,
  width = 150,
}: PerPageFilterProps<T>) {
  const onChange = (value: number) => onFiltersChange({ [filterKey ? filterKey : 'page_size']: value } as T);

  return (
    <Select onChange={onChange} key={defaultPageSize} defaultValue={defaultPageSize} style={{ width }}>
      {perPage.map(value => (
        <Option key={value} value={value}>
          {value}
        </Option>
      ))}
    </Select>
  );
}
