/**
 * API Error object received from fetch wrapper
 *
 * @interface APIError
 * @property {*} error - Error received from api side
 * @property {number} status - Status of the request(ex.: 400)
 * @property {string} statusText - Status of the request in text(ex.: "Bad Request")
 * @property {string} url - Url of the request. Can be used for some logging functions
 */

export interface APIError {
  error: any;
  status: number;
  statusText: string;
  url: string;
}

/**
 * APIBaseError used for all custom classes to have same definitions of processing data
 *
 * @interface APIBaseError
 * @property {function} getFieldsError - Here live logic of errors mapping
 *
 * @returns {*}
 */

interface APIBaseError extends Error {
  getFieldsError: () => object | string;
}

/**
 * Class representing validation error from api
 * @extends Error - Extend native JS Error to have all his properties(ex.: stack trace)
 * @implements {APIBaseError}
 */

class APIValidationError extends Error implements APIBaseError {
  private apiError: APIError;
  private fields: any;

  /**
   * Create a validation error
   * @param {APIError} apiError - Object of APIError type received from fetch wrapper
   * @param {*} fields - Request body contains data send to server(body may be FormData or stringified object)
   */

  constructor(apiError: APIError, fields: any) {
    super(apiError.statusText);
    this.apiError = apiError;
    this.fields = typeof fields === 'string' ? JSON.parse(fields) : fields;
  }

  /**
   * Process received error
   * @return {object} Return errors in format needed by antd design form
   */

  getFieldsError() {
    let errorsToSet: any = {};
    const { error: errors } = this.apiError;

    for (let error in errors) {
      errorsToSet[error] = {
        value: this.fields[error],
        errors: [new Error(errors[error][0])],
      };
    }
    return errorsToSet;
  }
}

/**
 * Class representing generic error from api
 * @extends Error - Extend native JS Error to have all his properties(ex.: stack trace)
 * @implements {APIBaseError}
 */

class APIGenericError extends Error implements APIBaseError {
  private apiError: APIError;
  /**
   * Create a validation error
   * @param {APIError} apiError - Object of APIError type received from fetch wrapper
   */

  constructor(apiError: APIError) {
    super(apiError.error.non_field_errors[0]);
    this.apiError = apiError;
  }

  /**
   * Process received error
   * @return {string} Return received generic error string
   */

  getFieldsError() {
    return this.message;
  }
}

export { APIValidationError, APIGenericError };
