import { useIntl } from 'estafette-intl';
import { OrdersApi } from 'lib/api';
import { UTMFieldSelect, UTMFieldSelectProps } from './UTMFieldSelect';

interface UTMTypeSelectProps extends Omit<UTMFieldSelectProps, 'fieldName' | 'apiCall' | 'itemName'> {}

export const UTMTypeSelect = (props: UTMTypeSelectProps) => {
  const { t } = useIntl();
  return <UTMFieldSelect fieldName="utm_type" apiCall={OrdersApi.getUtmType} itemName={t('utmType')} {...props} />;
};
