import { useEffect, useMemo, useState } from 'react';
import { Avatar, Col, Row } from 'antd';
import { useIntl } from 'estafette-intl';
import { ProgramComment, Reply } from 'lib/entities/comments/programs';
import { EODComment } from 'lib/entities/comments/eod';
import { useTimeUtils } from 'hooks';
import { EpisodeReply } from 'lib/api/episode-replies/episodeReplies.types';
import { getPriorityRole } from 'lib/utils';
import { adminMobileTranslations } from '../../index';
import { Languages } from 'lib/entities';
import { replaceUserReply } from '../../helpers';

import EmptyHeart from 'assets/images/empty-heart.svg';

import './MobileComment.scss';

interface Props {
  comment: ProgramComment | EODComment | Reply | EpisodeReply;
  isReply?: boolean;
  language?: Languages;
}

export const MobileComment = ({ comment, isReply = false, language = 'en' }: Props) => {
  const { getDiffFromToday } = useTimeUtils();
  const { t } = useIntl();

  const [isReadMore, setIsReadMore] = useState(false);

  useEffect(() => {
    const checkCommentLength = () => {
      if (comment.text?.length > 170) {
        setIsReadMore(true);
      }
    };

    checkCommentLength();
  }, []);

  const isStaff = useMemo(() => comment.author.is_staff, [comment]);

  const adminGroup = useMemo(() => comment.author.groups && getPriorityRole(comment.author.groups), [comment]);

  const onReadMoreClick = () => setIsReadMore(prevState => !prevState);

  return (
    <Row type="flex" className="m-mobile-comment">
      <Col className={`m-mobile-comment__avatar-holder ${isReply ? 'reply' : 'main'}`}>
        <Avatar src={comment.author.photo} className={`m-mobile-comment__avatar ${isStaff ? 'expert' : ''}`} />

        <div className={`m-mobile-comment__avatar-line ${isReply ? 'reply' : 'main'}`} />

        {isReply && <div className="m-mobile-comment__avatar-reply" />}
      </Col>

      <Col className="w-100">
        <Row className="m-mobile-comment__content-wrapper">
          <Row className="m-mobile-comment__author-name">{comment.author.full_name}</Row>

          {adminGroup && (
            <Row className="m-mobile-comment__expert">{adminMobileTranslations[adminGroup][language]}</Row>
          )}

          <Row className={`m-mobile-comment__comment ${isReadMore ? 'short' : ''}`}>
            {replaceUserReply(comment.text)}
          </Row>

          {isReadMore && (
            <Row>
              <span className="m-mobile-comment__read-more" onClick={onReadMoreClick}>
                {t('readMore')}
              </span>
            </Row>
          )}
        </Row>

        <Row className="m-mobile-comment__actions">
          <Col className="m-mobile-comment__actions-time">
            <span>{getDiffFromToday(comment.created_at) || t('aboutAMinute')}</span>
            <span>{t('like')}</span>
          </Col>

          <Col className="m-mobile-comment__actions-like">
            <img src={EmptyHeart} alt="empty-heart" />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
