import { forwardRef, useEffect, useMemo } from 'react';
import { Row, Select, Spin } from 'antd';
import { useIntl } from 'estafette-intl';
import { SelectProps } from 'antd/lib/select';

import { useRequest, useStateHandlers } from 'hooks';
import { InfiniteScroll } from 'ui/molecules';
import { TasksApi } from 'lib/api';
import { UserIncomplete } from 'lib/api/users/users.types';
import { pageSize } from 'lib/globalVars';
import { WithPagination } from 'lib/entities';

interface StateType {
  page: number;
  query?: string;
  users: number | number[] | null;
}

const { Option } = Select;

interface ResponsibleMembersProps extends SelectProps<number | number[] | null> {
  query?: string;
  parentsNodeClassName?: string;
  groupId?: number;
}

export const ResponsibleMembers = forwardRef<Select<number | number[] | null>, ResponsibleMembersProps>(
  ({ query, parentsNodeClassName, groupId, ...props }, ref) => {
    const { t } = useIntl();
    const users = useRequest<WithPagination<UserIncomplete[]>>({ data: {} });
    const [state, setState] = useStateHandlers<StateType>({ page: 1, users: [], query });

    useEffect(() => {
      users.request(
        TasksApi.users.list({
          page_size: pageSize,
          page: state.page,
          full_name: state.query,
          responsible_group: groupId,
        }),
        state.page > 1 ? { concat: 'results' } : {},
      );
    }, [state.page, state.query, groupId]);

    useEffect(() => {
      setState({ query });
    }, [query]);

    const hasMore = useMemo(() => !users.loading && Math.ceil(users.data.count / pageSize) > state.page, [
      users.loading,
      users.data,
      state.page,
    ]);

    const onPageChange = () => {
      if (hasMore) {
        setState(prevState => ({ page: ++prevState.page }));
      }
    };

    const onSearch = (query?: string) => {
      setState({ page: 1, query });
    };

    const onChange = (users: number | number[] | null) => {
      setState({ users });
    };

    return (
      <Select
        ref={ref}
        loading={users.loading}
        placeholder={t('responsible')}
        showSearch
        allowClear
        filterOption={() => true}
        onSearch={onSearch}
        onChange={onChange}
        style={{ width: '100%' }}
        onDropdownVisibleChange={() => onSearch()}
        dropdownClassName={parentsNodeClassName}
        dropdownRender={menu => (
          <InfiniteScroll
            onLoadMore={onPageChange}
            parentsNodeClassName={parentsNodeClassName}
            nodeClassName="ant-select-dropdown-menu"
            hasMore={true}
          >
            {menu}
            {users.loading && (
              <Row type="flex" justify="center">
                <Spin style={{ height: 30 }} />
              </Row>
            )}
          </InfiniteScroll>
        )}
        {...props}
      >
        {!state.query && <Option value={null as any}>{t('unassigned')}</Option>}
        {users.data.results?.map(({ id, full_name }) => (
          <Option key={id} value={id}>
            {full_name}
          </Option>
        ))}
      </Select>
    );
  },
);
