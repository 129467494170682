import { UserIncomplete } from '../../api/users/users.types';
import { ProgramIncomplete } from '../../api/education/programs/programs.types';
import { Offer } from '../../api/payments/offers/offers.types';
import { Subscription } from '../sales/subscriptions';

export interface Order {
  id: string;
  billing_error: string | null;
  billing_error_detail: string | null;
  billing_id: string;
  user: UserIncomplete;
  program: ProgramIncomplete;
  program_subscriptions: Subscription[];
  event_subscriptions: Subscription[];
  unfinished_checkout: string | null;
  start_date: string;
  start: string;
  offer_type: string;
  price: Offer;
  paid_installments: number;
  total_installments: number;
  billing_status: BillingStatuses;
  last_payment: string;
  next_invoice: string;
  schedule_cancel?: string;
  language: string;
  quiz_url?: string;
}

export interface UserOrdersGroups {
  order_id: number;
  billing_id: string;
  user: UserIncomplete;
  product: ProductShortDict;
  start: string;
  status: string;
  id: number;
  utm_medium: string;
  utm_source: string;
  utm_type: string;
  price: {
    id: number;
    name: string;
  };
}

export interface ProductShortDict {
  id: number;
  name: string;
  image: string;
  has_gender: boolean;
  genders: string[];
  has_size: boolean;
  sizes: string[];
  product_type: string;
  product_kind: string;
  language: string;
}

export enum BillingStatuses {
  initializing = 'initializing',
  active = 'active',
  suspended = 'suspended',
  deactivated = 'deactivated',
  refunded = 'refunded',
  installmentsComplete = 'installments_complete',
  unpaid = 'unpaid',
  paymentError = 'payment_error',
  deactivatedUnpaid = 'deactivated_unpaid',
  completed = 'completed',
  archived = 'archived',
  processing = 'processing',
  new = 'new',
  expired = 'expired',
}

export const statuses: {
  [Property in BillingStatuses]: {
    label: string;
    color: string;
    active: boolean;
  };
} = {
  [BillingStatuses.new]: {
    label: 'New',
    color: 'gray',
    active: true,
  },
  [BillingStatuses.initializing]: {
    label: 'Initializing',
    color: 'cyan',
    active: true,
  },
  [BillingStatuses.processing]: {
    label: 'Processing',
    color: 'magenta',
    active: true,
  },
  [BillingStatuses.paymentError]: {
    label: 'Payment error',
    color: 'blue',
    active: true,
  },
  [BillingStatuses.active]: {
    label: 'Active',
    color: 'green',
    active: true,
  },
  [BillingStatuses.completed]: {
    label: 'Completed',
    color: 'gold',
    active: true,
  },
  [BillingStatuses.suspended]: {
    label: 'Suspended',
    color: 'geekblue',
    active: true,
  },
  [BillingStatuses.expired]: {
    label: 'Expired',
    color: 'red',
    active: true,
  },
  [BillingStatuses.deactivated]: {
    label: 'Deactivated',
    color: 'volcano',
    active: true,
  },
  [BillingStatuses.refunded]: {
    label: 'Refunded',
    color: 'purple',
    active: true,
  },
  [BillingStatuses.archived]: {
    label: 'Archived',
    color: 'red',
    active: true,
  },
  [BillingStatuses.installmentsComplete]: {
    label: 'Completed',
    color: 'gold',
    active: false,
  },
  [BillingStatuses.unpaid]: {
    label: 'Unpaid',
    color: 'magenta',
    active: false,
  },
  [BillingStatuses.deactivatedUnpaid]: {
    label: 'Deactivated unpaid',
    color: 'red',
    active: false,
  },
};

export interface OrderTotals {
  name: string;
  value: number;
  legend: BillingStatuses[];
}
