import { Switch, Route, matchPath, Redirect } from 'react-router-dom';
import { ProtectedRoute, routes } from 'lib/router';
import { LoginPage } from 'features/login/pages/LoginPage';
import NotFoundPage from 'features/404/pages/NotFoundPage';
import { ChangeExpiredPasswordPage } from 'features/login/pages/ChangeExpiredPasswordPage';
import { SessionStorage } from 'lib';
import { Route as IRoute } from 'lib/router/routes';
import { DefaultLayout } from 'ui/organisms';
import ConfirmPhoneForm from 'features/login/organisms/ConfirmPhoneForm';

const App = () => {
  SessionStorage.makeFilterStorage();

  return (
    <div className="app-main">
      <Switch>
        <Route path="/login" component={LoginPage} />
        <Route path="/expired-password" component={ChangeExpiredPasswordPage} />
        <Route path="/sms-verification" component={ConfirmPhoneForm} />
        <Route exact path="/" render={() => <Redirect to="/parenting-programs" />} />
        <DefaultLayout>{renderProtectedRoutesArray(routes)}</DefaultLayout>
        <Route
          render={(match: any) => {
            let isMatch: boolean = false;
            let path = match.location.pathname;
            if (matchPath(path, { path: '/', exact: true, strict: true })) isMatch = true;
            routes.forEach(r => {
              if (matchPath(path, { path: r.path })) isMatch = true;
              r.children.forEach(child => {
                if (matchPath(path, { path: child.path })) isMatch = true;
              });
            });
            if (!isMatch) return <Redirect to="/404" />;
          }}
        />
        <Route component={NotFoundPage} />
      </Switch>
    </div>
  );
};

export default App;

const renderProtectedRoutesArray = (routes: Array<IRoute>): Array<JSX.Element> => {
  const arr: Array<JSX.Element> = [];
  routes.forEach(r => {
    if (!r.children.length) {
      arr.push(<ProtectedRoute exact key={r.path} path={r.path} component={r.component} />);
    } else {
      renderProtectedRoutesArray(r.children).map(cr => arr.push(cr));
    }
  });
  return arr;
};
