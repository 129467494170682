import { useMemo, useState } from 'react';
import moment from 'moment';
import { useIntl } from 'estafette-intl';
import Masonry from 'react-masonry-component';
import InfiniteScroll from 'react-infinite-scroller';

import { Button, Card, Icon, Modal, Spin } from 'antd';
import { TemplateComment } from 'lib/entities/comments/templates';
import { useScreenBreakPoint } from 'hooks';

import { AddTemplateModal, EditTemplateModal } from '.';

export interface EpisodeListProps {
  filter: {
    page: number;
    pageSize: number;
    totalResults: number;
  };
  data: Array<TemplateComment>;
  onPageChange: (page: number, hasMore?: boolean) => void;
  refreshList: () => void;
  selected: string;
  loading: boolean;
  onRemove: (id: string) => void;
}

const masonryOptions = {
  transitionDuration: 0,
  gutter: 20,
  columnWidth: '.card-item',
};

export const TemplatesList = ({ data, onPageChange, refreshList, loading, filter, onRemove }: EpisodeListProps) => {
  const { t } = useIntl();

  const [showAddModal, setShowAddModal] = useState<boolean>(false);
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [selected, setSelected] = useState<TemplateComment>();

  const onShowAddModal = () => setShowAddModal(!showAddModal);

  const onShowEditModal = () => setShowEditModal(!showEditModal);

  const isTablet = useScreenBreakPoint('tablet');

  const minWidth = isTablet ? '100%' : 500;

  const onSelectedChange = (obj: TemplateComment) => {
    setSelected(obj);
    setShowEditModal(true);
  };

  const onAddSubmitSuccess = () => {
    refreshList();

    setShowAddModal(false);
    setSelected(undefined);
  };

  const onEditSubmitSuccess = () => {
    refreshList();

    setShowEditModal(false);
    setSelected(undefined);
  };

  const hasMore = useMemo(() => !loading && Math.ceil(filter.totalResults / filter.pageSize) > filter.page, [
    filter,
    loading,
  ]);

  const onLoadMore = (page: number) => onPageChange(page, hasMore);

  return (
    <div className="infinite-scroll">
      <Modal
        title={t('addItem', { item: t('template').toLowerCase() })}
        visible={showAddModal}
        onCancel={onShowAddModal}
        footer={null}
        width={1000}
      >
        <AddTemplateModal onSubmitSuccess={onAddSubmitSuccess} />
      </Modal>

      <Modal
        title={t('editItem', { item: t('template').toLowerCase() })}
        visible={showEditModal}
        onCancel={onShowEditModal}
        footer={null}
        width={1000}
        destroyOnClose
      >
        {selected && <EditTemplateModal onSubmitSuccess={onEditSubmitSuccess} item={selected} />}
      </Modal>

      <Spin spinning={loading}>
        <InfiniteScroll initialLoad={false} pageStart={filter.page} loadMore={onLoadMore} hasMore={hasMore}>
          <Masonry enableResizableChildren={true} className="templates-list" options={masonryOptions}>
            <Card className="card-item" style={{ minWidth }}>
              <span className="new-btn" onClick={onShowAddModal}>
                {' '}
                <Icon type="plus" /> {t('add')}
              </span>
            </Card>
            {data
              ? data.map((item: any) => (
                  <Card
                    key={item.id}
                    style={{ minWidth }}
                    hoverable
                    className="card-item"
                    actions={[
                      <Button onClick={() => onSelectedChange(item)} className="custom-link-btn" type="link">
                        {t('edit')}
                      </Button>,
                      <Button onClick={() => onRemove(item.id)} className="custom-link-btn" type="link">
                        {t('delete')}
                      </Button>,
                    ]}
                  >
                    <Card.Meta
                      avatar={<img alt="" className="cardAvatar" src={item.created_by.photo} />}
                      title={
                        <h3>
                          {item.created_by.full_name} &bull;{' '}
                          <sub className="template-date">{moment(item.created_at).format('HH:mm DD/MM/YYYY')}</sub>
                        </h3>
                      }
                    />
                    <p>
                      {t('subject')}: {item.subject}
                    </p>
                    <p>{item.text}</p>
                  </Card>
                ))
              : null}
          </Masonry>
        </InfiniteScroll>
      </Spin>
    </div>
  );
};
