import React from 'react';

import './ListItem.scss';

export const ListItem: React.FC = ({ children }) => {
  const count = React.Children.count(children);

  const items = React.Children.map(children, (child, index) => (
    <li>
      {child}
      {count > index + 1 && <em className="ant-list-item-action-split" />}
    </li>
  ));
  return <ul className="list-items">{items}</ul>;
};
