import { Request } from '../../index';

export const ActiveCampaignApi = {
  tags: (data: object) => {
    return Request.get('/activecampaign/dashboard/tags/', data);
  },
  resyncTags: (data: object) => {
    return Request.post('/activecampaign/dashboard/tags/resync/', data);
  },

  fields: (data: object) => {
    return Request.get('/activecampaign/dashboard/fields/', data);
  },
  resyncFields: (data: object) => {
    return Request.post('/activecampaign/dashboard/fields/resync/', data);
  },
};
