import { Request } from '../../index';

export const AdministrationApi = {
  retrieve: (page: string, data: object) => {
    return Request.get(`/content/page/retrieve/${page}`, data);
  },
  update: (data: object) => {
    return Request.post('/content/page/update', { ...data });
  },
};
