import { Tooltip, Switch } from 'antd';
import { SwitchProps } from 'antd/lib/switch';

interface SwitchWithTooltipProps extends Omit<SwitchProps, 'title'> {
  title: React.ReactNode;
}

export const SwitchWithTooltip = ({ title, ...props }: SwitchWithTooltipProps) => (
  <Tooltip title={title}>
    <Switch {...props} />
  </Tooltip>
);
