import { Icon as AntIcon, Drawer, Layout, Menu, Spin } from 'antd';
import { useIntl } from 'estafette-intl';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { DrawerProps } from 'antd/lib/drawer';

import { routes } from 'lib/router';
import { Route } from 'lib/router/routes';
import { Profile, UserGroups } from 'lib/entities/profile';
import { Icon } from 'ui/atoms';
import { useScreenBreakPoint } from 'hooks';

import 'assets/sass/sider.scss';

const { Sider } = Layout;
const { SubMenu } = Menu;

interface SiderLayoutProps extends RouteComponentProps {
  collapsed: boolean;
  setCollapsed: React.Dispatch<React.SetStateAction<boolean>>;
  profile: Profile;
}

interface SiderWrapperProps extends DrawerProps {
  isDrawer?: boolean;
  children?: React.ReactNode;
}

const SiderWrapper = ({ isDrawer, children, ...props }: SiderWrapperProps) =>
  isDrawer ? (
    <Drawer width={250} bodyStyle={{ padding: 0 }} placement="left" closable={false} {...props}>
      {children}
    </Drawer>
  ) : (
    <>{children}</>
  );

/**
 * Render main dashboard side menu
 */
const SiderLayout = ({ location, collapsed, profile, setCollapsed }: SiderLayoutProps) => {
  const isLaptop = useScreenBreakPoint('laptop');

  let path = location.pathname;
  const pathItems = path.split('/');
  let parent: string | null = '';
  let tab: string = '';

  const activateMenu = () => {
    parent = `/${pathItems[1]}`;

    routes.forEach(r => {
      if (r.children.length) {
        r.children.forEach(children => {
          if (children.path === parent) {
            tab = r.path;
          }
        });
      }
    });
  };

  activateMenu();

  return (
    <SiderWrapper visible={!collapsed} onClose={() => setCollapsed(true)} isDrawer={isLaptop}>
      <Sider trigger={null} collapsible collapsed={collapsed} theme="light" width={250}>
        <div className="site-logo">
          <Icon type="logo" />
        </div>

        {profile?.groups?.length ? (
          <Menu
            mode="inline"
            defaultSelectedKeys={parent ? [parent, path] : []}
            defaultOpenKeys={!collapsed ? [tab, path, `/${pathItems[1]}`] : undefined}
            className="side-menu"
          >
            {RenderMenuItems(routes, profile.groups)}
          </Menu>
        ) : (
          <div className="side-menu__spin">
            <Spin />
          </div>
        )}
      </Sider>
    </SiderWrapper>
  );
};

export default withRouter(SiderLayout);

const RenderMenuItems = (routes: Array<Route>, groups: UserGroups[]): Array<JSX.Element> => {
  const { t } = useIntl();
  const arr: Array<JSX.Element> = [];

  routes.forEach(r => {
    if (!r.children.length) {
      if (r.text && groups.filter(i => !r.groups?.includes(i)).length > 0) {
        arr.push(
          <Menu.Item key={r.path}>
            <Link to={r.path}>
              <AntIcon type={r.icon} />
              <span>{r.text && t(r.text)}</span>
            </Link>
          </Menu.Item>,
        );
      }
    } else {
      if (groups.filter(i => !r.groups?.includes(i)).length > 0) {
        arr.push(
          <SubMenu
            key={r.path}
            title={
              <span>
                <AntIcon type={r.icon} /> <span>{r.text && t(r.text)}</span>
              </span>
            }
          >
            {RenderMenuItems(r.children, groups)}
          </SubMenu>,
        );
      }
    }
  });

  return arr;
};
