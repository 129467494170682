import { Request } from '../../index';

export const ContentApi = {
  get: (pageType: string, data: object) => {
    return Request.get(`/content/page/retrieve/${pageType}`, data);
  },
  update: (data: object) => {
    return Request.post(`/content/page/update`, { ...data });
  },
};
