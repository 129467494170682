import React from 'react';

interface Args extends Omit<IntersectionObserverInit, 'root'> {
  targetRef: React.RefObject<HTMLElement | undefined | null>;
  rootRef?: React.RefObject<HTMLElement | undefined | null>;
  onIntersect: () => void;
  enabled?: boolean;
}

export const useIntersectionObserver = ({ targetRef, rootRef, onIntersect, enabled = true, ...options }: Args) => {
  const onIntersectRef = React.useRef(onIntersect);

  onIntersectRef.current = onIntersect;

  React.useEffect(() => {
    const target = targetRef.current;
    if (!enabled || !target) {
      return;
    }
    const observer = new IntersectionObserver(
      entries =>
        entries.forEach(entry => {
          entry.isIntersecting && onIntersectRef.current();
        }),
      { ...options, root: rootRef?.current },
    );

    observer.observe(target);

    return () => {
      observer.unobserve(target);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enabled, targetRef.current, rootRef?.current]);
};
