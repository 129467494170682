import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import { SessionStorage } from '../index';

/**
 * Render protected dashboard route or redirect to login if session is expired
 * Accept same props as Route from react-router-dom package(https://reacttraining.com/react-router/web/api/Route)
 * @example
 *
 *    <ProtectedRoute path={stringPath} component={ <ReactComponent /> }/>
 *
 */
const ProtectedRoute = ({ component: Component, ...rest }: any) => (
  <Route
    render={props => (SessionStorage.getAccessToken() ? <Component {...props} /> : <Redirect to="/login" />)}
    {...rest}
  />
);

export default ProtectedRoute;
