import Loadable from 'react-loadable';
import { LoadingPlaceholder } from 'ui/atoms';

import { Route } from 'lib/router/routes';

export const notifications: Route[] = [
  {
    path: '/notifications',
    text: 'notifications',
    icon: 'alert',
    children: [
      {
        path: '/notifications/config',
        text: 'configuration',
        icon: 'setting',
        component: Loadable({
          loader: () => import('../../../../features/notifications/pages/configNotifications/ConfigNotifications'),
          loading: LoadingPlaceholder,
          delay: 300,
        }),
        children: [],
        groups: ['moderator', 'customer_care', 'consilier', 'sales_specialist', 'warehouse_admin', 'content_creator'],
      },
      {
        path: '/notifications/config-prompts/form/:id',
        text: null,
        icon: 'setting',
        component: Loadable({
          loader: () => import('../../../../features/notifications/organism/KycNotifications'),
          loading: LoadingPlaceholder,
          delay: 300,
        }),
        children: [],
        groups: ['moderator', 'customer_care', 'consilier', 'sales_specialist', 'warehouse_admin', 'content_creator'],
      },
      {
        path: '/notifications/config/:id',
        text: null,
        icon: 'setting',
        component: Loadable({
          loader: () => import('../../../../features/notifications/pages/configNotifications/EditConfigNotifications'),
          loading: LoadingPlaceholder,
          delay: 300,
        }),
        children: [],
        groups: ['moderator', 'customer_care', 'consilier', 'sales_specialist', 'warehouse_admin', 'content_creator'],
      },
      {
        path: '/notifications/smtp-templates',
        text: 'SMTPTemplates',
        icon: 'layout',
        component: Loadable({
          loader: () => import('../../../../features/notifications/pages/smtpTemplates/SmtpTemplatesList'),
          loading: LoadingPlaceholder,
          delay: 300,
        }),
        children: [],
        groups: ['sales_specialist', 'moderator', 'customer_care', 'consilier', 'warehouse_admin', 'content_creator'],
      },
      {
        path: '/notifications/templates',
        text: 'templates',
        icon: 'mail',
        component: Loadable({
          loader: () => import('../../../../features/notifications/pages/templates/TemplatesList'),
          loading: LoadingPlaceholder,
          delay: 300,
        }),
        children: [],
        groups: ['customer_care', 'moderator', 'content_creator', 'consilier', 'sales_specialist', 'warehouse_admin'],
      },
    ],
    groups: ['sales_specialist', 'moderator', 'customer_care', 'consilier', 'warehouse_admin', 'content_creator'],
  },
];
