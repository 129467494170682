import { stringify } from 'query-string';

import { axios } from './axios';

export const FileApi = {
  download: async (url: string, params = {}, fileName?: string) => {
    const response = await axios.get(`${url}?${stringify(params)}`, { responseType: 'blob' });

    const contentDisposition = response.headers['content-disposition'];

    if (contentDisposition) {
      const foundName = contentDisposition
        .split(';')
        .find(part => part.includes('filename'))
        ?.split('=')[1]
        ?.trim();

      if (foundName) fileName = foundName;
    } else {
      // Extract file name from URL if content-disposition header is not provided
      const urlParts = url.split('/').filter(Boolean);
      fileName = urlParts[urlParts.length - 1];
    }

    const href = URL.createObjectURL(response.data);

    const link = document.createElement('a');
    link.href = href;

    fileName && link.setAttribute('download', fileName);

    document.body.append(link);

    link.click();
    link.remove();

    // In case the Blob uses a lot of memory
    setTimeout(() => URL.revokeObjectURL(link.href), 5000);
  },
};
