import { useIntl } from 'estafette-intl';
import { OrdersApi } from 'lib/api';
import { UTMFieldSelect, UTMFieldSelectProps } from './UTMFieldSelect';

interface UTMSourceSelectProps extends Omit<UTMFieldSelectProps, 'fieldName' | 'apiCall' | 'itemName'> {}

export const UTMSourceSelect = (props: UTMSourceSelectProps) => {
  const { t } = useIntl();
  return (
    <UTMFieldSelect fieldName="utm_source" apiCall={OrdersApi.getUtmSource} itemName={t('utmSource')} {...props} />
  );
};
