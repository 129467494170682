export enum PaymentTypes {
  card = 'card',
  paypal = 'paypal',
  blik = 'blik',
  app_store = 'app_store',
  sepa_debit = 'sepa_debit',
  us_bank_account = 'us_bank_account',
  klarna = 'klarna',
  mbway = 'mbway',
  onlineBanking_PL = 'onlineBanking_PL',
  afterpaytouch = 'afterpay',
  clearpay = 'clearpay',
  multi = 'multi',
}

export enum ProviderTypes {
  stripe = 'stripe',
  braintree = 'braintree',
  adyen = 'adyen',
  pay_u = 'pay_u',
  appStore = 'app_store',
}

export enum ProviderTypesStatus {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
}

export interface PaymentProvider {
  id: number;
  created_at: string;
  modified_at: string;
  name: string;
  type: ProviderTypes;
  provider_type: string;
  is_active: boolean;
  public_key: string;
  secret_key: string;
  kwargs: {
    [key: string]: string;
  };
  company_name: string;
  company_registration_number: string;
  company_registration_code: string;
  company_joint_stock: string;
  company_address: string;
  company_bank: string;
  company_series: string;
  company_iban: string;
  business_name: string;
  phone?: string;
  url?: string;
  vat_payer_from?: string;
  export_bank_name: string;
  export_bank_iban: string;
  export_bank_account_name: string;
  export_bank_account_location: string;
  export_bank_code: string;
  generate_pdf: boolean;
}

export interface PaymentProviderType {
  id: number;
  name: string;
  type: string;
  is_active: boolean;
}

export interface PaymentProviderCountry {
  id: number;
  created_at: string;
  modified_at: string;
  language: string;
  country: string;
  currency: string;
  payment_type: PaymentTypes;
  payment_provider: PaymentProviderType;
  status: 'active' | 'inactive' | 'blocked';
  description?: string;
}

export interface SavedPayment {
  id: number;
  is_default: boolean;
  name: string;
  provider_id: string;
  type: PaymentTypes;
  data: SavedPaymentData;
  sub_type?: SavedPaymentSubType;
}

export interface SavedPaymentData {
  brand?: string;
  country: string;
  exp_month?: number;
  exp_year?: number;
  fingerprint?: string;
  last4: string;
  bank_name?: string;
}

export enum SavedPaymentSubType {
  GOOGLE_PAY = 'google_pay',
  APPLE_PAY = 'apple_pay',
}

export interface DeletePaymentMethod {
  customer: number | string;
  payment_method: number | string;
}
