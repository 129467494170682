import { Button } from 'antd';
import { Link } from 'react-router-dom';
import { useIntl } from 'estafette-intl';

import 'assets/sass/404.scss';

const NotFoundPage: React.FC = () => {
  const { t } = useIntl();

  return (
    <div className="app-404">
      <div className="app-404-right">
        <div className="app-404-img" />
      </div>
      <div className="app-404-left">
        <h1>404</h1>
        <h3>{t('notFound')}</h3>
        <Link to="/">
          <Button type="primary">{t('toHome')}</Button>
        </Link>
      </div>
    </div>
  );
};

export default NotFoundPage;
