import React from 'react';
import { Breadcrumb, Button, Layout } from 'antd';
import { Link, Route } from 'react-router-dom';

import 'assets/sass/default.scss';

interface HeaderWithBreadcrumbsProps {
  actions?: {
    onRemove?: () => void;
  };
  customBread?: CustomBreadProps;
}

interface CustomBreadProps {
  breadcrumbs: Crumb[];
  last: string;
}

interface Crumb {
  crumb: string;
  link: string;
}

const { Header } = Layout;

const Breadcrumbs = () => (
  <Route
    path="*"
    render={props => {
      let parts = props.location.pathname.split('/').filter(item => !/\d/.test(item));
      let place = parts[parts.length - 1];

      if (place.length === 24) {
        place = parts[parts.length - 2];
        parts = parts.slice(1, parts.length - 2);
      } else {
        parts = parts.slice(1, parts.length - 1);
      }

      return (
        <span className="capitalize">
          {parts.map(crumb)}
          <span>{place}</span>
        </span>
      );
    }}
  />
);

const crumb = (part: string, partIndex: number, parts: Array<string>) => {
  const path = ['', ...parts.slice(0, partIndex + 1)].join('/');
  return (
    <Breadcrumb.Item key={partIndex}>
      <Link key={path} to={path}>
        {part}
      </Link>
    </Breadcrumb.Item>
  );
};

const CustomBread: React.FC<{ customBread: CustomBreadProps }> = ({ customBread }) => (
  <span className="capitalize">
    {customBread.breadcrumbs.map((item: Crumb) => (
      <Breadcrumb.Item key={item.crumb}>
        <Link key={item.crumb} to={item.link}>
          {item.crumb}
        </Link>
      </Breadcrumb.Item>
    ))}
    <span>{customBread.last}</span>
  </span>
);

export const HeaderWithBreadcrumbs: React.FC<HeaderWithBreadcrumbsProps> = ({ actions, customBread }) => {
  return (
    <Header className="header-breadcrumb">
      <div className="bread-content">
        {customBread ? <CustomBread customBread={customBread} /> : <Breadcrumbs />}
        {actions && (
          <div className="bread-inverse">
            {actions.onRemove && (
              <span>
                <Button type="default" className="bread-remove" onClick={actions.onRemove}>
                  Remove
                </Button>
              </span>
            )}
            <span>
              <p>Actions :</p>
            </span>
          </div>
        )}
      </div>
    </Header>
  );
};
