import $axios from 'axios';
import { SessionStorage } from '../index';
import { SessionApi } from './session';

export const axios = $axios.create();
export const cancelToken = $axios.CancelToken;

const pendingRequests: Array<() => void> = [];
let isRefreshingNow: boolean = false;

const skipErrorStatuses = ['user_is_blocked'];

axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
axios.defaults.baseURL = process.env.REACT_APP_API_URL;

axios.interceptors.response.use(
  response => response,
  async error => {
    if ($axios.isCancel(error)) {
      return Promise.reject({ cancel: true, message: 'The endpoint was cancelled' });
    }

    if (error.response) {
      if (error.response.status === 401 && error.response.data?.reason !== 'non_device_login') {
        if (skipErrorStatuses.includes(error.response.data?.detail)) {
          return Promise.reject(error);
        }

        if (error.response.data && error.response.data.code === 'token_not_valid') {
          return onError();
        }

        if (error.response.data && error.response.data.code === 'password_expired') {
          return SessionStorage.getAccessToken() ? onPasswordExpired() : Promise.reject(error);
        }

        const originalRequestRetry = new Promise(resolve =>
          pendingRequests.push(() => {
            resolve(
              axios({
                ...error.config,
                headers: { ...error.config.headers, Authorization: SessionStorage.getAccessToken() },
              }),
            );
          }),
        );

        if (!isRefreshingNow) {
          await onRefreshToken();

          pendingRequests.forEach(callback => callback());
        }

        return originalRequestRetry;
      }
    }

    return Promise.reject(error);
  },
);

export const onRefreshToken = async (): Promise<void> => {
  const refresh = SessionStorage.getRefreshToken();

  isRefreshingNow = true;

  if (refresh) {
    await SessionApi.refresh({ refresh })
      .then(({ data }) => {
        SessionStorage.setAccessToken(data.access);
      })
      .catch(() => onError());
  }

  isRefreshingNow = false;
};

const onError = () => {
  SessionStorage.clearStorage();
  window.history.go(0);
};

const onPasswordExpired = () => {
  window.location.assign('/expired-password');
};