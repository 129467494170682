import { $Object } from 'lib/object';
import { Request } from '../index';

export const EasyboxApi = {
  paginatedLockers: (params?: $Object) => {
    return Request.get(`/warehouse/easybox/`, params);
  },
  allLockersURL: () => {
    return Request.get(`/warehouse/easybox/lockers/`);
  },
};
