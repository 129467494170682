import React, { useEffect, useState } from 'react';
import { Icon, message, Upload } from 'antd';
import fetch from 'cross-fetch';

import { ACCEPT_IMAGE_FORMAT, uploadMediaRoute } from 'lib/globalVars';

import './UploadImage.scss';

interface UploadImageProps {
  value?: string;
  accept?: string;
  disabled?: boolean;
  onChange?: (value?: string | null) => void;
  withDeleteBtn?: boolean;
  fillBackground?: boolean;
}

interface UploadImageState {
  loading: boolean;
  imgUrl?: string;
}

const UploadButton = ({ loading }: any) => (
  <div>
    <Icon type={loading ? 'loading' : 'plus'} />
    <div className="ant-upload-text">Upload</div>
  </div>
);

export const UploadImage = React.forwardRef<any, UploadImageProps>(
  ({ value, withDeleteBtn, onChange, accept = ACCEPT_IMAGE_FORMAT, disabled, fillBackground }, ref) => {
    const [state, setState] = useState<UploadImageState>({
      loading: false,
      imgUrl: value,
    });

    const uploadClassName = fillBackground && state?.imgUrl ? 'avatar-uploader upload-fill' : 'avatar-uploader';

    useEffect(() => {
      const onValueChange = () => {
        setState(prevState => ({ ...prevState, imgUrl: value }));
      };

      onValueChange();
    }, [value]);

    const beforeUpload = (file: any) => {
      const isLt10M = file.size / 1024 / 1024 < 10;
      if (!isLt10M) {
        message.error('Image must smaller than 10MB!');
        return false;
      }

      if (!accept.includes(file.type)) {
        const imagesFormat = accept
          .split(' ')
          .map(v => v.split('/')[1])
          .join(' ');
        message.error(`Uploaded file must have one of the following formats: ${imagesFormat}`);
        return false;
      }

      return true;
    };

    const uploadFile = (info: any) => {
      const formData = new FormData();
      formData.append('file', info.file);
      setState(prevState => ({ ...prevState, loading: true }));

      return fetch(uploadMediaRoute, {
        method: 'POST',
        body: formData,
      })
        .then(res => {
          if (res.ok) return res.json();
          else throw res;
        })
        .then(data => {
          setState({
            loading: false,
            imgUrl: data.url,
          });
          if (onChange) {
            onChange(data.url);
          }
        })
        .catch(() => {
          setState({
            loading: false,
          });
          message.error('Uploading image error');
        });
    };

    return (
      <Upload
        ref={ref}
        accept={accept}
        name="photo"
        listType="picture-card"
        showUploadList={false}
        customRequest={uploadFile}
        beforeUpload={beforeUpload}
        disabled={disabled}
        className={uploadClassName}
      >
        {state.imgUrl ? (
          <span className="upload-remove">
            <img src={state.imgUrl} alt="avatar" />
            {withDeleteBtn && (
              <span
                className="btn-with-event button"
                onClick={e => {
                  e.stopPropagation();
                  onChange?.(null);
                }}
              >
                <Icon type="close-circle" />
              </span>
            )}
          </span>
        ) : (
          <UploadButton loading={state.loading} />
        )}
      </Upload>
    );
  },
);
