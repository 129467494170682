import { Icon, Tooltip } from 'antd';

interface DataVerifiedProps {
  isVerified?: boolean;
  isVerifiedTitle: React.ReactNode;
  isNotVerifiedTitle: React.ReactNode;
}

export const DataVerified = ({ isVerified, isVerifiedTitle, isNotVerifiedTitle }: DataVerifiedProps) => {
  return isVerified ? (
    <Tooltip title={isVerifiedTitle}>
      <Icon style={{ paddingLeft: '4px' }} type="check-circle" theme="twoTone" twoToneColor="#52c41a" />
    </Tooltip>
  ) : (
    <Tooltip title={isNotVerifiedTitle}>
      <Icon style={{ paddingLeft: '4px' }} type="close-circle" theme="twoTone" twoToneColor="#ff4d4f" />
    </Tooltip>
  );
};
