import { Request } from '../../index';

export const EpisodesApi = {
  list: (data: object) => {
    return Request.get('/education/dashboard/episodes/', data);
  },
  create: (data: object) => {
    return Request.post('/education/dashboard/episodes/', data);
  },
  byId: (id: string, data: object) => {
    return Request.get(`/education/dashboard/episodes/${id}/`, { ...data });
  },
  patch: (id: string, data: object) => {
    return Request.patch(`/education/dashboard/episodes/${id}/`, { ...data });
  },
  delete: (id: string, data = {}) => {
    return Request.delete(`/education/dashboard/episodes/${id}/`, { ...data });
  },
  quizList: (data: object) => {
    return Request.get('/education/dashboard/episode-quiz/', data);
  },
  quizById: (id: string, data: object) => {
    return Request.get(`/education/dashboard/episode-quiz/${id}/`, { ...data });
  },
  postVideoUrl: (id: string, data: object) => {
    return Request.post(`/education/dashboard/episodes/${id}/video-urls/`, { ...data });
  },
};
