import { forwardRef, useEffect, useMemo } from 'react';
import { Row, Select, Spin } from 'antd';
import { useIntl } from 'estafette-intl';
import { SelectProps } from 'antd/lib/select';

import { useRequest, useStateHandlers } from 'hooks';
import { InfiniteScroll } from 'ui/molecules';
import { TasksApi } from 'lib/api';
import { pageSize } from 'lib/globalVars';
import { WithPagination } from 'lib/entities';
import { StatusDetail, TaskType } from 'lib/api/tasks/tasks.types';

interface StateType {
  page: number;
  query?: string;
  statuses: number | number[];
}

const { Option } = Select;

interface StatusesSelectProps extends SelectProps<number | number[]> {
  query?: string;
  parentsNodeClassName?: string;
  types?: TaskType | TaskType[];
  additionalStatus?: StatusDetail;
  onlyAllowedTypes?: boolean;
  projects?: string;
  isAutoSetup?: boolean;
}

export const StatusesSelect = forwardRef<Select<number | number[]>, StatusesSelectProps>(
  (
    { query, parentsNodeClassName, types, additionalStatus, onlyAllowedTypes, projects, isAutoSetup, ...props },
    ref,
  ) => {
    const { t } = useIntl();
    const statuses = useRequest<WithPagination<StatusDetail[]>>({ data: {} });
    const [state, setState] = useStateHandlers<StateType>({ page: 1, statuses: [], query });

    useEffect(() => {
      statuses.request(
        TasksApi.status.list({
          page_size: pageSize,
          page: state.page,
          search: state.query,
          ordering: 'ordering',
          types,
          only_allowed_types: onlyAllowedTypes,
          projects,
        }),
        state.page > 1 ? { concat: 'results' } : {},
      );
    }, [state.page, state.query, types, projects]);

    useEffect(() => {
      if (!isAutoSetup || statuses.data.results?.length !== 1 || state.query) return;
      props.onChange?.(+statuses.data.results[0].id, <></>);
    }, [statuses.data.results, isAutoSetup]);

    useEffect(() => {
      setState({ query });
    }, [query]);

    const hasMore = useMemo(() => !statuses.loading && Math.ceil(statuses.data.count / pageSize) > state.page, [
      statuses.loading,
      statuses.data,
      state.page,
    ]);

    const onPageChange = () => {
      if (hasMore) {
        setState(prevState => ({ page: ++prevState.page }));
      }
    };

    const onSearch = (query?: string) => {
      setState({ page: 1, query });
    };

    const onChange = (statuses: number | number[]) => {
      setState({ statuses });
    };

    return (
      <Select
        ref={ref}
        loading={statuses.loading}
        placeholder={t('pleaseSelectA', { item: t('status').toLowerCase() })}
        showSearch
        allowClear
        filterOption={() => true}
        onSearch={onSearch}
        onChange={onChange}
        style={{ width: '100%' }}
        onDropdownVisibleChange={() => onSearch()}
        dropdownClassName={parentsNodeClassName}
        dropdownRender={menu => (
          <InfiniteScroll
            onLoadMore={onPageChange}
            parentsNodeClassName={parentsNodeClassName}
            nodeClassName="ant-select-dropdown-menu"
            hasMore={true}
          >
            {menu}
            {statuses.loading && (
              <Row type="flex" justify="center">
                <Spin style={{ height: 30 }} />
              </Row>
            )}
          </InfiniteScroll>
        )}
        {...props}
      >
        {statuses.data.results?.map(({ name, id }) => (
          <Option key={id} value={id} title={name}>
            {name}
          </Option>
        ))}
        {!!additionalStatus && !statuses.data.results?.some(({ id }) => id === additionalStatus?.id) && (
          <Option key={additionalStatus?.id} value={additionalStatus?.id} title={additionalStatus?.name}>
            {additionalStatus?.name}
          </Option>
        )}
      </Select>
    );
  },
);
