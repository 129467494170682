import { Request } from '../index';

export const CommonApi = {
  countries: () => {
    return Request.get(`/common/countries/`);
  },
  counties: (data: object) => {
    return Request.get(`/common/counties/`, data);
  },
  refreshLocalizations: (data = {}) => {
    return Request.post(`/common/dashboard/generate-localization-files/`, data);
  },
  throttlingWhiteList: (data: object) => {
    return Request.post(`/common/dashboard/throttling-white-list/`, data);
  },
  blackListPhones: (data = {}) => {
    return Request.get(`/common/dashboard/black-list-phones/`, data);
  },
  blackListCountries: (data = {}) => {
    return Request.get(`/common/dashboard/black-list-countries/`, data);
  },
};
