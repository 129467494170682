import Loadable from 'react-loadable';
import { LoadingPlaceholder } from 'ui/atoms';

import { Route } from 'lib/router/routes';

export const responsibleGroups: Route[] = [
  {
    path: '/user-assignment',
    text: 'userAssignment',
    icon: 'solution',
    component: Loadable({
      loader: () => import('../../../../features/users/responsible-groups/user-assignment/ResponsibleGroupsPage'),
      loading: LoadingPlaceholder,
      delay: 300,
    }),
    children: [],
    groups: ['moderator', 'content_creator', 'consilier', 'warehouse_admin'],
  },
  {
    path: '/user-responsible-groups',
    text: 'groups',
    icon: 'table',
    component: Loadable({
      loader: () => import('../../../../features/users/responsible-groups/groups/GroupsPage'),
      loading: LoadingPlaceholder,
      delay: 300,
    }),
    children: [],
    groups: ['moderator', 'content_creator', 'consilier', 'warehouse_admin'],
  },
  {
    path: '/user-task-statuses',
    text: 'statuses',
    icon: 'switcher',
    component: Loadable({
      loader: () => import('../../../../features/users/responsible-groups/statuses/StatusesPage'),
      loading: LoadingPlaceholder,
      delay: 300,
    }),
    children: [],
    groups: ['moderator', 'content_creator', 'consilier', 'warehouse_admin'],
  },
  {
    path: '/user-task-types',
    text: 'taskTypes',
    icon: 'read',
    component: Loadable({
      loader: () => import('../../../../features/users/responsible-groups/task-types/TaskTypesPage'),
      loading: LoadingPlaceholder,
      delay: 300,
    }),
    children: [],
    groups: ['moderator', 'content_creator', 'consilier', 'warehouse_admin'],
  },
];
