import { Card as AntdCard } from 'antd';
import { CardProps } from 'antd/lib/card';

import './Card.scss';

interface Props extends CardProps {
  marginBottom?: boolean;
}

export const Card = ({ className = '', marginBottom = false, ...props }: Props) => (
  <AntdCard className={`custom-card ${className} ${!marginBottom ? 'mb-0' : ''}`} bordered={false} {...props} />
);
