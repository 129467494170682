import { Request } from '../../../index';

export const ProgramsApi = {
  list: (data = {}) => {
    return Request.get('/education/dashboard/programs/', data);
  },
  create: (data: object) => {
    return Request.post('/education/dashboard/programs/', data);
  },
  byId: (id: string, data?: object) => {
    return Request.get(`/education/dashboard/programs/${id}/`, { ...data });
  },
  update: (id: string, data: object) => {
    return Request.patch(`/education/dashboard/programs/${id}/`, { ...data });
  },
  delete: (id: string) => {
    return Request.delete(`/education/dashboard/programs/${id}/`);
  },
  chapters: (id: number, data = {}) => {
    return Request.get(`/education/dashboard/programs/${id}/chapters/`, { ...data });
  },
  subscribers: (id: string, data: object) => {
    return Request.get(`/education/dashboard/programs/${id}/subscriptions/`, data);
  },
  unsubscribed: (id: string, data: object) => {
    return Request.get(`/education/dashboard/programs/${id}/unsubscribed_users/`, data);
  },
  gifts: (id: number, data: object) => {
    return Request.get(`/education/dashboard/programs/${id}/gifts/`, data);
  },
};
