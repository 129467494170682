import Loadable from 'react-loadable';
import { LoadingPlaceholder } from 'ui/atoms';

import { Route } from 'lib/router/routes';

export const parenting: Route[] = [
  {
    path: '/programs',
    text: 'programs',
    icon: 'schedule',
    component: Loadable({
      loader: () => import('../../../../features/education/pages/programs/ProgramsPage'),
      loading: LoadingPlaceholder,
      delay: 300,
    }),
    children: [],
    groups: ['sales_specialist'],
  },
  {
    path: '/webinars',
    text: 'webinars',
    icon: 'cluster',
    component: Loadable({
      loader: () => import('../../../../features/education/pages/webinars/ProductsPage'),
      loading: LoadingPlaceholder,
      delay: 300,
    }),
    children: [],
    groups: ['customer_care', 'moderator', 'consilier', 'content_creator'],
  },
  {
    path: '/webinars/add',
    text: null,
    component: Loadable({
      loader: () => import('../../../../features/education/pages/webinars/AddProductPage'),
      loading: LoadingPlaceholder,
      delay: 300,
    }),
    children: [],
    groups: ['customer_care', 'moderator', 'content_creator'],
  },
  {
    path: '/webinars/:id/edit',
    text: null,
    component: Loadable({
      loader: () => import('../../../../features/education/pages/webinars/EditProductPage/EditProductPage'),
      loading: LoadingPlaceholder,
      delay: 300,
    }),
    children: [],
    groups: ['customer_care', 'moderator', 'content_creator'],
  },
  {
    path: '/programs/add',
    text: null,
    icon: undefined,
    component: Loadable({
      loader: () => import('../../../../features/education/pages/programs/AddProgramPage'),
      loading: LoadingPlaceholder,
      delay: 300,
    }),
    children: [],
    groups: ['customer_care', 'moderator', 'sales_specialist'],
  },
  {
    path: '/programs/:programId/edit',
    text: null,
    icon: undefined,
    component: Loadable({
      loader: () => import('../../../../features/education/organisms/programs/ProgramTabs'),
      loading: LoadingPlaceholder,
      delay: 300,
    }),
    children: [],
    groups: ['moderator', 'sales_specialist'],
  },
  {
    path: '/programs/:programId/chapters/add',
    text: null,
    icon: undefined,
    component: Loadable({
      loader: () => import('../../../../features/education/pages/chapters/AddChapterPage'),
      loading: LoadingPlaceholder,
      delay: 300,
    }),
    children: [],
    groups: ['sales_specialist'],
  },
  {
    path: '/programs/:programId/gifts/add',
    text: null,
    icon: undefined,
    component: Loadable({
      loader: () => import('../../../../features/education/pages/gifts/AddGiftPage'),
      loading: LoadingPlaceholder,
      delay: 300,
    }),
    children: [],
    groups: ['moderator', 'sales_specialist'],
  },
  {
    path: '/programs/:programId/chapters/:chapterId/edit',
    text: null,
    icon: undefined,
    component: Loadable({
      loader: () => import('../../../../features/education/organisms/chapters/ChapterTabs'),
      loading: LoadingPlaceholder,
      delay: 300,
    }),
    children: [],
    groups: ['sales_specialist'],
  },
  {
    path: '/programs/chapters',
    text: null,
    icon: undefined,
    component: Loadable({
      loader: () => import('../../../../features/education/pages/programs/ProgramsPage'),
      loading: LoadingPlaceholder,
      delay: 300,
    }),
    children: [],
    groups: ['sales_specialist'],
  },
  {
    path: '/programs/chapters/episodes',
    text: null,
    icon: undefined,
    component: Loadable({
      loader: () => import('../../../../features/education/pages/programs/ProgramsPage'),
      loading: LoadingPlaceholder,
      delay: 300,
    }),
    children: [],
    groups: ['sales_specialist'],
  },
  {
    path: '/programs/:programId/chapters/:chapterId/add',
    text: null,
    icon: undefined,
    component: Loadable({
      loader: () => import('../../../../features/education/pages/episodes/AddEpisodePage'),
      loading: LoadingPlaceholder,
      delay: 300,
    }),
    children: [],
    groups: ['sales_specialist'],
  },
  {
    path: '/programs/:programId/chapters/:chapterId/episodes/:episodeId/edit',
    text: null,
    icon: undefined,
    component: Loadable({
      loader: () => import('../../../../features/education/organisms/episodes/EpisodeTab'),
      loading: LoadingPlaceholder,
      delay: 300,
    }),
    children: [],
    groups: ['sales_specialist'],
  },
  {
    path: '/programs/subscriptions/',
    text: null,
    icon: undefined,
    component: Loadable({
      loader: () => import('../../../../features/education/pages/programs/ProgramsPage'),
      loading: LoadingPlaceholder,
      delay: 300,
    }),
    children: [],
    groups: ['sales_specialist'],
  },
  {
    path: '/programs/:programId/subscriptions/add',
    text: null,
    icon: undefined,
    component: Loadable({
      loader: () => import('../../../../features/education/pages/programs/subscriptions/AddProgramSubscriptionsPage'),
      loading: LoadingPlaceholder,
      delay: 300,
    }),
    children: [],
    groups: ['sales_specialist'],
  },
  {
    path: '/programs/:programId/subscriptions/:subscriptionId/edit',
    text: null,
    icon: undefined,
    component: Loadable({
      loader: () => import('../../../../features/education/organisms/programs/subscriptions/ProgramSubscriptionTabs'),
      loading: LoadingPlaceholder,
      delay: 300,
    }),
    children: [],
    groups: ['sales_specialist'],
  },
  // {
  //   path: '/subscriptions',
  //   text: 'allMembers',
  //   icon: 'team',
  //   component: Loadable({
  //     loader: () => import('../../../../features/education/pages/subscribers/SubscriptionsPage'),
  //     loading: LoadingPlaceholder,
  //     delay: 300,
  //   }),
  //   children: [],
  //   groups: [],
  // },
  // {
  //   path: '/subscriptions/add',
  //   text: null,
  //   icon: undefined,
  //   component: Loadable({
  //     loader: () => import('../../../../features/education/pages/subscribers/AddSubscriptionPage'),
  //     loading: LoadingPlaceholder,
  //     delay: 300,
  //   }),
  //   children: [],
  //   groups: [],
  // },
  {
    path: '/subscriptions/:subscriptionId/edit',
    text: null,
    icon: undefined,
    component: Loadable({
      loader: () => import('../../../../features/education/organisms/subscribers/SubscriptionTabs'),
      loading: LoadingPlaceholder,
      delay: 300,
    }),
    children: [],
    groups: [],
  },
  {
    path: '/programs/:programId/gifts/:giftId/edit',
    text: null,
    icon: undefined,
    component: Loadable({
      loader: () => import('../../../../features/education/pages/gifts/EditGiftPage'),
      loading: LoadingPlaceholder,
      delay: 300,
    }),
    children: [],
    groups: [],
  },
];
