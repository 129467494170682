import { filtersVersion } from './globalVars';

/**
 * Class representing storage to manipulate data session data
 */

class SessionStorage {
  private accessTokenKey = 'access-token';
  private refreshTokenKey = 'refresh-token';
  private userProfile = 'user-profile';
  private filters = `filters_v${filtersVersion}`;
  private storage = 'storage';
  private twoFactorTokenKey = 'two-factor-token';
  private UserPhoneNumber = 'user-phone-number';

  constructor() {
    this.clearOldVersions('filters', this.filters);
  }

  /**
   * Return access token for session
   * @returns {string}
   */
  getAccessToken() {
    return localStorage.getItem(this.accessTokenKey);
  }

  /**
   * Set access token for session
   * @param {string} token String representation for jwt token.
   *
   * @returns {void}
   */
  setAccessToken(token: string) {
    return localStorage.setItem(this.accessTokenKey, token);
  }

  /**
   * Return refresh token for session
   * @returns {string}
   */
  getRefreshToken() {
    return localStorage.getItem(this.refreshTokenKey);
  }

  /**
   * Return two factor token for session
   * @returns {string}
   */
  getTwoFactorToken() {
    return localStorage.getItem(this.twoFactorTokenKey);
  }

  /**
   * Return user phone number for session
   * @returns {string}
   */
  getUserPhoneNumber() {
    return localStorage.getItem(this.UserPhoneNumber);
  }

  /**
   * Set refresh token for session
   * @param {string} token String representation for jwt token.
   *
   * @returns {void}
   */
  setRefreshToken(token: string) {
    return localStorage.setItem(this.refreshTokenKey, token);
  }

  /**
   * Delete refresh and access token
   * @returns {void}
   */
  clearStorage() {
    localStorage.removeItem(this.accessTokenKey);
    localStorage.removeItem(this.refreshTokenKey);
    localStorage.removeItem(this.userProfile);
    localStorage.removeItem(this.twoFactorTokenKey);
    localStorage.removeItem(this.UserPhoneNumber);
  }

  setUserProfile(profile: object) {
    return localStorage.setItem(this.userProfile, JSON.stringify(profile));
  }

  getUserProfile() {
    return localStorage.getItem(this.userProfile);
  }

  makeFilterStorage() {
    const filters = localStorage.getItem(this.filters);

    if (!filters) {
      return localStorage.setItem(this.filters, JSON.stringify({}));
    }
  }

  getFilterStorage() {
    return localStorage.getItem(this.filters);
  }

  setFiltersStorage(newFilters: object) {
    return localStorage.setItem(this.filters, JSON.stringify(newFilters));
  }

  getLocalStorage() {
    return localStorage.getItem(this.storage);
  }

  setLocalStorage(newItems: object) {
    return localStorage.setItem(this.storage, JSON.stringify(newItems));
  }

  clearOldVersions(matchValue: string, matchKey: string) {
    Object.keys(localStorage).forEach(key => {
      if (key.startsWith(matchValue) && key !== matchKey) {
        localStorage.removeItem(key);
      }
    });
  }
}

export default new SessionStorage();
