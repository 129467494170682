import { Request } from '../../index';

export const InvoicesApi = {
  list: (data: object) => Request.get('/sales/dashboard/invoices/', data),
  provider: (data: object = {}) => Request.get('/sales/dashboard/providers/', data),
  download: (data: object) => Request.get('/invoices/download', data),
  cancel: (id: number) => Request.patch(`/sales/dashboard/invoices/${id}/cancel/`),
  manualRefund: (id: number, data: object) => Request.post(`/sales/dashboard/invoices/${id}/manual-refund/`, data),
  schedule: (id: number, data: object) => Request.post(`/sales/dashboard/invoices/${id}/schedule/`, data),
  recreatePdf: (id: number, data: object) => Request.post(`/sales/dashboard/invoices/${id}/recreate-pdf/`, data),
  exportZip: (params: object) => Request.get('/sales/dashboard/invoices/zip/', params),
  exportZipByMonth: (params: object) => Request.get('/sales/dashboard/invoices/provider-zip/', params),
};
