import { FormComponentProps } from 'antd/lib/form';
import React, { useState } from 'react';
import { Button, Form, Icon, message } from 'antd';
import { useIntl } from 'estafette-intl';
import { PasswordInput } from 'ui/atoms';
import { UsersApi } from 'lib/api';
import { setFieldsError } from 'lib/utils';
import { useStateHandlers } from 'hooks';

export interface ResetPasswordModalProps extends FormComponentProps {
  setShowModal(): void;
}

const ResetPasswordForm: React.FC<ResetPasswordModalProps> = ({ form, setShowModal }) => {
  const { getFieldDecorator } = form;
  const { t } = useIntl();

  const [confirmDirty, setConfirmDirty] = useState(false);
  const [state, setState] = useStateHandlers({ loading: false, errors: '' });

  const handleSubmit = (e: any) => {
    e.preventDefault();
    setState({ loading: true, errors: '' });

    form.validateFields((err, values) => {
      if (!err) {
        UsersApi.changePassword(values)
          .then(() => {
            setShowModal();
          })
          .catch(e => {
            if (e.apiError.error.detail) {
              return setState({ errors: e.apiError.error.detail });
            }

            if (e.apiError && e.apiError.error && !e.apiError.error.detail) {
              return setFieldsError(e.apiError.error, form);
            }
            message.error('Server error');
          })
          .finally(() => {
            setState({ loading: false });
          });
      }
    });
  };

  const compareToFirstPassword = (rule: any, value: string, callback: any) => {
    if (value && value !== form.getFieldValue('new_password')) {
      callback(t('passwordsDontMatch'));
    } else {
      callback();
    }
  };

  const validateToNextPassword = (rule: any, value: string, callback: any) => {
    if (value && confirmDirty) {
      form.validateFields(['confirm_password'], { force: true });
    }
    callback();
  };

  const handleConfirmBlur = (e: any) => {
    const value = e.target.value;
    setConfirmDirty(confirmDirty || !!value);
  };

  return (
    <Form onSubmit={handleSubmit} className="login-form">
      {state.errors && <div className="error-div">{state.errors}</div>}

      <Form.Item>
        {getFieldDecorator('new_password', {
          rules: [
            {
              required: true,
              message: 'Please input new password!',
            },
            {
              min: 8,
              message: 'Please input at least 8 characters!',
            },
            {
              validator: validateToNextPassword,
            },
          ],
        })(
          <PasswordInput
            prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
            placeholder="New password"
          />,
        )}
      </Form.Item>
      <Form.Item>
        {getFieldDecorator('confirm_password', {
          rules: [
            {
              required: true,
              message: 'Please confirm new password!',
            },
            {
              validator: compareToFirstPassword,
            },
          ],
        })(
          <PasswordInput
            prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
            onBlur={handleConfirmBlur}
            placeholder="Confirm new password"
          />,
        )}
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit" className="login-form-button" loading={state.loading}>
          Save
        </Button>
      </Form.Item>
    </Form>
  );
};

export default Form.create<ResetPasswordModalProps>()(ResetPasswordForm);
