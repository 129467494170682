import { useEffect } from 'react';

import { SessionStorage } from 'lib';
import { parseJwt, remainedExpirationTime } from 'lib/jwt';
import { SessionApi } from 'lib/api';

/**
 *
 * @param callTime the minutes until the call refresh token
 */
export const useRefreshToken = (callTime = 30) => {
  useEffect(() => {
    const accessToken = SessionStorage.getAccessToken();
    const refreshToken = SessionStorage.getRefreshToken();
    const parsedJWT = parseJwt(accessToken);
    if (!parsedJWT) return;

    const expirationTime = remainedExpirationTime(parsedJWT?.exp);
    const millisecondsCallTime = callTime * 60 * 1000;

    const time = expirationTime.asMilliseconds() - millisecondsCallTime;

    if (time < 0) return;

    const timeId = setTimeout(async () => {
      await SessionApi.refresh({
        refresh: refreshToken,
        device_id: [navigator.appCodeName, navigator.platform, navigator.productSub].join('_'),
        device_name: navigator.userAgent,
        device_type: 'pc',
      })
        .then(({ data }) => {
          SessionStorage.setAccessToken(data.access);
        })
        .catch(() => {
          SessionStorage.clearStorage();
          window.history.go(0);
        });
    }, time);

    return () => clearTimeout(timeId);
  }, [callTime]);
};
