import LoginForm from 'features/login/organisms/LoginForm';
import { SessionLayout } from 'ui/organisms';

export const LoginPage = () => {
  return (
    <SessionLayout>
      <LoginForm />
    </SessionLayout>
  );
};
