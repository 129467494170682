import Loadable from 'react-loadable';
import { LoadingPlaceholder } from 'ui/atoms';

import { Route } from 'lib/router/routes';

export const offers: Route[] = [
  {
    path: '/offers',
    text: 'offerList',
    icon: 'snippets',
    component: Loadable({
      loader: () => import('../../../../features/payments/offers/OffersPage'),
      loading: LoadingPlaceholder,
      delay: 300,
    }),
    children: [],
    groups: [],
  },
  {
    path: '/sales-page',
    text: 'salesPage',
    icon: 'tags',
    component: Loadable({
      loader: () => import('../../../../features/payments/offers/SalesPage'),
      loading: LoadingPlaceholder,
      delay: 300,
    }),
    children: [],
    groups: [],
  },

  {
    path: '/offers/add',
    text: null,
    component: Loadable({
      loader: () => import('../../../../features/payments/offers/AddOfferPage'),
      loading: LoadingPlaceholder,
      delay: 300,
    }),
    children: [],
    groups: [],
  },
  {
    path: '/offers/:id/edit',
    text: null,
    component: Loadable({
      loader: () => import('../../../../features/payments/offers/EditOfferPage'),
      loading: LoadingPlaceholder,
      delay: 300,
    }),
    children: [],
    groups: [],
  },
  {
    path: '/sales-page/add',
    text: null,
    component: Loadable({
      loader: () => import('../../../../features/payments/offers/SalesPageAddEdit'),
      loading: LoadingPlaceholder,
      delay: 300,
    }),
    children: [],
    groups: [],
  },
  {
    path: '/sales-page/:id/edit',
    text: null,
    component: Loadable({
      loader: () => import('../../../../features/payments/offers/SalesPageAddEdit'),
      loading: LoadingPlaceholder,
      delay: 300,
    }),
    children: [],
    groups: [],
  },
];
