import { Request } from '../index';

export const FeedbackApi = {
  list: (data: object) => {
    return Request.get('/users/dashboard/feedback/', { ...data });
  },
  reply: (id: string, data: object) => {
    return Request.post(`/users/dashboard/feedback/reply/${id}`, data);
  },
  listByProgram: (id: string, data: object) => {
    return Request.get(`/education/dashboard/programs/${id}/reviews/`, data);
  },
  delete: (programId: string, id: string) => {
    return Request.delete(`/education/dashboard/programs/${programId}/reviews/${id}/`);
  },
  hide: (programId: string, id: string) => {
    return Request.post(`/education/dashboard/programs/${programId}/reviews/${id}/hide/`);
  },
  unhide: (programId: string, id: string) => {
    return Request.post(`/education/dashboard/programs/${programId}/reviews/${id}/show/`);
  },
};
