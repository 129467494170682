import React, { useState } from 'react';
import { Button, Form, Input } from 'antd';
import { maxLengthValidation } from 'lib/utils';

const { TextArea } = Input;

interface CommentEditInterface {
  text: string;
  loading: boolean;
  onSubmit: (val: string) => void;
}

export const CommentEdit = ({ text, loading, onSubmit }: CommentEditInterface) => {
  const [value, setValue] = useState<string>(text);

  const onReplyChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => setValue(maxLengthValidation(e.target.value));

  return (
    <div className="comment-edit">
      <Form.Item className="mb-10">
        <TextArea value={value} onChange={onReplyChange} className="textarea" />
      </Form.Item>
      <Form.Item>
        {value ? (
          <Button htmlType="submit" loading={loading} onClick={() => onSubmit(value)} type="primary">
            Save
          </Button>
        ) : null}
      </Form.Item>
    </div>
  );
};
