import { HTMLAttributes } from 'react';

export interface SpaceProps extends HTMLAttributes<HTMLDivElement> {
  space?: React.CSSProperties['gap'];
  direction?: React.CSSProperties['flexDirection'];
  align?: React.CSSProperties['alignItems'];
  justify?: React.CSSProperties['justifyContent'];
  wrap?: React.CSSProperties['flexWrap'];
  inline?: boolean;
}

export const Space: React.FC<SpaceProps> = ({
  space = 15,
  direction = 'row',
  align = 'flex-end',
  justify,
  wrap = 'wrap',
  style,
  inline,
  ...props
}) => (
  <div
    style={{
      display: !inline ? 'flex' : 'inline-flex',
      gap: space,
      flexWrap: wrap,
      flexDirection: direction,
      alignItems: align,
      justifyContent: justify,
      ...style,
    }}
    {...props}
  />
);
