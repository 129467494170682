import { Request } from '../index';

export const ReportsApi = {
  list: (data: object) => {
    return Request.get('/posts/dashboard/reports', { ...data });
  },
  delete: (id: string) => {
    return Request.delete(`/posts/dashboard/report/${id}`);
  },
  userReports: (data: object) => Request.get('/users/dashboard/report/list', data),
  userReportDelete: (id: string) => Request.get(`/users/dashboard/report/list/${id}`),
  chatReports: (data: object) => Request.get(`/chat/reports/`, data),
};
