import { Request } from '../../index';

export const CommentsPrograms = {
  list: (data: object) => {
    return Request.get('/education/dashboard/episode-comments/', data);
  },
  update: (id: number, data: object) => {
    return Request.patch(`/education/dashboard/episode-comments/${id}/`, data);
  },
  getReplies: (id: number) => {
    return Request.get(`/education/dashboard/episode-comments/${id}/`);
  },
  delete: (id: number) => {
    return Request.delete(`/education/dashboard/episode-comments/${id}/`);
  },
  createReply: (data: object, id: number) => {
    return Request.post(`/education/dashboard/episode-comments/${id}/reply/`, data);
  },
  addToArchive: (id: number) => {
    return Request.post(`/education/dashboard/episode-comments/${id}/archive/`);
  },
  unArchive: (id: string) => {
    return Request.post(`/education/dashboard/episode-comments/${id}/unarchive/`);
  },
  translate: (id: number, data: object) => {
    return Request.post(`/education/dashboard/episode-comments/${id}/translate/`, data);
  },
  markCommentAnswered: (id: number) => {
    return Request.post(`/education/dashboard/episode-comments/${id}/mark-as-answered/`);
  },
  setResponsible: (id: number, data = {}) => {
    return Request.post(`/education/dashboard/episode-comments/${id}/set-responsible/`, data);
  },
  publish: (id: number, data = {}) => {
    return Request.post('/education/dashboard/episode-replies/{id}/publish/', data);
  },
};
