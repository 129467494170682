import React, { useState } from 'react';
import { Select } from 'antd';

interface SelectProps {
  data: any[];
  placeholder: string;
  onChange?: (value: string) => void;
  disabled?: boolean;
  value?: any;
  makeCall?: () => any;
}

const { Option } = Select;

export const MultiSelectWithCall = ({ data, placeholder, onChange, disabled, value, makeCall }: SelectProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [localData, setLocalData] = useState<{ id: string; title: string; name: string }[]>([]);

  const onFocusChange = async () => {
    if (makeCall && localData && localData.length === 0) {
      setLoading(true);

      const res = await makeCall();
      setLocalData(res.results);

      setLoading(false);
    }
  };

  return (
    <Select
      mode="tags"
      placeholder={placeholder}
      showSearch
      loading={loading}
      onChange={onChange}
      optionFilterProp="children"
      disabled={disabled}
      value={value}
      onFocus={onFocusChange}
    >
      {localData.length > 0 &&
        localData.map(item => (
          <Option key={item.id} value={item.id.toString()}>
            {item.title ? item.title : item.name}
          </Option>
        ))}
      {data.length > 0 &&
        data.map(item => (
          <Option key={item.id} value={item.id.toString()}>
            {item.title ? item.title : item.name}
          </Option>
        ))}
    </Select>
  );
};
