import { Request } from '../../index';
import { Prompts } from './chat.types';

export const ChatApi = {
  list: () => {
    return Request.get('/chat/dashboard/prompts/');
  },

  create: (data: Prompts) => {
    return Request.post('/chat/dashboard/prompts/', data);
  },

  byId: (id: string) => {
    return Request.get(`/chat/dashboard/prompts/${id}/`);
  },

  delete: (id: number) => {
    return Request.delete(`/chat/dashboard/prompts/${id}/`);
  },

  update: (data: Prompts, id: string) => {
    return Request.patch(`/chat/dashboard/prompts/${id}/`, { ...data });
  },
};
