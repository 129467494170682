import { useEffect, useRef, useState } from 'react';

interface InfiniteScrollProps {
  parentsNodeClassName?: string;
  nodeClassName?: string;
  isDirectChild?: boolean;
  hasMore: boolean;
  onLoadMore: () => void;
}

const infiniteScrollClassName = 'm-infinite-scroll';

export const InfiniteScroll: React.FC<InfiniteScrollProps> = ({
  children,
  nodeClassName,
  parentsNodeClassName,
  isDirectChild,
  hasMore,
  onLoadMore,
}) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const scrollableElement = useRef<Element | null>(null);

  const [loadMore, setLoadMore] = useState(false);

  useEffect(() => {
    if (loadMore) {
      onLoadMore();

      setLoadMore(false);
    }
  }, [loadMore]);

  useEffect(() => {
    if (nodeClassName) {
      const parentsFoundElements = parentsNodeClassName
        ? document.getElementsByClassName(parentsNodeClassName)[0]?.getElementsByClassName(nodeClassName)
        : undefined;

      const foundElements = parentsFoundElements?.length
        ? parentsFoundElements
        : document.getElementsByClassName(nodeClassName);
      if (foundElements.length) {
        scrollableElement.current = foundElements[0];

        scrollableElement.current.addEventListener('scroll', scrollCallback);
      }
    }

    const directChild = wrapperRef.current?.firstElementChild?.firstElementChild;

    if (isDirectChild && directChild) {
      scrollableElement.current = directChild;
      scrollableElement.current.addEventListener('scroll', scrollCallback);
    }

    return () => {
      if (scrollableElement.current) {
        scrollableElement.current.removeEventListener('scroll', scrollCallback);
      }
    };
  }, []);

  const scrollCallback = (e: any) => {
    const target = e.target;

    const targetPosition = Math.ceil(target.scrollTop + target.offsetHeight);

    if (hasMore && targetPosition === target.scrollHeight) {
      setLoadMore(true);

      target.scrollTo(0, target.scrollHeight);
    }
  };

  return (
    <div ref={wrapperRef} className={infiniteScrollClassName}>
      {children}
    </div>
  );
};
