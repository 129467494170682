import Loadable from 'react-loadable';
import { LoadingPlaceholder } from 'ui/atoms';

import { Route } from 'lib/router/routes';

export const reportsAndFeedback: Route[] = [
  {
    path: '/reports-and-feedback',
    text: 'reportsAndFeedback',
    icon: 'notification',
    children: [
      {
        path: '/chat-reports',
        text: 'chatReports',
        icon: 'warning',
        component: Loadable({
          loader: () => import('../../../../features/reportsAndFeedback/pages/ChatReportsPage/ChatReportsPage'),
          loading: LoadingPlaceholder,
          delay: 300,
        }),
        children: [],
        groups: ['moderator', 'content_creator', 'sales_specialist', 'warehouse_admin'],
      },
      {
        path: '/program-surveys',
        text: 'nps',
        icon: 'file-protect',
        component: Loadable({
          loader: () => import('../../../../features/reportsAndFeedback/pages/NetPromoterScoreReport'),
          loading: LoadingPlaceholder,
          delay: 300,
        }),
        children: [],
        groups: ['moderator', 'content_creator', 'sales_specialist', 'warehouse_admin'],
      },
    ],
    groups: ['sales_specialist', 'moderator', 'warehouse_admin', 'content_creator'],
  },
];
