import Loadable from 'react-loadable';
import { LoadingPlaceholder } from 'ui/atoms';

import { Route } from 'lib/router/routes';
import { responsibleGroups } from './responsibleGroups';

export const tasks: Route[] = [
  {
    path: '/task-list',
    text: 'tasks',
    icon: 'profile',
    children: [
      {
        path: '/responsible-groups',
        text: 'responsibleGroups',
        icon: 'team',
        children: responsibleGroups,
        groups: ['moderator', 'content_creator', 'consilier', 'warehouse_admin', 'sales_specialist'],
      },
      {
        path: '/task-list',
        text: 'taskList',
        icon: 'ordered-list',
        component: Loadable({
          loader: () => import('../../../../features/payments-management/pages/TaskListPage'),
          loading: LoadingPlaceholder,
          delay: 300,
        }),
        children: [],
        groups: ['sales_specialist', 'moderator', 'warehouse_admin', 'content_creator'],
      },
    ],
    groups: ['sales_specialist', 'moderator', 'warehouse_admin', 'content_creator'],
  },

  {
    path: '/task-list/:id/details',
    text: null,
    component: Loadable({
      loader: () => import('../../../../features/payments-management/pages/TaskDetailsPage'),
      loading: LoadingPlaceholder,
      delay: 300,
    }),
    children: [],
    groups: ['sales_specialist'],
  },
];
