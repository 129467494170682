import { Request } from '../../index';
import { SubscriptionAction } from './orders.types';

export const OrdersApi = {
  list: (data: object) => {
    return Request.get('/sales/dashboard/orders/', data);
  },
  create: (data: object) => {
    return Request.post('/sales/dashboard/price/', data);
  },
  byId: (id: number) => {
    return Request.get(`/sales/dashboard/subscriptions/${id}/`);
  },
  update: (id: number, data: object) => {
    return Request.patch(`/sales/dashboard/price/${id}/`, { ...data });
  },
  delete: (id: number, data: SubscriptionAction) => {
    return Request.post(`/sales/dashboard/orders/${id}/cancel/`, data);
  },
  listTransactions: (id: number, data: object) => {
    return Request.get(`/sales/dashboard/subscriptions/${id}/transactions/`, data);
  },
  setPause: (id: number, data: SubscriptionAction) => {
    return Request.post(`/sales/dashboard/orders/${id}/pause_invoice_collection/`, { ...data });
  },
  setDiscount: (id: number, data: SubscriptionAction) => {
    return Request.post(`/sales/dashboard/orders/${id}/set_discount/`, { ...data });
  },
  closeInstallments: (id: number, data: SubscriptionAction) => {
    return Request.post(`/sales/dashboard/orders/${id}/close_installments_subscription/`, { ...data });
  },
  advanceInstallments: (id: number, data: SubscriptionAction) => {
    return Request.post(`/sales/dashboard/orders/${id}/advance_installments/`, { ...data });
  },
  transactions: (id: number, data: object) => {
    return Request.get(`/sales/dashboard/orders/${id}/transactions/`, { ...data });
  },
  getSubscription: (id: number) => {
    return Request.get(`/sales/dashboard/subscriptions/${id}/`);
  },
  getOrder: (id: string) => {
    return Request.get(`/sales/dashboard/orders/${id}/`);
  },
  getUtmMedium: (params: object = {}) => {
    return Request.get('/sales/dashboard/utm-medium/', params);
  },
  getUtmSource: (data: object = {}) => {
    return Request.get('/sales/dashboard/utm-sources/', data);
  },
  getUtmType: (params: object = {}) => {
    return Request.get('/sales/dashboard/utm-type/', params);
  },
  confirmTransaction: (data: object) => {
    return Request.post('/sales/confirm-transaction/', data);
  },
  cancelTransaction: (data: object) => {
    return Request.post('/sales/cancel-transaction/', data);
  },
  getGroupedOrders: (data: object) => {
    return Request.get(`/sales/dashboard/orders-groups/`, data);
  },
  reactivate: (id: number, data: SubscriptionAction) => {
    return Request.post(`/sales/dashboard/orders/${id}/reactivate/`, data);
  },
  upgrade: (id: number, data: SubscriptionAction) => {
    return Request.post(`/sales/dashboard/orders/${id}/upgrade/`, data);
  },
  evaluate_offer: (id: number, params: SubscriptionAction) => {
    return Request.get(`/sales/dashboard/orders/${id}/evaluate_price/`, params);
  },
  evaluate_cancel_upgrade: (id: number, params: SubscriptionAction) => {
    return Request.get(`/sales/dashboard/orders/${id}/evaluate_cancel_upgrade/`, params);
  },
  cancelUpgrade: (id: number, data: SubscriptionAction) => {
    return Request.post(`/sales/dashboard/orders/${id}/cancel_upgrade/`, data);
  },
  addScheduleCancel: (id: number, data: SubscriptionAction) => {
    return Request.post(`/sales/dashboard/orders/${id}/schedule-cancel/`, data);
  },
  removeScheduleCancel: (id: number, data: object = {}) => {
    return Request.post(`/sales/dashboard/orders/${id}/remove-schedule-cancel/`, data);
  },
  getQuizzes: (params: object = {}) => {
    return Request.get(`/sales/dashboard/order-quiz/`, params);
  },
  getQuizById: (id: number) => {
    return Request.get(`/sales/dashboard/order-quiz/${id}/`);
  },
  scheduleArchive: (id: number, data: SubscriptionAction) => {
    return Request.post(`/sales/dashboard/orders/${id}/schedule-archive/`, data);
  },
  updateUtmSource: (id: number, data: object) => {
    return Request.patch(`/sales/dashboard/orders/${id}/utm/`, data);
  },
  exportCSV: (params: object) => {
    return Request.get('/sales/dashboard/orders/get-csv/', params);
  },
};
