export type $Object = { [key: string]: any };

export const withValues = (obj: $Object) =>
  Object.keys(obj)
    .filter(i => {
      const value = obj[i];

      return value !== null && value !== undefined && value !== '';
    })
    .reduce((acc, current) => ({ ...acc, [current]: obj[current] }), {});

export const replaceEmptyWithNull = (obj: $Object) =>
  Object.keys(obj).reduce((acc, current) => {
    const currentValue = obj[current];

    if (currentValue === '') {
      return { ...acc, [current]: null };
    }

    return { ...acc, [current]: currentValue };
  }, {});

export function getOnlyExistingKeys<T extends $Object>(obj1: $Object, obj2: $Object): T {
  const obj1Keys = Object.keys(obj1);

  return Object.keys(obj2).reduce((acc, i) => ({ ...acc, ...(obj1Keys.includes(i) ? { [i]: obj2[i] } : {}) }), {} as T);
}

export const createNotateObj = (keys: string, value: any): $Object => {
  const tempObject = {};
  let container: $Object = tempObject;
  keys.split('.').forEach((k, i, values) => {
    container = container[k] = i === values.length - 1 ? value : {};
  });

  return tempObject;
};

export const createNotateFromObject = (obj: $Object) => {
  let key = '';
  const checkObj = (deepObj: $Object) => {
    for (let k in deepObj) {
      key = key + (key ? `.${k}` : k);
      if (typeof deepObj[k] !== 'object' || Array.isArray(deepObj[k])) return;
      checkObj(deepObj[k]);
    }
  };

  checkObj(obj);

  return key;
};

export const recursiveEmptyWithNull = (obj: $Object, setNull?: boolean) => {
  const iter = (value: $Object) => {
    for (let key in value) {
      const item = value[key];
      if (typeof item === 'string' && !item.trim()) {
        value[key] = null;
      } else if (setNull && item === undefined) {
        value[key] = null;
      } else if (typeof item === 'object') {
        iter(item);
      }
    }
  };

  iter(obj);
  return obj;
};

export function removeObjectKeys<T extends $Object>(obj: T, keys: (keyof T)[]) {
  const newObj = Object.keys(obj)
    .filter(objKey => !keys.includes(objKey))
    .reduce((newObj: $Object, key) => {
      newObj[key] = obj[key];
      return newObj;
    }, {} as Partial<T>);

  return newObj;
}