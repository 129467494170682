import { Request } from '../../index';

export const ProvidersAPI = {
  get: (data = {}) => Request.get('/sales/dashboard/providers/', data),
  create: (data: object) => Request.post(`/sales/dashboard/providers/`, data),
  getById: (id: string) => Request.get(`/sales/dashboard/providers/${id}/`),
  update: (id: number, data: object) => Request.patch(`/sales/dashboard/providers/${id}/`, { ...data }),
  delete: (id: number) => Request.delete(`/sales/dashboard/providers/${id}/`),
  validate: (id: number) => Request.post(`/sales/dashboard/providers/${id}/validate/`),
  clone: (id: number) => Request.post(`/sales/dashboard/providers/${id}/clone/`),
};
