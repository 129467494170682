import { Request } from '../../index';

export const TasksApi = {
         list: (data: object) => {
           return Request.get('/task/', data);
         },
         create: (data: object) => {
           return Request.post('/task/', data);
         },
         byId: (id: number) => {
           return Request.get(`/task/${id}/`);
         },
         update: (id: number, data: object) => {
           return Request.patch(`/task/${id}/`, { ...data });
         },
         assignResponsible: (id: number, data: object) => {
           return Request.patch(`/task/${id}/assign-responsible/`, data);
         },
         changeStatus: (id: number, data: object) => {
           return Request.patch(`/task/${id}/change-status/`, data);
         },
         bulkAssignResponsible: (data: object, params: string) => {
           return Request.post(`/task/bulk-assign-responsible/?${params}`, data);
         },
         bulkChangeStatus: (data: object, params: string) => {
           return Request.post(`/task/bulk-change-status/?${params}`, data);
         },
         addReminder: (id: number, data: object) => {
           return Request.patch(`/task/${id}/add-reminder/`, data);
         },

         groups: {
           list: (data: object) => {
             return Request.get('/task/groups/', data);
           },
           create: (data: object) => {
             return Request.post('/task/groups/', data);
           },
           byId: (id: number) => {
             return Request.get(`/task/groups/${id}/`);
           },
           update: (id: number, data: object) => {
             return Request.patch(`/task/groups/${id}/`, { ...data });
           },

           responsibleUsers: (data: object) => {
             return Request.get('/task/groups/responsible-users/', data);
           },
           responsibleUsersSet: (data: object) => {
             return Request.post('/task/groups/responsible-users/set/', data);
           },
         },

         status: {
           list: (data: object) => {
             return Request.get('/task/status/', data);
           },
           create: (data: object) => {
             return Request.post('/task/status/', data);
           },
           byId: (id: number) => {
             return Request.get(`/task/status/${id}/`);
           },
           update: (id: number, data: object) => {
             return Request.patch(`/task/status/${id}/`, { ...data });
           },
           delete: (id: number) => {
             return Request.delete(`/task/status/${id}/`);
           },
         },

         users: {
           list: (data: object) => {
             return Request.get('/task/users/', data);
           },
           byId: (id: number) => {
             return Request.get(`/task/users/${id}/`);
           },
         },

         types: {
           list: (data: object) => {
             return Request.get('/task/types/', data);
           },
           create: (data: object) => {
             return Request.post('/task/types/', data);
           },
           byId: (id: number) => {
             return Request.get(`/task/types/${id}/`);
           },
           update: (id: number, data: object) => {
             return Request.patch(`/task/types/${id}/`, { ...data });
           },
           delete: (id: number) => {
             return Request.delete(`/task/types/${id}/`);
           },
         },

         programs: {
           list: (data: object) => {
             return Request.get('/task/program-types/', data);
           },
           update: (id: number, data: object) => {
             return Request.post(`/task/program-types/${id}/set/`, { ...data });
           },
         },
       };
