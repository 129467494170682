import { Request } from '../../index';

export const ProviderCountryAPI = {
  get: (data: object = {}) => {
    return Request.get('/sales/dashboard/provider-country/', data);
  },
  create: (data: object) => {
    return Request.post(`/sales/dashboard/provider-country/`, data);
  },
  getById: (id: number) => {
    return Request.get(`/sales/dashboard/provider-country/${id}/`);
  },
  update: (id: number, data: object) => {
    return Request.patch(`/sales/dashboard/provider-country/${id}/`, { ...data });
  },
  delete: (id?: number) => {
    return Request.delete(`/sales/dashboard/provider-country/${id}/`);
  },
};
