import { useState, useMemo, useContext } from 'react';
import { Button, DatePicker, Form, Input, Modal, message } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { useIntl } from 'estafette-intl';
import moment from 'moment';
import { ButtonProps } from 'antd/lib/button';

import { formItemLayout } from 'features/posts/constants';
import { Transaction } from 'lib/entities/payments-management/detalls';
import { ProfileContext } from 'contexts';
import { OrdersApi } from 'lib/api';
import { resetErrorFromServer, setFieldsError } from 'lib/utils';

const formatDate = 'DD/MM/YYYY HH:mm:ss';

export interface PlannedCancellationOrderProps extends FormComponentProps {
  order?: Transaction;
  buttonProps?: ButtonProps;
  onSuccess?: () => void;
}

export const PlannedCancellationOrder = Form.create<PlannedCancellationOrderProps>({
  onValuesChange({ form }, changedValues) {
    resetErrorFromServer(form, changedValues);
  },
})(({ form, order, buttonProps, onSuccess }: PlannedCancellationOrderProps) => {
  const { t } = useIntl();
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const {
    groups: { isAdministrator, isConsilier, isCustomerCare },
  } = useContext(ProfileContext);

  const acceptUserGroups = isAdministrator || isCustomerCare || isConsilier;

  if (!order || !acceptUserGroups) return null;
  const isPlannedCancellation = !!order.schedule_cancel;

  const { getFieldDecorator, validateFieldsAndScroll } = form;

  const toggleIsOpen = () => setIsOpen(isOpen => !isOpen);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    validateFieldsAndScroll(async (err, formFields) => {
      if (err) return;

      try {
        setLoading(true);
        await (isPlannedCancellation
          ? OrdersApi.removeScheduleCancel(order.id, formFields)
          : OrdersApi.addScheduleCancel(order.id, { billing_id: order.billing_id, ...formFields }));

        setIsOpen(false);
        onSuccess?.();
      } catch (e) {
        const error = e as any;
        if (error.apiError?.error?.detail) {
          return message.error(error.apiError.error.detail);
        }
        if (error.apiError && error.apiError.error && !error.apiError.error.detail) {
          return setFieldsError(error.apiError.error, form);
        }
        message.error(t('serverError'));
      } finally {
        setLoading(false);
      }
    });
  };

  const subscriptionData = useMemo(() => order?.program_subscriptions?.[0] && order?.event_subscriptions?.[0], [order]);

  const disabledDate = (current?: moment.Moment | null) => {
    const date = moment();
    date
      .hour(0)
      .minute(0)
      .second(0);

    const currentDate = moment(current);
    currentDate
      .hour(0)
      .minute(0)
      .second(0);

    return currentDate.isBefore(date);
  };

  return (
    <>
      <Button {...buttonProps} onClick={toggleIsOpen}>
        {!isPlannedCancellation ? t('plannedCancellation') : t('removePlannedCancellation')}
      </Button>

      <Modal
        title={!isPlannedCancellation ? t('plannedCancellation') : t('removePlannedCancellation')}
        visible={isOpen}
        onCancel={toggleIsOpen}
        width={1000}
        footer={null}
        destroyOnClose
      >
        <Form {...formItemLayout} onSubmit={handleSubmit}>
          {!isPlannedCancellation && (
            <Form.Item label={t('date')}>
              {getFieldDecorator('schedule', {
                rules: [{ required: true, message: t('pleaseSelectA', { item: t('date').toLowerCase() }) }],
                initialValue: subscriptionData?.next_invoice
                  ? moment(subscriptionData.next_invoice).subtract(1, 'hour')
                  : undefined,
              })(<DatePicker format={formatDate} showTime disabledDate={disabledDate} />)}
            </Form.Item>
          )}

          <Form.Item label={t('comments')}>
            {getFieldDecorator('comment', {
              rules: [{ required: true, message: t('pleaseInput', { item: t('comments').toLowerCase() }) }],
            })(<Input.TextArea placeholder={t('comments')} />)}
          </Form.Item>

          <Form.Item
            wrapperCol={{
              span: 12,
              offset: 12,
            }}
          >
            <Button type="primary" loading={loading} htmlType="submit">
              {t('save')}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
});
