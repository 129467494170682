import Loadable from 'react-loadable';
import { LoadingPlaceholder } from 'ui/atoms';

import { Route } from 'lib/router/routes';
import { offers } from './offers';
import { parenting } from './parenting';

export const productsAndOffers: Route[] = [
  {
    path: '/offers',
    text: 'productsAndOffers',
    icon: 'shopping-cart',
    children: [
      {
        path: '/offer-list',
        text: 'offers',
        icon: 'shopping-cart',
        children: offers,
        groups: ['moderator', 'content_creator', 'consilier'],
      },
      {
        path: '/parenting',
        text: 'parenting',
        icon: 'book',
        children: parenting,
        groups: [],
      },
      {
        path: '/physical-products',
        text: 'physicalProducts',
        icon: 'shop',
        component: Loadable({
          loader: () => import('../../../../features/warehouse/pages/ProductsPage'),
          loading: LoadingPlaceholder,
          delay: 300,
        }),
        children: [],
        groups: ['customer_care', 'moderator', 'content_creator', 'consilier', 'sales_specialist'],
      },
      {
        path: '/physical-products/add',
        text: null,
        component: Loadable({
          loader: () => import('../../../../features/warehouse/pages/AddProductPage'),
          loading: LoadingPlaceholder,
          delay: 300,
        }),
        children: [],
        groups: ['moderator', 'customer_care', 'sales_specialist', 'content_creator'],
      },
      {
        path: '/physical-products/:id/edit',
        text: null,
        component: Loadable({
          loader: () => import('../../../../features/warehouse/pages/EditProductPage'),
          loading: LoadingPlaceholder,
          delay: 300,
        }),
        children: [],
        groups: ['moderator', 'customer_care', 'sales_specialist', 'content_creator'],
      },
      {
        path: '/inventory',
        text: 'inventory',
        icon: 'reconciliation',
        component: Loadable({
          loader: () => import('../../../../features/warehouse/pages/InventoryPage'),
          loading: LoadingPlaceholder,
          delay: 300,
        }),
        children: [],
        groups: ['moderator', 'content_creator', 'consilier', 'sales_specialist', 'customer_care'],
      },
      {
        path: '/inventory/stocks/:stockId/items/:productId',
        text: null,
        component: Loadable({
          loader: () => import('../../../../features/warehouse/pages/InventoryItemPage'),
          loading: LoadingPlaceholder,
          delay: 300,
        }),
        children: [],
        groups: ['moderator', 'content_creator', 'consilier', 'sales_specialist', 'customer_care'],
      },
      {
        path: '/gifts',
        text: 'gifts',
        icon: 'gift',
        component: Loadable({
          loader: () => import('../../../../features/warehouse/pages/GiftsPage'),
          loading: LoadingPlaceholder,
          delay: 300,
        }),
        children: [],
        groups: ['content_creator', 'sales_specialist', 'moderator', 'consilier'],
      },
    ],
    groups: [],
  },
];
