import { Editor } from '@tinymce/tinymce-react';
import { forwardRef, useRef } from 'react';
import { $Object } from 'lib/object';
import { uploadMediaRoute } from 'lib/globalVars';

interface HtmlEditorProps {
  value?: string;
  onChange?: (value: string) => void;
  customStyle?: 'mobile' | 'offerDescription';
  initialValue?: string;
  height?: string;
  width?: string;
  licenseKey?: string;
}

const cssOptions: $Object = {
  mobile:
    "@font-face { font-family: 'SF Pro Medium'; src: url('https://tribes-web.devebs.net/fonts/SFProDisplay-Medium.ttf') format('truetype'); } body { font-family: sans-serif; word-break: normal; }",
  offerDescription:
    "h2 { margin: 3rem 0 1.5rem 0; font-size: 1.87066rem; color: #141414; } p, ul { margin: 1.5rem 0; font-size: 1.33618rem; font-family: 'Roboto Regular', 'Helvetica Neue', Helvetica, Arial, sans-serif; color: #5e5e5e; }",
};

export const HtmlEditor = forwardRef<any, HtmlEditorProps>(
  ({ onChange, customStyle = '', height = 700, width, ...props }, ref) => {
    const editorRef = useRef<$Object>({});

    return (
      <Editor
        tinymceScriptSrc="/tinymce/tinymce.min.js"
        licenseKey={process.env.REACT_APP_TINYMCE_KEY}
        ref={ref}
        onInit={(_, editor) => (editorRef.current = editor)}
        init={{
          extended_valid_elements: 'script[language|type|src],style',
          plugins: 'image link lists code',
          ally_advanced_options: true,
          toolbar:
            'code | undo redo | formatselect fontsizeselect | bold italic lineheight backcolor | alignleft aligncenter alignright alignjustify bullist numlist outdent indent | removeformat | help',
          content_style: cssOptions[customStyle] || '',
          fontsize_formats: '8pt 10pt 12pt 14pt 16pt 18pt 20pt 22pt 24pt 36pt',
          height: height,
          width: width,
          block_unsupported_drop: false,
          automatic_uploads: true,
          file_picker_types: 'image',
          images_upload_url: uploadMediaRoute,
          promotion: false,
          images_upload_handler: (blobInfo, progress) =>
            new Promise((resolve, reject) => {
              const xhr = new XMLHttpRequest();
              xhr.open('POST', uploadMediaRoute);

              xhr.upload.onprogress = function(e) {
                progress && progress((e.loaded / e.total) * 100);
              };

              xhr.onload = function() {
                if (xhr.status === 403) {
                  reject('HTTP Error: ' + xhr.status);

                  return;
                }

                if (xhr.status < 200 || xhr.status >= 300) {
                  reject('HTTP Error: ' + xhr.status);

                  return;
                }

                const json = JSON.parse(xhr.responseText);

                if (!json || typeof json.url != 'string') {
                  reject('Invalid JSON: ' + xhr.responseText);

                  return;
                }

                resolve(json.url);
              };

              xhr.onerror = function() {
                reject('Image upload failed due to a XHR Transport error. Code: ' + xhr.status);
              };

              const formData = new FormData();
              formData.append('file', blobInfo.blob(), blobInfo.filename());

              xhr.send(formData);
            }),
          formats: {
            bold: [
              { inline: 'span', styles: { fontWeight: 'bold', fontFamily: 'Arial' } },
              { inline: 'strong', remove: 'all' },
              { inline: 'b', remove: 'all' },
            ],
          },
        }}
        onEditorChange={onChange}
        {...props}
      />
    );
  },
);
