import { Request } from '../../index';

export const NotesApi = {
  get: (data: object) => {
    return Request.get(`/sales/dashboard/price-notes/`, data);
  },
  getById: (id: string, data: object) => {
    return Request.get(`/sales/dashboard/price-notes/${id}/`, data);
  },
  create: (data: object) => {
    return Request.post(`/sales/dashboard/price-notes/`, { ...data });
  },
  update: (data: object) => {
    return Request.patch(`/sales/dashboard/price-notes/`, { ...data });
  },
};
