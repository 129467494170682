import { useMemo, useState } from 'react';
import { Icon, Modal, Tooltip, notification } from 'antd';
import { useIntl } from 'estafette-intl';

import { copyToClipboard, trimText } from 'lib/utils';

interface LimitedTextProps extends React.HTMLAttributes<HTMLSpanElement> {
  data?: string;
  withCopy?: boolean;
  item?: string;
  maxLength?: number;
  modal?: boolean;
  isCode?: boolean;
}

export const LimitedText = ({
  data,
  withCopy,
  item = 'text',
  style,
  maxLength = 50,
  modal,
  children,
  isCode = true,
  title = 'lastError',
  ...props
}: LimitedTextProps) => {
  const { t } = useIntl();
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = async () => {
    if (!withCopy || !data) return;
    try {
      await copyToClipboard(data);
      notification.success({
        message: t('success'),
        description: t('successfullyCopiedToClipboard', { item: t(item) }),
      });
    } catch (e) {
      notification.error({ message: t('failedToCopy'), description: t('accesDenied') });
    }
  };

  const toggleIsOpen = () => setIsOpen(isOpen => !isOpen);

  const prettyError = useMemo(() => {
    try {
      return !!data && JSON.stringify(JSON.parse(data), null, 2);
    } catch {
      return data;
    }
  }, [data]);

  return (
    <>
      <span
        style={{
          ...(withCopy && data && { cursor: 'pointer' }),
          wordBreak: children ? 'break-word' : 'break-all',
          ...style,
        }}
        {...(modal && data ? { className: 'btn-with-event', onClick: toggleIsOpen } : { onClick: handleClick })}
        {...props}
      >
        {!modal && data ? (
          <Tooltip title={data} {...(!(data.length > maxLength) && { visible: false })}>
            {children ? children : trimText(data, maxLength)} {withCopy && <Icon type="copy" />}
          </Tooltip>
        ) : children ? (
          children
        ) : (
          trimText(data, maxLength)
        )}
      </span>

      {modal && !!data && (
        <Modal
          title={t(title)}
          width={1000}
          visible={isOpen}
          onCancel={toggleIsOpen}
          okText={t('copyToClipboard')}
          onOk={handleClick}
        >
          {isCode ? <pre>{prettyError}</pre> : <p>{data}</p>}
        </Modal>
      )}
    </>
  );
};
