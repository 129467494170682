import Loadable from 'react-loadable';
import { LoadingPlaceholder } from 'ui/atoms';

import { Route } from 'lib/router/routes';

export const reports: Route[] = [
  {
    path: '/drop-off-report',
    text: 'dropOffReport',
    icon: 'percentage',
    component: Loadable({
      loader: () => import('../../../../features/payments-management/pages/DropOffReportPage'),
      loading: LoadingPlaceholder,
      delay: 300,
    }),
    children: [],
    groups: ['sales_specialist'],
  },
];
