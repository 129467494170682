import { BillingStatuses } from 'lib/entities/payments-management/orders';
import { UserIncomplete } from '../users/users.types';

export interface Task {
  id: number;
  responsible: UserIncomplete;
  group?: ResponsibleTaskShortGroup;
  order: TaskOrder;
  created_at: string;
  modified_at: string;
  type: TaskTypeDetail;
  due_date?: string;
  status: StatusDetail;
  created_by: number;
  modified_by: number;
  language: string;
  utm_source?: string;
  reminder_date?: string;
  reminder_description?: string;
  reminder_error?: string;
  reminder_sent: boolean;
  reminder_title?: string;
  schedule?: string;
  follow_up?: string;
  follow_up_tz?: string;
  customer?: TaskCustomer;
}

export interface TaskOrder {
  id: number;
  date: string;
  user?: UserIncomplete;
  price: number;
  billing_status: BillingStatuses;
}

export interface TaskGroup {
  id: number;
  name: string;
  allocation: number;
}

export enum TaskStatus {
  COMPLETE = 'complete',
  INCOMPLETE = 'incomplete',
  SCHEDULED = 'scheduled',
  ENQUEUED = 'enqueued',
}

export const taskStatuses: Record<TaskStatus, { label: string; color: string }> = {
  [TaskStatus.INCOMPLETE]: {
    label: 'Incomplete',
    color: 'volcano',
  },
  [TaskStatus.COMPLETE]: {
    label: 'Complete',
    color: 'green',
  },
  [TaskStatus.SCHEDULED]: {
    label: 'Scheduled',
    color: 'geekblue',
  },
  [TaskStatus.ENQUEUED]: {
    label: 'Enqueued',
    color: 'gold',
  },
};

export interface ResponsibleTaskGroup {
  id: number;
  name: string;
  project: string;
  task_types: ResponsibleTaskType[];
  admin: UserIncomplete;
  is_active: boolean;
  created_at: string;
  modified_at: string;
  members: number[];
  members_allocation: MembersAllocation[];
}

export type ResponsibleTaskShortGroup = Omit<ResponsibleTaskGroup, 'task_types' | 'members_allocation'>;

export interface MembersAllocation {
  allocation: 50 | 100;
  user: UserIncomplete;
}

export interface ResponsibleTaskType {
  type: TaskTypeDetail;
  sla: number;
}

export interface StatusDetail {
  id: number;
  name: string;
  created_at: string;
  modified_at: string;
  projects: string[];
  types: TaskTypeDetail[];
  state: TaskStatus;
  ordering: number;
}

export interface TaskTypeDetail {
  id: number;
  name: string;
  code: string;
  assignment_type: AssignmentType;
  order_statuses: BillingStatuses[];
  sort: number;
  trigger: number;
  based_on: number;
  created_at: string;
  modified_at: string;
  created_by: number;
  modified_by: number;
  trigger_type: string;
  autocomplete_on_execute: AutocompleteOnExecuteType[];
}

export interface ProgramTaskType {
  id: number;
  project: string;
  title: string;
  settings: Record<number, boolean>;
}

export interface TaskCustomer {
  id?: number;
  email?: string;
  full_name?: string;
  phone?: string;
}

export enum AssignmentType {
  LINKED = 'linked',
  ROUND_ROBIN = 'round_robin',
}

export enum TriggerType {
  MANUAL = 'manual',
  ORDER_START = 'order_start',
}

export enum AutocompleteOnExecuteType {
  NEW = 'new',
  INITIALIZING = 'initializing',
  ERROR = 'error',
  REFUNDED = 'refunded',
  ACTIVE = 'active',
  SUSPENDED = 'suspended',
  DEACTIVATED = 'deactivated',
  PAYMENT_ERROR = 'payment_error',
  COMPLETED = 'completed',
  ARCHIVED = 'archived',
  PROCESSING = 'processing',
  EXPIRED = 'expired',
}

// export enum TaskType {
//   SALES_CALL = 'sales_call',
//   FOLLOW_UP_FIRST_PAYMENT = 'follow_up_first_payment',
//   WELCOME_CALL = 'welcome_call',
//   SECOND_CALL = 'second_call',
//   FOLLOW_UP_RECURRING_PAYMENT = 'follow_up_recurring_payment',
//   SALES_CALL_PROSPECTS = 'sales_call_prospects',
//   REWARD = 'reward',
//   ENGAGEMENT_CALL = 'engagement_call',
// }

// export const taskTypes: Record<TaskType, string> = {
//   [TaskType.SALES_CALL]: 'Sales call',
//   [TaskType.FOLLOW_UP_FIRST_PAYMENT]: 'Follow up first payment',
//   [TaskType.WELCOME_CALL]: 'Welcome call',
//   [TaskType.SECOND_CALL]: 'Second call',
//   [TaskType.FOLLOW_UP_RECURRING_PAYMENT]: 'Follow up recurring payment',
//   [TaskType.SALES_CALL_PROSPECTS]: 'Sales call prospects',
//   [TaskType.REWARD]: 'Reward',
//   [TaskType.ENGAGEMENT_CALL]: 'Engagement call',
// };
