import { Link } from 'react-router-dom';

export const replaceUserReply = (text?: string) => {
  const regEx = /\{(.*?)\}/g;

  if (text && text.search(regEx) !== -1) {
    const userId = text.substring(text.indexOf('{[') + 2, text.indexOf(']['));
    const userName = text.substring(text.indexOf('][') + 2, text.indexOf(']}'));

    return (
      <>
        <Link to={`users/detail/${userId}`}>{userName}</Link>

        {text.replace(regEx, '')}
      </>
    );
  }

  return text;
};
