import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Icon, Input, Row } from 'antd';
import { GetFieldDecoratorOptions } from 'antd/lib/form/Form';

import './DynamicItems.scss';

interface DynamicItemsProps {
  getFieldDecorator: <T extends Object = {}>(
    id: keyof T,
    options?: GetFieldDecoratorOptions | undefined,
  ) => (node: React.ReactNode) => React.ReactNode;
  value?: { [key: string]: string };
}

export const DynamicItems = React.forwardRef<any, DynamicItemsProps>(
  ({ getFieldDecorator, value = {}, ...props }, ref) => {
    const [keys, setKeys] = useState<{ [key: string]: string }>({});

    useEffect(() => {
      const setNewKeys = () => {
        if (value && Object.keys(value).length > 0) {
          setKeys(prevState => ({ ...prevState, ...value }));
        }
      };

      setNewKeys();
    }, [value]);

    const onFieldAdd = () => {
      const currentLength = Object.keys(keys).length;
      setKeys(prevState => ({ ...prevState, [currentLength]: 'new' }));
    };

    const onFieldDelete = (key: string) => {
      const newKeys = { ...keys };

      delete newKeys[key];

      setKeys(newKeys);
    };

    return (
      <div className="dynamic-items" ref={ref}>
        {keys &&
          Object.keys(keys).length > 0 &&
          Object.keys(keys).map((item, key) => (
            <Row key={item}>
              <Col xs={22} sm={11}>
                <Form.Item className="d-flex" label="Key:">
                  {getFieldDecorator(`keys.${key}`, { initialValue: keys[item] !== 'new' ? item : '' })(<Input />)}
                </Form.Item>
              </Col>
              <Col xs={22} sm={11}>
                <Form.Item className="d-flex" label="Value:">
                  {getFieldDecorator(`values.value${key}`, {
                    initialValue:
                      keys[item] !== 'new'
                        ? typeof keys[item] === 'object'
                          ? JSON.stringify(keys[item])
                          : keys[item]
                        : '',
                  })(<Input />)}
                </Form.Item>
              </Col>
              <Col span={2}>
                <Icon className="dynamic-delete-button" type="minus-circle-o" onClick={() => onFieldDelete(item)} />
              </Col>
            </Row>
          ))}
        <Form.Item>
          <Button type="dashed" onClick={onFieldAdd}>
            <Icon type="plus" /> Add field
          </Button>
        </Form.Item>
      </div>
    );
  },
);
