import React from 'react';
import { Select } from 'antd';
import { SelectProps } from 'antd/lib/select';

interface Props extends SelectProps<any> {
  call: () => any;
  refresh?: boolean;
  resultsFrom?: string;
  initCall?: boolean;
}

const { Option } = Select;

export const SelectWithCall = React.forwardRef<any, Props>(
  ({ call, refresh = false, onChange, value, defaultValue, resultsFrom, initCall, ...props }, ref) => {
    const [data, setData] = React.useState([]);
    const [loading, setLoading] = React.useState<boolean>(false);

    React.useEffect(() => {
      if (value && onChange) {
        onChange(value, []);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => {
      initCall && onFocusChange();
    }, [initCall]);

    const onFocusChange = async () => {
      setLoading(true);

      call()
        .then((res: any) => setData(resultsFrom ? res[resultsFrom] : res))
        .catch((e: any) => console.error(e))
        .finally(() => setLoading(false));
    };

    return (
      <Select
        showSearch
        onFocus={data.length === 0 || refresh ? onFocusChange : undefined}
        loading={loading}
        onChange={onChange}
        defaultValue={defaultValue}
        optionFilterProp="children"
        ref={ref}
        {...props}
      >
        {data.length > 0 &&
          data.map((item: { id: string; title: string; name: string }) => (
            <Option key={item.id} value={item.id}>
              {item.title ? item.title : item.name}
            </Option>
          ))}
      </Select>
    );
  },
);
