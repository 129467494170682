import { useEffect, useState } from 'react';
import { Alert, Button, Form, Icon, message } from 'antd';
import { FormComponentProps, ValidationRule } from 'antd/lib/form';
import { useHistory } from 'react-router-dom';
import { useIntl } from 'estafette-intl';

import { PasswordInput } from 'ui/atoms';
import { SessionLayout } from 'ui/organisms';
import { UsersApi } from 'lib/api';
import { getUnknownError, setFieldsError } from 'lib/utils';
import { SessionStorage } from 'lib';

import 'assets/sass/default.scss';
import { useStateHandlers } from 'hooks';

interface ChangeExpiredPasswordPageProps extends FormComponentProps {}

export const ChangeExpiredPasswordPage = Form.create<ChangeExpiredPasswordPageProps>()(
  ({ form }: ChangeExpiredPasswordPageProps) => {
    const { getFieldDecorator } = form;
    const [confirmDirty, setConfirmDirty] = useState(false);
    const { push, location } = useHistory();
    const { t } = useIntl();

    const [state, setState] = useStateHandlers({ loading: false, errors: '' });

    const { email } = (location.state as { email?: string }) || {};

    useEffect(() => {
      if (!email && !SessionStorage.getAccessToken()) {
        push('/login');
      }
    }, [email]);

    const loginExpiredPassword = !!email;

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      form.validateFields((err, values) => {
        if (!err) {
          setState({ loading: true, errors: undefined });

          const confirmPasswordApi = !loginExpiredPassword
            ? UsersApi?.changePassword({ ...values, email })
            : UsersApi?.changeKnownPassword({ ...values, email });

          confirmPasswordApi
            .then(data => {
              if (data.success) {
                localStorage.setItem('user-phone-number', data?.phone);
                localStorage.setItem('two-factor-token', data?.two_factor_token);

                push('/sms-verification');
              }
            })
            .catch(e => {
              if (e?.apiError?.error?.detail) {
                return setState({ errors: e?.apiError?.error?.detail });
              }
              if (e?.apiError && e?.apiError?.error) {
                message.error(getUnknownError(e?.apiError?.error) || 'Server error');
                return setFieldsError(e?.apiError?.error, form);
              }
            })
            .finally(() => {
              setState({ loading: false });
            });
        }
      });
    };

    const compareToFirstPassword: ValidationRule['validator'] = (_, value, callback) => {
      if (value && value !== form.getFieldValue('new_password')) {
        callback(t('passwordsDontMatch'));
      } else {
        callback();
      }
    };

    const validateToNextPassword: ValidationRule['validator'] = (_, value, callback) => {
      if (value && confirmDirty) {
        form.validateFields(['confirm_password'], { force: true });
      }
      callback();
    };

    const handleConfirmBlur: React.FocusEventHandler<HTMLInputElement> = e => {
      const value = e.target.value;
      setConfirmDirty(confirmDirty || !!value);
    };

    return (
      <SessionLayout>
        {state.errors && <div className="error-div">{state.errors}</div>}

        <Form.Item>
          <Alert
            message="Your password has expired. Please set a new one."
            type="error"
            showIcon
            icon={<Icon type="warning" />}
          />
        </Form.Item>
        <Form onSubmit={handleSubmit}>
          {loginExpiredPassword && (
            <Form.Item>
              {getFieldDecorator('password')(<PasswordInput placeholder="Old password" autoComplete="new-password" />)}
            </Form.Item>
          )}
          <Form.Item>
            {getFieldDecorator('new_password', {
              rules: [
                {
                  required: true,
                  message: 'Please input password!',
                },
                {
                  min: 8,
                  message: 'Please input at least 8 characters!',
                },
                {
                  validator: validateToNextPassword,
                },
              ],
            })(<PasswordInput placeholder="New password" autoComplete="new-password" />)}
          </Form.Item>
          <Form.Item>
            {getFieldDecorator('confirm_password', {
              rules: [
                {
                  required: true,
                  message: 'Please input confirm password!',
                },
                {
                  required: true,
                  validator: compareToFirstPassword,
                },
              ],
            })(<PasswordInput placeholder="Confirm new password" onBlur={handleConfirmBlur} />)}
          </Form.Item>

          <Form.Item className="text-align-center">
            <Button type="primary" htmlType="submit" loading={state.loading}>
              {t('save')}
            </Button>
          </Form.Item>
        </Form>
      </SessionLayout>
    );
  },
);
