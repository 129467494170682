import React, { createContext, ReactElement, ReactNode, useContext } from 'react';
import { RequestResponse, useRequest } from 'hooks/useRequest';
import { User, UserShort } from 'lib/api/users/users.types';
import { UsersApi } from 'lib/api';
import { UserGroups } from 'lib/entities/profile';
import { ProfileContext } from 'contexts/ProfileContext';
import { useStateHandlers } from 'hooks';

const defaultRequestResponse = {
  request: () => Promise.resolve(),
  data: { data: [] },
  setData: () => null,
  loading: false,
  errors: {},
  onSearch: () => null,
  onInclude: () => null,
};

export const UsersContext = createContext<{
  customerCares: RequestResponse<{ data: User[] }> & {
    onSearch: (value?: string) => void;
    onInclude: (value?: number[]) => void;
  };
  moderators: RequestResponse<{ data: UserShort[] }> & { onSearch: (value?: string) => void };
  councilors: RequestResponse<{ data: UserShort[] }> & { onSearch: (value?: string) => void };
}>({
  customerCares: defaultRequestResponse,
  moderators: defaultRequestResponse,
  councilors: defaultRequestResponse,
});

export const UsersProvider = ({ children }: { children: ReactNode }): ReactElement => {
  const {
    groups: { isAdministrator, isConsilier, isModerator, isCustomerCare, isWarehouseAdmin },
  } = useContext(ProfileContext);

  const allowRequest = [isAdministrator, isConsilier, isModerator, isCustomerCare, isWarehouseAdmin].some(
    value => value,
  );

  const customerCares = useRequest<{ data: User[] }>({ data: { data: [] } });

  const moderators = useRequest<{ data: UserShort[] }>({ data: { data: [] } });

  const councilors = useRequest<{ data: UserShort[] }>({ data: { data: [] } });

  const [state, setState] = useStateHandlers<{
    customerCareSearch?: string;
    customerCareInclude?: number[];
    moderatorsSearch?: string;
    councilorsSearch?: string;
  }>({});

  React.useEffect(() => {
    if (isAdministrator || isConsilier || isModerator || isCustomerCare) {
      customerCares.request(
        UsersApi.listAdminUsers({
          group: [UserGroups.administrator, UserGroups.moderator, UserGroups.consilier, UserGroups.customerCare],
          query: state.customerCareSearch,
          include: state.customerCareInclude,
        }),
      );
    }
  }, [
    isAdministrator,
    isConsilier,
    isModerator,
    isWarehouseAdmin,
    isCustomerCare,
    state.customerCareSearch,
    state.customerCareInclude,
  ]);

  React.useEffect(() => {
    if (!allowRequest) return;
    moderators.request(
      UsersApi.listAdminUsers({
        group: [UserGroups.moderator, UserGroups.consilier],
        query: state.moderatorsSearch,
      }),
    );
  }, [state.moderatorsSearch, allowRequest]);

  React.useEffect(() => {
    if (!allowRequest) return;
    councilors.request(
      UsersApi.listAdminUsers({
        group: [UserGroups.consilier],
        query: state.moderatorsSearch,
      }),
    );
  }, [state.moderatorsSearch, allowRequest]);

  const onCustomerCareSearch = (value: string = '') => {
    if (value.length !== 1 || (!value && state.customerCareSearch)) {
      setState({ customerCareSearch: value });
    }
  };

  const onCustomerCareInclude = (value?: number[]) => {
    if (value?.length || (!value && state.customerCareInclude)) {
      setState({ customerCareInclude: value });
    }
  };

  const onModeratorsSearch = (value: string = '') => {
    if (value.length !== 1 || (!value && state.moderatorsSearch)) {
      setState({ moderatorsSearch: value });
    }
  };

  const onCouncilorsSearch = (value: string = '') => {
    if (value.length !== 1 || (!value && state.councilorsSearch)) {
      setState({ councilorsSearch: value });
    }
  };

  return (
    <UsersContext.Provider
      value={{
        customerCares: {
          ...customerCares,
          onSearch: onCustomerCareSearch,
          onInclude: onCustomerCareInclude,
        },
        moderators: { ...moderators, onSearch: onModeratorsSearch },
        councilors: { ...councilors, onSearch: onCouncilorsSearch },
      }}
    >
      {children}
    </UsersContext.Provider>
  );
};
