import { useContext, useState } from 'react';
import { Button, DatePicker, Form, Input, Modal, message } from 'antd';
import { ButtonProps } from 'antd/lib/button';
import { FormComponentProps } from 'antd/lib/form';
import { useIntl } from 'estafette-intl';

import { BillingStatuses } from 'lib/entities/payments-management/orders';
import { resetErrorFromServer, setFieldsError } from 'lib/utils';
import { formItemLayout } from 'features/posts/constants';
import { OrdersApi } from 'lib/api';
import { recursiveEmptyWithNull, withValues } from 'lib/object';
import { ProfileContext } from 'contexts';

interface ScheduleArchiveOrderProps {
  id: number;
  billingId: number;
  status: BillingStatuses;
  isWidget?: boolean;
  onSubmitSuccess?: () => void;
}

type FormProps = FormComponentProps & ScheduleArchiveOrderProps;

export const ScheduleArchiveOrder = Form.create<FormProps>({
  onValuesChange({ form }, changedValues) {
    resetErrorFromServer(form, changedValues);
  },
})(({ id, billingId, status, isWidget, onSubmitSuccess, form }: FormProps) => {
  const { t } = useIntl();
  const [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const {
    groups: { isAdministrator, isCustomerCare },
  } = useContext(ProfileContext);

  const handleToggleModal = () => setIsOpen(open => !open);

  const { getFieldDecorator, validateFieldsAndScroll } = form;

  const btnProps: ButtonProps = !isWidget ? { className: 'change-btn' } : { size: 'small' };

  const handleSubmit = () => {
    validateFieldsAndScroll(async (err, formFields) => {
      if (err) return;

      recursiveEmptyWithNull(formFields);

      try {
        setLoading(true);
        await OrdersApi.scheduleArchive(id, { billing_id: billingId, ...withValues(formFields) });
        onSubmitSuccess?.();
        handleToggleModal();
      } catch (e) {
        const error = e as any;
        if (error.apiError?.error?.detail) {
          return message.error(error.apiError.error.detail);
        }

        if (error.apiError?.error && !error.apiError?.error?.detail) {
          return setFieldsError(error.apiError.error, form);
        }
        message.error(t('serverError'));
      } finally {
        setLoading(false);
      }
    });
  };

  const isAvailable = [BillingStatuses.suspended, BillingStatuses.deactivated].includes(status);
  const allowPermission = isAdministrator || isCustomerCare;

  if (!isAvailable || !allowPermission) return null;

  return (
    <>
      <Button onClick={handleToggleModal} {...btnProps}>
        {t('archiveOrder')}
      </Button>

      <Modal
        width={1000}
        title={t('plannedArchivation')}
        visible={isOpen}
        onOk={handleSubmit}
        okButtonProps={{ loading }}
        onCancel={handleToggleModal}
        destroyOnClose
      >
        <Form {...formItemLayout} onSubmit={handleSubmit}>
          <Form.Item label={t('date')}>{getFieldDecorator('schedule')(<DatePicker />)}</Form.Item>
          <Form.Item label={t('comments')}>
            {getFieldDecorator('comment', {
              rules: [{ required: true, message: t('pleaseInput', { item: t('comments').toLowerCase() }) }],
            })(<Input.TextArea placeholder={t('comments')} />)}
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
});
