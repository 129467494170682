import { Route } from 'lib/router/routes';
import { faq } from './faq';
import { pages } from './pages';
import { checkout } from './checkout';

export const contentAdministration: Route[] = [
  {
    path: '/q-and-a',
    text: 'contentAdministration',
    icon: 'global',
    children: [
      {
        path: '/faq',
        text: 'faq',
        icon: 'question-circle',
        children: faq,
        groups: [],
      },
      {
        path: '/pages',
        text: 'pages',
        icon: 'read',
        children: pages,
        groups: [],
      },
      {
        path: '/texts-setup',
        text: 'checkout',
        icon: 'line-chart',
        children: checkout,
        groups: ['moderator', 'content_creator', 'consilier', 'customer_care'],
      },
    ],
    groups: [],
  },
];
