import { useEffect, useMemo } from 'react';
import { Button, Form, Input, message, Select } from 'antd';
import { useIntl } from 'estafette-intl';
import { FormComponentProps } from 'antd/lib/form';
import TextArea from 'antd/es/input/TextArea';
import { ChaptersApi, ProgramsApi, Templates } from 'lib/api';
import { setFieldsError } from 'lib/utils';
import { languages, pageType } from 'lib/globalVars';
import { TemplateComment } from 'lib/entities/comments/templates';
import { useRequest, useStateHandlers } from 'hooks';
import { Program } from 'lib/api/education/programs/programs.types';
import { Chapter } from 'lib/api/education/chapters/chapters.types';
import { Episode } from 'lib/entities/education/episodes';
import { formItemLayout, tailFormItemLayout } from 'features/posts/constants';

export interface EditTemplateModalProps extends FormComponentProps {
  onSubmitSuccess?: () => void;
  item: TemplateComment;
}

const { Item } = Form;
const { Option } = Select;

const EditTemplateModal = ({ form, onSubmitSuccess, item }: EditTemplateModalProps) => {
  const { t } = useIntl();

  const { getFieldDecorator, getFieldValue } = form;
  const [state, setState] = useStateHandlers<{
    loading: boolean;
  }>({ loading: false });

  const programs = useRequest<{ results: Program[] }>({ data: { results: [] } });
  const chapters = useRequest<{ results: Chapter[] }>({ data: { results: [] } });
  const episodes = useRequest<{ results: Episode[] }>({ data: { results: [] } });

  const isTemplateEpisode = useMemo(() => item.template_type === 'education_episode', [item]);

  useEffect(() => {
    if (isTemplateEpisode) {
      programs.request(ProgramsApi.list());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTemplateEpisode]);

  useEffect(() => {
    if (isTemplateEpisode) {
      if (getFieldValue('program')) {
        chapters.request(ProgramsApi.chapters(getFieldValue('program')));
      }

      if (getFieldValue('chapter')) {
        episodes.request(ChaptersApi.episodes(getFieldValue('chapter')));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTemplateEpisode, getFieldValue('program'), getFieldValue('chapter')]);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    form.validateFieldsAndScroll((err, form) => {
      if (!err) {
        setState({ loading: false });

        Templates.update(item.id, form)
          .then(() => {
            message.success(t('successSaved', { item: t('template') }));
            onSubmitSuccess && onSubmitSuccess();
          })
          .catch(e => {
            if (e.apiError && e.apiError.error) {
              return setFieldsError(e.apiError.error, form);
            }
            message.error(t('serverError'));
          })
          .finally(() => setState({ loading: false }));
      }
    });
  };

  return (
    <Form {...formItemLayout} onSubmit={handleSubmit}>
      <Item label={t('templateType')}>
        {getFieldDecorator('template_type', {
          initialValue: item.template_type,
        })(
          <Select placeholder={t('pleaseSelectA', { item: t('templateType').toLowerCase() })}>
            {pageType.map(item => (
              <Option key={item} value={item}>
                {t(item)}
              </Option>
            ))}
          </Select>,
        )}
      </Item>
      {getFieldValue('template_type') === 'education_episode' && (
        <>
          <Item label={t('program')}>
            {getFieldDecorator('program', {
              initialValue: item.program && item.program.id,
            })(
              <Select placeholder={t('pleaseSelectA', { item: t('program').toLowerCase() })} loading={programs.loading}>
                {programs.data.results.map(i => (
                  <Option key={i.id} value={i.id}>
                    {i.title}
                  </Option>
                ))}
              </Select>,
            )}
          </Item>
          <Item label={t('chapter')}>
            {getFieldDecorator('chapter', {
              initialValue: item.chapter && item.chapter.id,
            })(
              <Select
                placeholder={t('pleaseSelectA', { item: t('chapter').toLowerCase() })}
                disabled={!getFieldValue('program')}
              >
                {chapters.data.results.map(i => (
                  <Option key={i.id} value={i.id}>
                    {i.title}
                  </Option>
                ))}
              </Select>,
            )}
          </Item>
          <Item label={t('episode')}>
            {getFieldDecorator('episode', { initialValue: item.episode && item.episode.id })(
              <Select
                placeholder={t('pleaseSelectA', { item: t('episode').toLowerCase() })}
                disabled={!getFieldValue('chapter')}
              >
                {episodes.data.results.map(i => (
                  <Option key={i.id} value={i.id}>
                    {i.title}
                  </Option>
                ))}
              </Select>,
            )}
          </Item>
        </>
      )}
      <Item label={t('language')}>
        {getFieldDecorator('language', { initialValue: item.language })(
          <Select placeholder={t('pleaseSelectA', { item: t('language').toLowerCase() })}>
            {languages.map(item => (
              <Option key={item} value={item}>
                {t(item)}
              </Option>
            ))}
          </Select>,
        )}
      </Item>
      <Item label={t('subject')}>
        {getFieldDecorator('subject', {
          rules: [
            {
              required: true,
              message: t('pleaseWriteItem', { item: t('subject').toLowerCase() }),
            },
          ],
          initialValue: item.subject,
        })(<Input />)}
      </Item>
      <Item label={t('content')}>
        {getFieldDecorator('text', {
          rules: [
            {
              required: true,
              message: t('pleaseWriteContent'),
            },
            {
              max: 5000,
              message: t('inputLess', { number: '5000' }),
            },
          ],
          initialValue: item.text,
        })(<TextArea />)}
      </Item>
      <Item {...tailFormItemLayout}>
        <Button className="ant-btn-primary" htmlType="submit" loading={state.loading}>
          {t('save')}
        </Button>
      </Item>
    </Form>
  );
};

export default Form.create<EditTemplateModalProps>()(EditTemplateModal);
