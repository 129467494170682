import { Col, Input, Radio, Row } from 'antd';
import { useIntl } from 'estafette-intl';
import { LanguageSwitch } from 'ui/molecules';
import { RadioChangeEvent } from 'antd/lib/radio';
import { Space } from 'ui/atoms';

const Search = Input.Search;

interface UsersHeaderProps {
  onSearch: (query: string) => void;
  onChange: (val: string) => void;
  onCategoryChange: (e: RadioChangeEvent) => void;
}

export const TemplatesHeader = ({ onSearch, onChange, onCategoryChange }: UsersHeaderProps) => {
  const { t } = useIntl();

  return (
    <div className="templates-header">
      <Row>
        <Col md={4}>
          <h3>{t('templates')}</h3>
        </Col>

        <Col md={20}>
          <Space justify="flex-end">
            <Radio.Group defaultValue="education_episode" onChange={onCategoryChange}>
              <Radio.Button value="education_episode"> {t('episodes')} </Radio.Button>
              <Radio.Button value="eod"> {t('eod')} </Radio.Button>
            </Radio.Group>

            <LanguageSwitch onChange={onChange} />

            <Search
              className="comments-search"
              style={{ margin: 0 }}
              placeholder={t('search')}
              onSearch={value => onSearch(value)}
            />
          </Space>
        </Col>
      </Row>
    </div>
  );
};
