import { useLayoutEffect, useState } from 'react';

export type BreakPointKey = 'xxl' | 'laptop' | 'tablet' | 'mobileLarge' | 'mobile';

export type BreakPoint = BreakPointKey | (string & {});

export const breakPointSize: Record<BreakPointKey, number> = {
  xxl: 1600,
  laptop: 1024,
  tablet: 768,
  mobileLarge: 575,
  mobile: 376,
};

export const isBreakPointKey = (key: BreakPoint): key is BreakPointKey => Object.keys(breakPointSize).includes(key);

export const useScreenBreakPoint = (breakPoint: BreakPoint = 'laptop') => {
  const [on, setOn] = useState(false);

  useLayoutEffect(() => {
    const media = window.matchMedia(
      `(max-width: ${isBreakPointKey(breakPoint) ? breakPointSize[breakPoint] + 'px' : breakPoint}`,
    );
    setOn(media.matches);
    let listener = (e: MediaQueryListEvent) => {
      setOn(e.matches);
    };
    media.addEventListener('change', listener);

    return () => {
      media.removeEventListener('change', listener);
    };
  }, [breakPoint]);

  return on;
};
