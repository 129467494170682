import { forwardRef, useEffect, useMemo } from 'react';
import { AutoComplete, Row, Select, Spin } from 'antd';
import { useIntl } from 'estafette-intl';
import { SelectProps } from 'antd/lib/select';

import { useRequest, useStateHandlers } from 'hooks';
import { InfiniteScroll } from 'ui/molecules';
import { pageSize } from 'lib/globalVars';
import { $Object } from 'lib/object';
import { WithPagination } from 'lib/entities';

interface UTMSourceRequest {
  results: { [key: string]: string }[];
  count: number;
}

interface StateType {
  page: number;
  query?: string;
}

const { Option } = Select;

export interface UTMFieldSelectProps extends SelectProps<string | string[]> {
  query?: string;
  params?: object;
  isAutoComplete?: boolean;

  fieldName: string;
  apiCall: (params: $Object) => Promise<WithPagination<Record<string, string>[]>>;

  itemName: string;
}

export const UTMFieldSelect = forwardRef<Select<string | string[]>, UTMFieldSelectProps>(
  ({ query, params = {}, isAutoComplete, fieldName, apiCall, itemName, ...props }, ref) => {
    const { t } = useIntl();
    const utmRequest = useRequest<UTMSourceRequest>({ data: {} });
    const [state, setState] = useStateHandlers<StateType>({ page: 1, query });

    useEffect(() => {
      utmRequest.request(
        apiCall({
          page_size: pageSize,
          page: state.page,
          [fieldName]: state.query,
          ...params,
        }),
        state.page > 1 ? { concat: 'results' } : {},
      );
    }, [state.page, state.query]);

    useEffect(() => {
      setState({ query });
    }, [query]);

    const hasMore = useMemo(() => !utmRequest.loading && Math.ceil(utmRequest.data.count / pageSize) > state.page, [
      utmRequest.loading,
      utmRequest.data,
      state.page,
    ]);

    const onPageChange = () => {
      if (hasMore) {
        setState(prevState => ({ page: ++prevState.page }));
      }
    };

    const onSearch = (query?: string) => {
      setState({ page: 1, query });
    };

    const Component = isAutoComplete ? AutoComplete : Select;

    return (
      <Component
        ref={ref}
        loading={utmRequest.loading}
        placeholder={t('pleaseSelectA', { item: itemName?.toLowerCase() })}
        showSearch
        allowClear
        filterOption={() => true}
        onSearch={onSearch}
        style={{ width: '100%' }}
        onDropdownVisibleChange={() => onSearch()}
        dropdownRender={menu => (
          <InfiniteScroll onLoadMore={onPageChange} isDirectChild hasMore={true}>
            {menu}
            {utmRequest.loading && (
              <Row type="flex" justify="center">
                <Spin style={{ height: 30 }} />
              </Row>
            )}
          </InfiniteScroll>
        )}
        {...props}
      >
        {utmRequest.data.results?.map(({ [fieldName]: value }) => (
          <Option key={value} value={value}>
            {value}
          </Option>
        ))}
      </Component>
    );
  },
);
