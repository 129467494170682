import ReactDOM from 'react-dom';
import { CreateIntl } from 'estafette-intl';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { messages } from 'lib/localizations/messages';
import { UsersProvider } from 'contexts/UsersContext';
import { ProfileProvider } from 'contexts/ProfileContext';
import { SettingsProvider } from 'contexts/SettingsContext';

import 'antd/dist/antd.css';
import 'assets/sass/default.scss';

ReactDOM.render(
  <BrowserRouter>
    <ProfileProvider>
      <UsersProvider>
        <SettingsProvider>
          <CreateIntl defaultLocale="en" messages={messages}>
            <App />
          </CreateIntl>
        </SettingsProvider>
      </UsersProvider>
    </ProfileProvider>
  </BrowserRouter>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
