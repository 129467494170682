import { useState } from 'react';
import { Input, Icon } from 'antd';
import { InputProps } from 'antd/lib/input/Input';

export const PasswordInput = ({ type, ...props }: InputProps) => {
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const onShowPasswordChange = () => setShowPassword(prevState => !prevState);

  return (
    <Input
      {...props}
      type={showPassword ? 'text' : 'password'}
      suffix={<Icon type={`eye${showPassword ? '-invisible' : ''}`} onClick={onShowPasswordChange} />}
    />
  );
};
