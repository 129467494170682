import React, { useRef } from 'react';
import { useHistory, useLocation } from 'react-router';

export const useHash = (hash: string) => {
  const history = useHistory();
  const { hash: oldHash } = useLocation<string>();
  const putHash = useRef(() => {
    if (oldHash.replace('#', '') !== hash) {
      history.push({ hash });
    }
  });

  React.useEffect(() => {
    putHash.current();
  }, []);
};
