import React from 'react';
import { Card } from 'antd';

import { BreakPoint, useScreenBreakPoint } from 'hooks';
import { Space } from '..';
import './Totals.scss';

export interface TotalsInfo {
  title: string;
  value: number | string[];
  legend?: React.ReactNode;
}

interface TotalsInfoProps {
  info: Array<TotalsInfo>;
  className?: string;
  breakpoint?: BreakPoint;
}

export const Totals: React.FC<TotalsInfoProps> = ({ info, className = '', breakpoint = 'tablet' }) => {
  const isTablet = useScreenBreakPoint(breakpoint);

  return (
    <Card className={className}>
      <Space align="strech" direction={isTablet ? 'column' : 'row'}>
        {info.map((item, idx) => (
          <InfoTotals key={idx} bordered={!isTablet && info.length > idx + 1} {...item} />
        ))}
      </Space>
    </Card>
  );
};

export interface InfoTotalsProps extends TotalsInfo {
  bordered: boolean;
}

export const InfoTotals: React.FC<InfoTotalsProps> = ({ title, value, legend, bordered }) => (
  <div className="headerInfo">
    <span>{title}</span>

    {legend && <div className="headerInfo-legend">{legend}</div>}

    <PutInfoValue value={value} />

    {bordered && <em />}
  </div>
);

const PutInfoValue = ({ value }: { value?: number | string[] }) => {
  if (typeof value === 'number') {
    return <p>{value}</p>;
  }

  if (value?.length) {
    return (
      <>
        {value.map((i, k) => (
          <p key={`${k}-info`}>{i}</p>
        ))}
      </>
    );
  }

  return <></>;
};
