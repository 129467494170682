import React, { useContext, useState } from 'react';
import { Avatar, Dropdown, Icon, Layout, Menu, Modal, Spin } from 'antd';
import moment from 'moment';
import { useIntl } from 'estafette-intl';
import { Profile } from 'lib/entities/profile';
import { Request } from 'lib';
import ResetPasswordModal from '../ResetPasswordForm/ResetPasswordForm';

import { ProfileContext } from 'contexts';
import 'assets/sass/header.scss';
import { PasswordExpiredData } from './PasswordExpiredData/PasswordExpiredData';

const { Header } = Layout;

export interface HeaderLayoutProps {
  collapsed: boolean;
  profile: Profile | null;

  setCollapsed(value: boolean): void;
}

/**
 * Render main dashboard header
 */
const HeaderLayout: React.FC<HeaderLayoutProps> = ({ collapsed, setCollapsed, profile }) => {
  const { t } = useIntl();

  const { admin } = useContext(ProfileContext);

  const days = Math.ceil(moment.duration(moment(admin.data?.password_expires_at).diff(moment())).asDays());

  const [showModal, setShowModal] = useState<boolean>(false);
  const onLogout = () => {
    Request.loggedOutCb();
  };

  const menu = (
    <Menu>
      <Menu.Item key="reset-password" onClick={() => setShowModal(true)}>
        <Icon type="lock" />
        change password
      </Menu.Item>
      <Menu.Item key="logout" onClick={() => onLogout()}>
        <Icon type="logout" />
        logout
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <Header className="header">
        <Icon
          className="trigger"
          type={collapsed ? 'menu-unfold' : 'menu-fold'}
          onClick={() => setCollapsed(!collapsed)}
        />
        <span>
          {admin.data?.password_expires_at && <PasswordExpiredData days={days} />}

          <Dropdown overlay={menu} trigger={['click']}>
            <span className="user">
              {profile && Object.keys(profile).length > 0 ? (
                <ProfileAvatar profile={profile} />
              ) : (
                <Spin size="small" className="spin" />
              )}
            </span>
          </Dropdown>
        </span>
      </Header>
      <Modal title={t('changePassword')} visible={showModal} footer={null} onCancel={() => setShowModal(false)}>
        <ResetPasswordModal setShowModal={() => setShowModal(false)} />
      </Modal>
    </>
  );
};

export default HeaderLayout;

const ProfileAvatar = ({ profile }: { profile: Profile }) => (
  <>
    <Avatar className="user_avatar" src={profile.photo} alt="avatar" />
    <span>{profile.full_name}</span>
  </>
);
