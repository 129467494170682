import React from 'react';
import { LoadingComponentProps } from 'react-loadable';
import { Alert, Spin } from 'antd';

import 'assets/sass/loading-placeholder.scss';

export const LoadingPlaceholder = (props: LoadingComponentProps) => {
  if (props.error) {
    return <Alert message={props.error || 'Error!'} />;
  } else if (props.pastDelay) {
    return (
      <div className="main-spinner">
        <Spin />
      </div>
    );
  }

  return null;
};
