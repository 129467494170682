import { Request } from 'lib/index';
import { pBotURL } from '../../request';

export const pBotApi = {
  list: (data = {}) => {
    return Request.get(`${pBotURL}/settings`, data);
  },
  create: (data: object) => {
    return Request.post(`${pBotURL}/settings`, data);
  },
  byId: (id: string) => {
    return Request.get(`${pBotURL}/settings/${id}`);
  },
  update: (id: string, data: object) => {
    return Request.patch(`${pBotURL}/settings/${id}`, { ...data });
  },
  delete: (id: string) => {
    return Request.delete(`${pBotURL}/settings/${id}`);
  },
  export: (data = {}) => {
    return Request.get(`${pBotURL}/messages/export`, data);
  },
  statistics: (data = {}) => {
    return Request.get(`${pBotURL}/messages/statistics`, data);
  },
};
