import { forwardRef, useEffect, useMemo, useRef } from 'react';
import { Row, Select, Spin } from 'antd';
import { useIntl } from 'estafette-intl';
import { SelectProps } from 'antd/lib/select';

import { useRequest, useStateHandlers } from 'hooks';
import { InfiniteScroll } from 'ui/molecules';
import { OffersApi } from 'lib/api';
import { pageSize } from 'lib/globalVars';
import { Languages, WithPagination } from 'lib/entities';
import { Offer } from 'lib/api/payments/offers/offers.types';
import { $Object } from 'lib/object';

interface StateType {
  page: number;
  query?: string;
  offers: number | number[] | null;
}

const { Option } = Select;

interface OfferListProps extends SelectProps<number | number[] | null> {
  query?: string;
  parentsNodeClassName?: string;
  language?: Languages;
  isActive?: boolean;
  excludeOffers?: number[];
  enabledCallApi?: boolean;
  params?: $Object;
  renderOptions?: (value: Offer) => React.ReactNode;
  offersList?: (value: Offer[]) => Offer[];
}

export const OfferList = forwardRef<Select<number | number[] | null>, OfferListProps>(
  (
    {
      query,
      parentsNodeClassName,
      language,
      isActive = true,
      excludeOffers = [],
      enabledCallApi = true,
      params,
      renderOptions,
      offersList,
      ...props
    },
    ref,
  ) => {
    const { t } = useIntl();
    const offers = useRequest<WithPagination<Offer[]>>({ data: {} });
    const [state, setState] = useStateHandlers<StateType>({ page: 1, offers: [], query });
    const abortController = useRef(new AbortController());

    useEffect(() => {
      enabledCallApi &&
        offers.request(
          (() => {
            abortController.current.abort();
            abortController.current = new AbortController();
            return OffersApi.list(
              {
                page_size: pageSize,
                page: state.page,
                search: state.query,
                language,
                is_active: isActive,
                ...params,
              },
              { signal: abortController.current.signal },
            );
          })(),
          state.page > 1 ? { concat: 'results' } : {},
        );
    }, [state.page, state.query, language, isActive, enabledCallApi, params]);

    useEffect(() => {
      setState({ query });
    }, [query]);

    const hasMore = useMemo(() => !offers.loading && Math.ceil(offers.data.count / pageSize) > state.page, [
      offers.loading,
      offers.data,
      state.page,
    ]);

    const onPageChange = () => {
      if (hasMore) {
        setState(prevState => ({ page: ++prevState.page }));
      }
    };

    const onSearch = (query?: string) => {
      setState({ page: 1, query });
    };

    const onChange = (offers: number | number[] | null) => {
      setState({ offers });
    };

    const filteredData = useMemo(
      () =>
        offersList
          ? offersList(offers.data.results)
          : offers.data.results?.filter(({ id }) => !excludeOffers.includes(id)),
      [offers.data.results, excludeOffers],
    );

    return (
      <Select
        ref={ref}
        loading={offers.loading}
        placeholder={t('pleaseSelectAn', { item: t('offer').toLowerCase() })}
        showSearch
        allowClear
        filterOption={() => true}
        onSearch={onSearch}
        onChange={onChange}
        style={{ width: '100%' }}
        onDropdownVisibleChange={() => onSearch()}
        dropdownClassName={parentsNodeClassName}
        dropdownRender={menu => (
          <InfiniteScroll
            onLoadMore={onPageChange}
            parentsNodeClassName={parentsNodeClassName}
            nodeClassName="ant-select-dropdown-menu"
            hasMore={true}
          >
            {menu}
            {offers.loading && (
              <Row type="flex" justify="center">
                <Spin style={{ height: 30 }} />
              </Row>
            )}
          </InfiniteScroll>
        )}
        {...props}
      >
        {query && !filteredData?.find(({ id }) => id === Number(props.value)) && (
          <Option value={Number(props.value)} key={Number(props.value)}>
            {query}
          </Option>
        )}
        {filteredData?.map(({ id, name, ...rest }) =>
          !renderOptions ? (
            <Option key={id} value={id}>
              {name}
            </Option>
          ) : (
            renderOptions({ id, name, ...rest })
          ),
        )}
      </Select>
    );
  },
);
