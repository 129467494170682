import { Request } from '../../index';
import { EpisodeReply } from './episodeReplies.types';

export const EpisodeReplies = {
  list: (data: object) => Request.get('/education/dashboard/episode-replies/', data),
  update: (id: number, data: Partial<EpisodeReply>) =>
    Request.patch(`/education/dashboard/episode-replies/${id}/`, data),
  publish: (id: number) => Request.post(`/education/dashboard/episode-replies/${id}/publish/`),
  sentForReview: (id: number, data: { reply_review_responsible: number }) =>
    Request.post(`/education/dashboard/episode-replies/${id}/send-for-review/`, data),
  review: (id: number, data: object) => Request.post(`/education/dashboard/episode-replies/${id}/review/`, data),
  getAverageResponseTime: (data = {}) => Request.get('/education/dashboard/episode-replies/average-response-time/', data),
};
