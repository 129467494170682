import { useState } from 'react';
import { Form, Modal, message } from 'antd';
import { ModalProps } from 'antd/lib/modal';
import { useIntl } from 'estafette-intl';
import { FormComponentProps } from 'antd/lib/form';

import { resetErrorFromServer, setFieldsError } from 'lib/utils';
import { ResponsibleMembers, StatusesSelect } from 'ui/molecules';
import { TasksApi } from 'lib/api';
import { ModalState } from './TaskListTable';

interface TaskActionModalProps extends ModalProps {
  state: ModalState;
  onSuccess?: () => void;
}

type FormProps = FormComponentProps & TaskActionModalProps;

export const TaskActionModal = Form.create<FormProps>({
  onValuesChange({ form }, changedValues) {
    resetErrorFromServer(form, changedValues);
  },
})(({ state, form, onSuccess, ...props }: FormProps) => {
  const { t } = useIntl();
  const [loading, setLoading] = useState(false);

  if (!state.modalType) return null;

  const modalProp: Record<
    NonNullable<ModalState['modalType']>,
    { title: string; mutation: (id: number, data: object) => Promise<void> }
  > = {
    assignee: {
      title: t('changeItem', { item: t('responsible').toLowerCase() }),
      mutation: TasksApi.assignResponsible,
    },
    status: { title: t('changeItem', { item: t('status').toLowerCase() }), mutation: TasksApi.changeStatus },
  };

  const { getFieldDecorator, validateFieldsAndScroll } = form;

  const handleSubmit = () => {
    validateFieldsAndScroll(async (err, formFields) => {
      if (err || !state.modalType || !state.task?.id) return;

      try {
        setLoading(true);
        await modalProp[state.modalType].mutation(state.task?.id, formFields);
        onSuccess?.();
      } catch (e) {
        const error = e as any;
        if (error.apiError?.error?.detail) {
          return message.error(error.apiError.error.detail);
        }

        if (error.apiError?.error && !error.apiError?.error?.detail) {
          return setFieldsError(error.apiError.error, form);
        }
        message.error(t('serverError'));
      } finally {
        setLoading(false);
      }
    });
  };

  return (
    <Modal
      title={modalProp[state.modalType].title}
      visible
      onOk={handleSubmit}
      okButtonProps={{ loading }}
      bodyStyle={{ paddingTop: 0, paddingBottom: 0 }}
      {...props}
    >
      <Form onSubmit={handleSubmit}>
        {state.modalType === 'status' && (
          <Form.Item label={t('status')}>
            {getFieldDecorator('status', { initialValue: state.task?.status?.id })(
              <StatusesSelect
                allowClear={false}
                types={state.task?.type?.id}
                additionalStatus={state.task?.status}
                onlyAllowedTypes
                projects={state.task?.language}
              />,
            )}
          </Form.Item>
        )}

        {state.modalType === 'assignee' && (
          <Form.Item label={t('member')}>
            {getFieldDecorator('responsible', { initialValue: state.task?.responsible?.id || null })(
              <ResponsibleMembers
                allowClear={false}
                query={state.task?.responsible?.full_name}
                groupId={state.task?.group?.id}
              />,
            )}
          </Form.Item>
        )}
      </Form>
    </Modal>
  );
});
