import React, { useState } from 'react';
import { Switch as AntSwitch } from 'antd';
import { SwitchProps as $SwitchProps } from 'antd/lib/switch';

import './Switch.scss';

interface SwitchProps extends $SwitchProps {
  value?: boolean;
}

export const Switch = React.forwardRef<any, SwitchProps>(({ value = false, ...props }, ref) => {
  const [checked, setChecked] = useState<boolean>();

  const onSwitchChange = (newValue: boolean) => {
    setChecked(newValue);
  };

  return (
    <AntSwitch
      ref={ref}
      className="dm-switch"
      checked={checked ? checked : value}
      onClick={onSwitchChange}
      {...props}
    />
  );
});
