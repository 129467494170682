import { Request } from '../../../index';

export const ChaptersApi = {
  list: (data: object) => {
    return Request.get('/education/dashboard/chapters/', data);
  },
  create: (data: object) => {
    return Request.post('/education/dashboard/chapters/', data);
  },
  byId: (id: string, data: object) => {
    return Request.get(`/education/dashboard/chapters/${id}/`, { ...data });
  },
  update: (id: string, data: object) => {
    return Request.patch(`/education/dashboard/chapters/${id}/`, { ...data });
  },
  delete: (id: string) => {
    return Request.delete(`/education/dashboard/chapters/${id}/`);
  },
  episodes: (id: string, data = {}) => {
    return Request.get(`/education/dashboard/chapters/${id}/episodes/`, { ...data });
  },
};
