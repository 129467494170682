import { useState } from 'react';
import { useIntl } from 'estafette-intl';
import { Button, Form, Input, message, Select } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { formItemLayout, tailFormItemLayout } from 'features/posts/constants';
import { FormComponentProps } from 'antd/lib/form';
import { ChaptersApi, ProgramsApi, Templates } from 'lib/api';
import { setFieldsError } from 'lib/utils';
import { SelectWithCall } from 'ui/atoms';
import { languages, pageType } from 'lib/globalVars';

export interface AddTemplateModalProps extends FormComponentProps {
  onSubmitSuccess?: () => void;
  options?: {
    text: string;
    template_type: string;
    language: string;
  };
}

const { Item } = Form;
const { Option } = Select;

const AddTemplateModal = ({ form, onSubmitSuccess, options }: AddTemplateModalProps) => {
  const { t } = useIntl();

  const { getFieldDecorator, getFieldValue, resetFields } = form;
  const [loading, setLoading] = useState<boolean>(false);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    form.validateFieldsAndScroll((err, form) => {
      if (!err) {
        setLoading(true);

        if (options) {
          form = { ...form, ...options };
        }

        Templates.create(form)
          .then(() => {
            message.success(t('successSaved', { item: t('template') }));
            onSubmitSuccess && onSubmitSuccess();
            resetFields();

            setLoading(false);
          })
          .catch(e => {
            if (e.apiError && e.apiError.error) {
              return setFieldsError(e.apiError.error, form);
            }
            message.error(t('serverError'));

            setLoading(false);
          });
      }
    });
  };

  const getPrograms = () => ProgramsApi.list({ page_size: 50 });
  const getChapters = () => ProgramsApi.chapters(getFieldValue('program'), { page_size: 50 });
  const getEpisodes = () => ChaptersApi.episodes(getFieldValue('chapter'), { page_size: 50 });

  return (
    <Form {...formItemLayout} onSubmit={handleSubmit}>
      {!options && (
        <Item label={t('templateType')}>
          {getFieldDecorator('template_type', {
            rules: [
              {
                required: true,
                message: t('pleaseSelectA', { item: t('templateType').toLowerCase() }),
              },
            ],
          })(
            <Select placeholder={t('pleaseSelectA', { item: t('templateType').toLowerCase() })}>
              {pageType.map(item => (
                <Option key={item} value={item}>
                  {t(item)}
                </Option>
              ))}
            </Select>,
          )}
        </Item>
      )}
      {getFieldValue('template_type') === 'education_episode' && !options && (
        <>
          <Item label={t('program')}>
            {getFieldDecorator('program')(
              <SelectWithCall
                call={getPrograms}
                resultsFrom="results"
                refresh={false}
                placeholder={t('pleaseSelectA', { item: t('program').toLowerCase() })}
              />,
            )}
          </Item>
          <Item label={t('chapter')}>
            {getFieldDecorator('chapter')(
              <SelectWithCall
                call={getChapters}
                refresh={true}
                placeholder={t('pleaseSelectA', { item: t('chapter').toLowerCase() })}
                disabled={!getFieldValue('program')}
                resultsFrom="results"
              />,
            )}
          </Item>
          <Item label={t('episode')}>
            {getFieldDecorator('episode')(
              <SelectWithCall
                call={getEpisodes}
                refresh={true}
                placeholder={t('pleaseSelectA', { item: t('episode').toLowerCase() })}
                disabled={!getFieldValue('chapter')}
                resultsFrom="results"
              />,
            )}
          </Item>
        </>
      )}
      {!options && (
        <Item label={t('language')}>
          {getFieldDecorator('language', {
            rules: [
              {
                required: true,
                message: t('pleaseSelectA', { item: t('language').toLowerCase() }),
              },
            ],
          })(
            <Select placeholder={t('pleaseSelectA', { item: t('language').toLowerCase() })}>
              {languages.map(item => (
                <Option key={item} value={item}>
                  {t(item)}
                </Option>
              ))}
            </Select>,
          )}
        </Item>
      )}
      <Item label={t('subject')}>
        {getFieldDecorator('subject', {
          rules: [
            {
              required: true,
              message: t('pleaseWriteItem', { item: t('subject').toLowerCase() }),
            },
          ],
        })(<Input />)}
      </Item>
      {!options && (
        <Item label={t('content')}>
          {getFieldDecorator('text', {
            rules: [
              {
                required: true,
                message: t('pleaseWriteContent'),
              },
              {
                max: 5000,
                message: t('inputLess', { number: '5000' }),
              },
            ],
          })(<TextArea />)}
        </Item>
      )}
      <Item {...tailFormItemLayout}>
        <Button className="ant-btn-primary" htmlType="submit" loading={loading}>
          {t('save')}
        </Button>
      </Item>
    </Form>
  );
};

export default Form.create<AddTemplateModalProps>()(AddTemplateModal);
