import { Request } from '../index';

export const PostsApi = {
  list: (data: object) => {
    return Request.get('/posts/dashboard/list', { ...data });
  },
  byId: (id: string) => {
    return Request.get(`/posts/dashboard/posts/${id}/`);
  },
  create: (data: object) => {
    return Request.post('/posts/add', { ...data });
  },
  update: (id: string, data: object) => {
    return Request.post(`/posts/update/${id}`, { ...data });
  },
  delete: (id: string, post: boolean) => {
    if (post) {
      return Request.delete(`/posts/action/delete/${id}`);
    } else {
      return Request.post(`/posts/action/hide/${id}`);
    }
  },
  going: (id: string, page: number) => {
    return Request.get(`/posts/dashboard/interested`, { post_id: id, page: page });
  },
  comments: (id: string, page: number) => {
    return Request.get(`/posts/list/comments`, { parent: id, page: page });
  },
  journal: (id: string, page: number) => {
    return Request.get(`/posts/list/journal/photos`, { photos_stack: id, page: page });
  },
};
