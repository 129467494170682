import { Route } from 'lib/router/routes';
import { pBot } from './pBot';

export const experimentalFeatures: Route[] = [
  {
    path: '/experimental',
    text: 'experimentalFeatures',
    icon: 'experiment',
    children: [
      {
        path: '/p-bot',
        text: 'pBot',
        icon: 'robot',
        children: pBot,
      },
    ],
    groups: ['customer_care', 'moderator', 'content_creator', 'consilier', 'warehouse_admin',"sales_specialist"],
  },
];
