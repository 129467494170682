import Loadable from 'react-loadable';
import { LoadingPlaceholder } from 'ui/atoms';

import { Route } from 'lib/router/routes';
import { reports } from './reports';

export const sales: Route[] = [
  {
    path: 'orders',
    text: 'sales',
    icon: 'pie-chart',
    children: [
      {
        path: '/orders',
        text: 'orders',
        icon: 'transaction',
        component: Loadable({
          loader: () => import('../../../../features/payments-management/pages/OrdersPage'),
          loading: LoadingPlaceholder,
          delay: 300,
        }),
        children: [],
        groups: ['moderator', 'content_creator', 'consilier', 'sales_specialist'],
      },
      {
        path: '/orders/:id/details',
        text: '',
        component: Loadable({
          loader: () => import('../../../../features/payments-management/pages/DetailsPage'),
          loading: LoadingPlaceholder,
          delay: 300,
        }),
        children: [],
        groups: ['moderator', 'content_creator', 'consilier', 'sales_specialist'],
      },
      {
        path: '/invoices',
        text: 'invoices',
        icon: 'file-text',
        component: Loadable({
          loader: () => import('../../../../features/payments-management/pages/InvoicesPage'),
          loading: LoadingPlaceholder,
          delay: 300,
        }),
        children: [],
        groups: ['moderator', 'content_creator', 'consilier', 'sales_specialist'],
      },
      {
        path: '/shipping',
        text: 'shipments',
        icon: 'gold',
        component: Loadable({
          loader: () => import('../../../../features/warehouse/pages/ShippingPage'),
          loading: LoadingPlaceholder,
          delay: 300,
        }),
        children: [],
        groups: ['sales_specialist', 'moderator', 'consilier', 'content_creator'],
      },
      {
        path: '/merchants',
        text: 'merchantsAccounts',
        icon: 'control',
        component: Loadable({
          loader: () => import('../../../../features/payments-management/pages/MerchantsPage'),
          loading: LoadingPlaceholder,
          delay: 300,
        }),
        children: [],
        groups: ['customer_care', 'moderator', 'content_creator', 'consilier', 'sales_specialist', 'warehouse_admin'],
      },
      {
        path: '/merchants/add',
        text: null,
        component: Loadable({
          loader: () => import('../../../../features/payments-management/pages/AddMerchantPage'),
          loading: LoadingPlaceholder,
          delay: 300,
        }),
        children: [],
        groups: ['customer_care', 'moderator', 'content_creator', 'consilier', 'sales_specialist', 'warehouse_admin'],
      },
      {
        path: '/merchants/:id/edit',
        text: null,
        component: Loadable({
          loader: () => import('../../../../features/payments-management/pages/EditMerchantPage'),
          loading: LoadingPlaceholder,
          delay: 300,
        }),
        children: [],
        groups: ['customer_care', 'moderator', 'content_creator', 'consilier', 'sales_specialist', 'warehouse_admin'],
      },
      {
        path: '/provider-country/add',
        text: null,
        component: Loadable({
          loader: () => import('../../../../features/payments-management/pages/AddProviderCountryPage'),
          loading: LoadingPlaceholder,
          delay: 300,
        }),
        children: [],
        groups: ['customer_care', 'moderator', 'content_creator', 'consilier', 'sales_specialist'],
      },
      {
        path: '/provider-country/:id/edit',
        text: null,
        component: Loadable({
          loader: () => import('../../../../features/payments-management/pages/EditProviderCountryPage'),
          loading: LoadingPlaceholder,
          delay: 300,
        }),
        children: [],
        groups: ['customer_care', 'moderator', 'content_creator', 'consilier', 'sales_specialist'],
      },
      {
        path: '/sales-overview',
        text: 'reports',
        icon: 'rise',
        children: reports,
        groups: ['sales_specialist'],
      },
    ],
    groups: ['sales_specialist'],
  },
];
