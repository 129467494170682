import Loadable from 'react-loadable';
import { LoadingPlaceholder } from 'ui/atoms';

import { Route } from 'lib/router/routes';

export const comments: Route[] = [
  {
    path: '/comments',
    text: 'comments',
    icon: 'message',
    children: [
      {
        path: '/episode-of-the-day',
        text: 'postsOfTheDay',
        icon: 'play-circle',
        component: Loadable({
          loader: () => import('../../../../features/comments/pages/EpisodesPage'),
          loading: LoadingPlaceholder,
          delay: 300,
        }),
        children: [],
        groups: ['content_creator', 'sales_specialist', 'warehouse_admin'],
      },
      {
        path: '/parenting-qa',
        text: 'parentingQA',
        icon: 'schedule',
        component: Loadable({
          loader: () => import('../../../../features/comments/pages/ParentingQA'),
          loading: LoadingPlaceholder,
          delay: 300,
        }),
        children: [],
        groups: ['content_creator', 'sales_specialist', 'warehouse_admin'],
      },
      {
        path: '/parenting-comments',
        text: 'parentingComments',
        icon: 'schedule',
        component: Loadable({
          loader: () => import('../../../../features/comments/pages/ParentingComments'),
          loading: LoadingPlaceholder,
          delay: 300,
        }),
        children: [],
        groups: ['content_creator', 'sales_specialist', 'warehouse_admin'],
      },
      {
        path: '/waiting-for-review',
        text: 'waitingForReview',
        icon: 'file-search',
        component: Loadable({
          loader: () => import('../../../../features/comments/pages/WaitingForReview'),
          loading: LoadingPlaceholder,
          delay: 300,
        }),
        children: [],
        groups: ['content_creator', 'moderator', 'sales_specialist', 'warehouse_admin'],
      },
      {
        path: '/templates',
        text: 'templates',
        icon: 'tags',
        component: Loadable({
          loader: () => import('../../../../features/comments/pages/TemplatesPage'),
          loading: LoadingPlaceholder,
          delay: 300,
        }),
        children: [],
        groups: ['content_creator', 'sales_specialist', 'warehouse_admin'],
      },
      {
        path: '/archive',
        text: 'archive',
        icon: 'cloud',
        component: Loadable({
          loader: () => import('../../../../features/comments/pages/ArchivePage'),
          loading: LoadingPlaceholder,
          delay: 300,
        }),
        children: [],
        groups: ['content_creator', 'sales_specialist', 'warehouse_admin'],
      },
    ],
    groups: ['content_creator', 'sales_specialist', 'warehouse_admin'],
  },
];
