export const formItemLayout = {
  labelCol: {
    sm: { span: 24 },
    md: { span: 8 },
  },
  wrapperCol: {
    sm: { span: 24 },
    md: { span: 8 },
  },
};

export const tailFormItemLayout = {
  wrapperCol: {
    sm: {
      span: 24,
      offset: 0,
    },
    md: {
      span: 16,
      offset: 8,
    },
  },
};

export const types = ['text', 'question', 'video', 'photo', 'photo_stack'];
