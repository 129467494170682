import Loadable from 'react-loadable';
import { LoadingPlaceholder } from 'ui/atoms';

import { Route } from 'lib/router/routes';

export const exports: Route[] = [
  {
    path: '/export',
    text: 'export',
    icon: 'cloud-download',
    children: [
      {
        path: '/exports',
        text: 'winMentor',
        icon: 'file-text',
        component: Loadable({
          loader: () => import('../../../../features/exports/pages/ExportsPage'),
          loading: LoadingPlaceholder,
          delay: 300,
        }),
        children: [],
        groups: ['moderator', 'content_creator', 'customer_care', 'consilier', 'sales_specialist', 'warehouse_admin'],
      },
      {
        path: '/disbursements',
        text: 'disbursements',
        icon: 'percentage',
        component: Loadable({
          loader: () => import('../../../../features/exports/pages/DisbursementsPage/DisbursementsPage'),
          loading: LoadingPlaceholder,
          delay: 300,
        }),
        children: [],
        groups: ['moderator', 'content_creator', 'customer_care', 'consilier', 'sales_specialist', 'warehouse_admin'],
      },
      {
        path: '/disbursements/:id/items',
        text: null,
        icon: '',
        component: Loadable({
          loader: () => import('../../../../features/exports/pages/DisbursementItemsPage'),
          loading: LoadingPlaceholder,
          delay: 300,
        }),
        children: [],
        groups: ['moderator', 'content_creator', 'customer_care', 'consilier', 'sales_specialist', 'warehouse_admin'],
      },
    ],
    groups: ['moderator', 'content_creator', 'customer_care', 'consilier', 'sales_specialist', 'warehouse_admin'],
  },
];
