import { Request } from '../index';

export const NotificationsApi = {
  list: () => {
    return Request.get('/notifications/dashboard/scheduled/');
  },
  create: (data: object) => {
    return Request.post('/notifications/dashboard/scheduled/', { ...data });
  },
  push: () => {
    return Request.post('/notifications/dashboard/scheduled/run');
  },
  byId: (id: string) => {
    return Request.get(`/notifications/dashboard/scheduled/${id}/`);
  },
  delete: (id: string) => {
    return Request.delete(`/notifications/dashboard/scheduled/${id}/`);
  },
  update: (id: string, data: object) => {
    return Request.put(`/notifications/dashboard/scheduled/${id}/`, { ...data });
  },
  smtpTemplates: (data: object) => {
    return Request.get('/notifications/dashboard/smtp_templates/', data);
  },
  templates: (data: object) => {
    return Request.get('/notifications/dashboard/notifications-templates/', data);
  },
  updateTemplates: (id: number, data: object) => {
    return Request.patch(`/notifications/dashboard/notifications-templates/${id}/`, data);
  },
  config: {
    list: (data: object = {}) => {
      return Request.get('/notifications/dashboard/notifications-config/', data);
    },
    byId: (id: string) => {
      return Request.get(`/notifications/dashboard/notifications-config/${id}/`);
    },
    update: (id: string, data: object) => {
      return Request.patch(`/notifications/dashboard/notifications-config/${id}/`, data);
    },
    categories: (data: object = {}) => {
      return Request.get(`/notifications/dashboard/notifications-category/`, data);
    },
  },
};
