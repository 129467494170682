import { createContext, ReactElement, ReactNode, useEffect } from 'react';

import { useRequest } from 'hooks';
import { UsersApi } from 'lib/api';
import { User } from 'lib/api/users/users.types';
import { $Object } from 'lib/object';

import { requestType } from '../hooks/useRequest';

interface OpenedUserContextInterface {
  user: { data?: User; request: requestType<User> };
  impersonate: { data: { count: number; results: [] }; getData: (params?: $Object) => void; loading: boolean };
}

export const OpenedUserContext = createContext<OpenedUserContextInterface>({
  user: { request: () => Promise.resolve() },
  impersonate: { data: { count: 0, results: [] }, getData: () => null, loading: false },
});

export const OpenedUserProvider = ({ children, userId }: { children: ReactNode; userId: string }): ReactElement => {
  const { request: requestUser, data: userData } = useRequest<User>();

  const { request: requestImpersonate, data: impersonateData, loading: impersonateLoading } = useRequest<{
    results: [];
    count: 0;
  }>({ data: { count: 0, results: [] } });

  useEffect(() => {
    if (userId) {
      requestUser(UsersApi.getAdmin(userId));
    }
  }, [userId]);

  const getImpersonateHistory = (params = {}) =>
    requestImpersonate(UsersApi.getImpersonateLogs({ email: userData.email, ...params }));

  return (
    <OpenedUserContext.Provider
      value={{
        user: { data: userData, request: requestUser },
        impersonate: { data: impersonateData, getData: getImpersonateHistory, loading: impersonateLoading },
      }}
    >
      {children}
    </OpenedUserContext.Provider>
  );
};
