import { useEffect } from 'react';
import Select, { SelectProps } from 'antd/lib/select';
import { useIntl } from 'estafette-intl';

import { useRequest } from 'hooks';
import { CommonApi } from 'lib/api';

interface CountrySelectProps extends SelectProps<string> {
  includedCountries?: string[];
}

export const CountrySelect = ({ includedCountries, ...props }: CountrySelectProps) => {
  const { t } = useIntl();

  const countries = useRequest<{ alpha_2: string; name: string }[]>({ data: [] });

  useEffect(() => {
    countries.request(CommonApi.countries());
  }, []);

  const filteredCountries = includedCountries
    ? countries.data.filter(({ alpha_2 }) => includedCountries.includes(alpha_2))
    : countries.data;

  return (
    <Select
      placeholder={t('pleaseSelectA', { item: t('country').toLowerCase() })}
      showSearch
      optionFilterProp="children"
      loading={countries.loading}
      {...props}
    >
      {filteredCountries.map(item => (
        <Select.Option key={item.alpha_2} value={item.alpha_2}>
          {item.name}
        </Select.Option>
      ))}
    </Select>
  );
};
