import { Request } from '../../index';

export const FaqApi = {
  list: (data = {}) => {
    return Request.get('/content/faq/', data);
  },
  create: (data: object) => {
    return Request.post('/content/faq/', { ...data });
  },
  byId: (id: string) => {
    return Request.get(`/content/faq/${id}/`);
  },
  delete: (id: string) => {
    return Request.delete(`/content/faq/${id}/`);
  },
  update: (id: string, data: object) => {
    return Request.patch(`/content/faq/${id}/`, { ...data });
  },
};
