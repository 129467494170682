import { Request } from '../../index';

export const SalesApi = {
  getSharedSlots: (params = {}) => Request.get(`/sales/dashboard/shared-slots/`, params),

  shareSlot: (id: number, options = {}) => Request.post(`/sales/dashboard/shared-slots/${id}/activate/`, options),

  deactivateSlot: (id: number, options = {}) =>
    Request.post(`/sales/dashboard/shared-slots/${id}/deactivate/`, options),

  availableSizes: () => Request.get(`/sales/dashboard/products/available_sizes/`),

  giftsList: (data: object) => Request.get('/sales/dashboard/user-gifts/', data),

  dropOffRateReport: (data = {}) => Request.get('/sales/dashboard/orders-statistics/', data),

  ordersList: (data = {}) => Request.get('/sales/dashboard/orders/', data),

  removeWarehouseOrder: (id: number, data = {}) =>
    Request.post(`/sales/dashboard/orders/${id}/remove-warehouse-order/`, data),

  createWarehouseOrder: (id: number) => Request.post(`/sales/dashboard/orders/${id}/create-warehouse-order/`),
};
