import { forwardRef, useEffect, useMemo } from 'react';
import { Row, Select, Spin } from 'antd';
import { useIntl } from 'estafette-intl';
import { SelectProps } from 'antd/lib/select';
import { useRequest, useStateHandlers } from 'hooks';
import { InfiniteScroll } from 'ui/molecules';
import { TasksApi } from 'lib/api';
import { pageSize } from 'lib/globalVars';
import { TaskTypeDetail } from 'lib/api/tasks/tasks.types';
import { WithPagination } from 'lib/entities';

interface StateType {
  page: number;
  query?: string;
  types: number | number[];
}

const { Option } = Select;

interface TaskTypesSelectProps extends SelectProps<number | number[] | undefined> {
  query?: string;
  parentsNodeClassName?: string;
  orderId?: string;
  isAutoSetup?: boolean;
  defaultTaskTypes?: TaskTypeDetail[];
  onResetType?: (existType: boolean) => void;
}

export const TaskTypesSelect = forwardRef<Select<number | number[]>, TaskTypesSelectProps>(
  ({ query, parentsNodeClassName, orderId, isAutoSetup, defaultTaskTypes, onResetType, ...props }, ref) => {
    const { t } = useIntl();
    const types = useRequest<WithPagination<TaskTypeDetail[]>>({ data: [] });
    const [state, setState] = useStateHandlers<StateType>({ page: 1, types: [], query });

    const includeValue = props.value || props.defaultValue;

    useEffect(() => {
      types.request(
        TasksApi.types.list({
          page_size: pageSize,
          page: state.page,
          name: state.query,
          order: orderId,
          include: includeValue ? (Array.isArray(includeValue) ? includeValue : [includeValue]) : undefined,
        }),
        state.page > 1 ? { concat: 'results' } : {},
      );
    }, [state.page, state.query, orderId]);

    useEffect(() => {
      if (!isAutoSetup || types.data?.count !== 1 || state.query) return;
      props.onChange?.(types.data?.results[0].id, <></>);
    }, [types.data, isAutoSetup]);

    useEffect(() => {
      setState({ query });
    }, [query]);

    useEffect(() => {
      const value = Array.isArray(props.value) ? props.value : [props.value];

      const existType = props?.value ? types?.data?.results?.some(({ id }) => value.includes(id)) : true;

      onResetType?.(existType);
    }, [types.data]);

    const hasMore = useMemo(() => !types?.loading && Math.ceil(types?.data?.count / pageSize) > state?.page, [
      types.loading,
      types.data,
      state.page,
      defaultTaskTypes,
    ]);

    const onPageChange = () => {
      if (hasMore) {
        setState(prevState => ({ page: ++prevState.page }));
      }
    };

    const onSearch = (query?: string) => {
      setState({ page: 1, query });
    };

    const onChange = (types: number | number[]) => {
      setState({ types });
    };

    const defaultTaskTypesOption = useMemo(() => {
      if (state?.query?.length) {
        return defaultTaskTypes?.filter(type =>
          type?.name?.toLowerCase().includes(state?.query?.toLowerCase() as string),
        );
      }
      return defaultTaskTypes;
    }, [state.query, defaultTaskTypes]);

    const options = useMemo(() => {
      const combinedTypes = [...(defaultTaskTypesOption || []), ...(types?.data?.results || [])];

      const uniqueIds = new Set();

      const filteredOptions = combinedTypes?.filter(type => {
        if (!uniqueIds?.has(type?.id)) {
          uniqueIds?.add(type?.id);
          return true;
        } else {
          return false;
        }
      });

      return filteredOptions;
    }, [types?.data?.results, defaultTaskTypesOption]);

    return (
      <Select
        ref={ref}
        loading={types.loading}
        placeholder={t('pleaseSelectA', { item: t('taskType').toLowerCase() })}
        showSearch
        allowClear
        filterOption={() => true}
        onSearch={onSearch}
        onChange={onChange}
        style={{ width: '100%' }}
        onDropdownVisibleChange={() => onSearch()}
        dropdownClassName={parentsNodeClassName}
        dropdownRender={menu => (
          <InfiniteScroll onLoadMore={onPageChange} isDirectChild hasMore={true}>
            {menu}
            {types.loading && (
              <Row type="flex" justify="center">
                <Spin style={{ height: 30 }} />
              </Row>
            )}
          </InfiniteScroll>
        )}
        {...props}
      >
        {options?.map(({ id, name }) => (
          <Option key={id} value={id}>
            {name}
          </Option>
        ))}
      </Select>
    );
  },
);
