import React from 'react';
import Loadable from 'react-loadable';
import { LoadingPlaceholder } from 'ui/atoms';
import {
  comments,
  contentAdministration,
  posts,
  productsAndOffers,
  reportsAndFeedback,
  sales,
  users,
  exports,
  experimentalFeatures,
  notifications,
  tasks,
} from './mainRoutes';

export interface Route {
  text: string | null;
  path: string;
  component?: React.ReactNode;
  icon?: string;
  children: Route[];
  groups?: string[];
}

export const routes: Route[] = [
  // Users
  ...users,

  // Content administration
  ...contentAdministration,

  // Products and offers
  ...productsAndOffers,

  // Comments
  ...comments,

  // Sales
  ...sales,

  // Tasks
  ...tasks,

  // Reports and feedback really
  ...reportsAndFeedback,

  // Posts
  ...posts,

  // Exports
  ...exports,

  // Experimental features
  ...experimentalFeatures,

  // Notifications
  ...notifications,

  // 404
  {
    path: '/404',
    text: '',
    icon: 'team',
    component: Loadable({
      loader: () => import('../../features/404/pages/NotFoundPage'),
      loading: LoadingPlaceholder,
      delay: 300,
    }),
    children: [],
    groups: [],
  },
];
