import Loadable from 'react-loadable';
import { LoadingPlaceholder } from 'ui/atoms';

import { Route } from 'lib/router/routes';

export const posts: Route[] = [
  {
    path: '/posts',
    text: 'posts',
    icon: 'home',
    children: [
      {
        path: '/episodes',
        text: 'postsOfTheDay',
        icon: 'play-circle',
        component: Loadable({
          loader: () => import('../../../../features/posts/pages/EODPage/EODPage'),
          loading: LoadingPlaceholder,
          delay: 300,
        }),
        children: [],
        groups: ['customer_care', 'moderator', 'consilier', 'sales_specialist', 'warehouse_admin'],
      },
      {
        path: '/episodes/detail/:id',
        text: null,
        icon: 'play-circle',
        component: Loadable({
          loader: () => import('../../../../features/posts/PostsDetailPage'),
          loading: LoadingPlaceholder,
          delay: 300,
        }),
        children: [],
        groups: ['customer_care', 'moderator', 'consilier', 'sales_specialist', 'warehouse_admin'],
      },
      {
        path: '/episodes/add',
        text: null,
        icon: 'play-circle',
        component: Loadable({
          loader: () => import('../../../../features/posts/AddEpisodePage'),
          loading: LoadingPlaceholder,
          delay: 300,
        }),
        children: [],
        groups: ['customer_care', 'moderator', 'consilier', 'sales_specialist', 'warehouse_admin'],
      },
      {
        path: '/episodes/edit/:id',
        text: null,
        icon: 'play-circle',
        component: Loadable({
          loader: () => import('../../../../features/posts/EditPostPage'),
          loading: LoadingPlaceholder,
          delay: 300,
        }),
        children: [],
        groups: ['customer_care', 'moderator', 'consilier', 'sales_specialist', 'warehouse_admin'],
      },
    ],
    groups: ['customer_care', 'moderator', 'consilier', 'sales_specialist', 'warehouse_admin'],
  },
];
