import { createMessages } from 'estafette-intl';
import en from './en.json';

const translate = [...Object.keys(en)].reduce((accumulator, value) => {
  return {
    ...accumulator,
    [value]: {
      // @ts-ignore
      en: en[value],
    },
  };
}, {});

export const messages = createMessages([{ ...translate }]);
