import { Request } from '../../index';
import { warehouseURL } from '../../request';

export const ProductsGroupsApi = {
  list: (data = {}) => {
    return Request.get(`${warehouseURL}/products-groups/`, data);
  },
  create: (data: object) => {
    return Request.post('products-groups/', data);
  },
  byId: (id?: number, data?: object) => {
    return Request.get(`${warehouseURL}/products-groups/${id}/`, data);
  },
  update: (id: string, data: object) => {
    return Request.patch(`products-groups/${id}/`, { ...data });
  },
  delete: (id: string) => {
    return Request.delete(`products-groups/${id}/`);
  },
};
