import React, { useEffect, useRef, useState } from 'react';
import { Alert, Button, Form, Input, notification, Statistic } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { useIntl } from 'estafette-intl';
import { SessionApi } from 'lib/api';
import { APIValidationError, Request, SessionStorage } from 'lib';
import { Space } from 'ui/atoms';
import { getUnknownError } from 'lib/utils';
import { SessionLayout } from 'ui/organisms';

const { Countdown } = Statistic;

const ConfirmPhoneForm = ({ form }: FormComponentProps) => {
  const { t } = useIntl();
  const { getFieldDecorator } = form;

  const [formState, setFormState] = useState({ loading: false, error: undefined });
  const [enableResend, setEnableResend] = useState(false);

  const twoFactorToken = SessionStorage.getTwoFactorToken();
  const phoneNumber = SessionStorage.getUserPhoneNumber();
  const deadline = useRef(Date.now() + 30 * 1000);

  useEffect(() => {
    const timer = setInterval(() => {
      const currentTime = Date.now();
      if (currentTime >= deadline.current) {
        clearInterval(timer);
        setEnableResend(true);
      }
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    form.validateFields((err, fields) => {
      if (!err) {
        setFormState({ loading: true, error: undefined });

        SessionApi.loginConfirm({ phone: phoneNumber, two_factor_token: twoFactorToken, token: fields?.phone })
          .then(({ data }) => {
            Request.loggedInCb(data);
          })
          .catch(({ response }) => {
            if (response.detail) {
              return setFormState({ loading: false, error: response?.detail || t('somethingWentWrong') });
            }

            if (response.data) {
              const errorMessage = getUnknownError(response.data)?.[0];

              return setFormState({ loading: false, error: errorMessage || t('somethingWentWrong') });
            }

            if (err instanceof APIValidationError) {
              const errors = err.getFieldsError();

              form.setFields(errors);
            }

            if (response?.data?.reason === 'non_device_login') {
              notification.error({
                message: `Something is wrong: ${response?.data?.reason}`,
              });
            }
          })
          .finally(() => setFormState(prev => ({ ...prev, loading: false })));
      }
    });
  };

  const onClickResendButton = () => {
    SessionApi.resendConfirm({ phone: phoneNumber, two_factor_token: twoFactorToken })
      .then(({ data }) => {
        if (data.success) {
          notification.success({ message: t('resendSuccessfully') });
          deadline.current = Date.now() + 30 * 1000;
          setEnableResend(false);
        }
      })
      .catch(({ response }) => {
        if (response.detail) {
          return setFormState({ loading: false, error: response?.detail || t('somethingWentWrong') });
        }

        if (response.data) {
          const errorMessage = getUnknownError(response.data)?.[0];

          return setFormState({ loading: false, error: errorMessage || t('somethingWentWrong') });
        }

        if (response?.data?.reason === 'non_device_login') {
          notification.error({
            message: `Something is wrong: ${response?.data?.reason}`,
          });
        }
      })
      .finally(() => setFormState(prev => ({ ...prev, loading: false })));
  };

  return (
    <SessionLayout>
      <Form onSubmit={handleSubmit}>
        {formState?.error && <Alert className="login-confirm-form-alert" message={formState?.error} type="error" />}

        <Space direction="column" align="center">
          <b className="login-confirm-form-text">
            {t('phoneConfirmFormText')}
            {phoneNumber?.substring?.(phoneNumber?.length - 4, phoneNumber?.length)}
          </b>

          <Form.Item style={{ textAlign: 'center' }}>
            {getFieldDecorator('phone', {
              rules: [
                {
                  required: true,
                  message: 'Please input the code!',
                },
              ],
            })(<Input style={{ width: 100 }} maxLength={4} />)}
          </Form.Item>

          <Button loading={formState?.loading} type="primary" htmlType="submit" className="login-confirm-form-button">
            {t('continue')}
          </Button>

          <Space space={4} align="end" justify="center">
            <Countdown
              onFinish={() => setEnableResend(true)}
              value={deadline.current}
              format="ss"
              valueStyle={{ fontSize: '14px' }}
              className="login-confirm-form-countdown"
              valueRender={value => <span hidden={enableResend}>{value}</span>}
              prefix={
                <>
                  <span className="login-confirm-form-receive">{`${t('receiveCode')} `}</span>

                  <Button
                    disabled={!enableResend}
                    onClick={onClickResendButton}
                    type="ghost"
                    className="login-confirm-form-resend"
                  >
                    {t('resend')}
                  </Button>

                  <span className="login-confirm-form-countdown" hidden={enableResend}>{` (${t('timer')} `}</span>
                </>
              }
              suffix={<span className="login-confirm-form-countdown" hidden={enableResend}>{`${t('sec')})`}</span>}
            />
          </Space>
        </Space>
      </Form>
    </SessionLayout>
  );
};

const WrappedConfirmPhoneForm = Form.create({ name: 'confirm-phone' })(ConfirmPhoneForm);
export default WrappedConfirmPhoneForm;
