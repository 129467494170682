import Loadable from 'react-loadable';
import { LoadingPlaceholder } from 'ui/atoms';

import { Route } from 'lib/router/routes';

export const pages: Route[] = [
  {
    path: '/help-register',
    text: 'helpRegister',
    icon: 'usergroup-add',
    component: Loadable({
      loader: () => import('../../../../features/administration/pages/HelpRegister'),
      loading: LoadingPlaceholder,
      delay: 300,
    }),
    children: [],
    groups: [],
  },
  {
    path: '/gdpr',
    text: 'gdpr',
    icon: 'safety-certificate',
    component: Loadable({
      loader: () => import('../../../../features/administration/pages/GDPRPage'),
      loading: LoadingPlaceholder,
      delay: 300,
    }),
    children: [],
    groups: [],
  },
  {
    path: '/terms-and-conditions',
    text: 'termsAndConditions',
    icon: 'unordered-list',
    component: Loadable({
      loader: () => import('../../../../features/administration/pages/TermsPage'),
      loading: LoadingPlaceholder,
      delay: 300,
    }),
    children: [],
    groups: [],
  },
  {
    path: '/privacy',
    text: 'privacyPolicy',
    icon: 'lock',
    component: Loadable({
      loader: () => import('../../../../features/administration/pages/PrivacyPage'),
      loading: LoadingPlaceholder,
      delay: 300,
    }),
    children: [],
    groups: [],
  },
  {
    path: '/about',
    text: 'about',
    icon: 'info-circle',
    component: Loadable({
      loader: () => import('../../../../features/administration/pages/AboutPage'),
      loading: LoadingPlaceholder,
      delay: 300,
    }),
    children: [],
    groups: [],
  },
];
