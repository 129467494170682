import { DatePicker } from 'antd';
import { DatePickerProps } from 'antd/lib/date-picker/interface';

interface CustomDatePickerProps extends DatePickerProps {
  enableInputMode?: boolean;
}

export const CustomDatePicker = ({ enableInputMode, onFocus, ...props }: CustomDatePickerProps) => (
  <DatePicker
    onFocus={ev => {
      onFocus?.(ev);
      if (enableInputMode) return;
      const input = ev.target as HTMLInputElement;
      input.inputMode = 'none';
      input.onclick = ev => {
        const input = ev.target as HTMLInputElement;

        if (!input.readOnly) input.inputMode = 'text';
      };
    }}
    {...props}
  />
);
