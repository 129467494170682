import React, { useContext, useEffect } from 'react';
import { useIntl } from 'estafette-intl';
import { Avatar, Button, Checkbox, Col, Form, Input, Modal, Row, Select } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { Templates } from 'lib/api';
import { maxLengthValidation } from 'lib/utils';
import { AddTemplateModal } from 'features/comments/organisms/templates';
import { InitialFiltersProps } from 'features/comments/pages/ParentingQA';
import { ProfileContext, UsersContext } from 'contexts';
import { TemplateComment } from 'lib/entities/comments/templates';
import { ProgramComment } from 'lib/entities/comments/programs';
import { EODComment } from 'lib/entities/comments/eod';
import { useStateHandlers } from 'hooks';
import { MobilePreview } from 'features/comments/organisms/mobile-preview/MobilePreview';
import { UserShort } from 'lib/api/users/users.types';
import { ReplyTypes } from 'features/comments/index.type';

interface ReplyWrapperProps {
  filters: InitialFiltersProps;
  onCommentRemove?: (id: number) => void;
  comment: ProgramComment | EODComment;
  episode: string;
  type: ReplyTypes;
  onRefresh: () => void;
}

interface State {
  search: string;
  loading: boolean;
  templates: { episode: TemplateComment[]; common: TemplateComment[] } | TemplateComment[];
  text: string;
  toTemplate: boolean;
  showAddModal: boolean;
  showMobilePreview: boolean;
  authorId?: number;
  selectedAuthor?: UserShort;
  replyLoading: boolean;
}

const { Option, OptGroup } = Select;
const { TextArea } = Input;

export const ReplyWrapper = ({ onCommentRemove, comment, filters, episode, type, onRefresh }: ReplyWrapperProps) => {
  const { moderators } = useContext(UsersContext);
  const { t } = useIntl();

  const {
    admin: { data: adminData },
  } = useContext(ProfileContext);

  const [state, setState] = useStateHandlers<State>({
    search: '',
    loading: false,
    templates: {
      episode: [],
      common: [],
    },
    text: '',
    toTemplate: false,
    showAddModal: false,
    showMobilePreview: false,
    replyLoading: false,
  });

  useEffect(() => {
    makeCall();
  }, [state.search]);

  useEffect(() => {
    setState({ selectedAuthor: moderators.data.data.find(i => i.id === state.authorId) });
  }, [state.authorId]);

  const makeCall = async () => {
    setState({ loading: true });

    if (!(type === ReplyTypes.eod)) {
      const res = await Templates.listByEpisode({
        search: state.search,
        language: filters.language,
        episode,
        template_type: 'parenting',
      });

      setState({ templates: res });
    } else {
      const res = await Templates.list({ search: state.search, language: filters.language, template_type: 'eod' });

      setState({ templates: res });
    }

    setState({ loading: false });
  };

  const onSearchChange = (search: string) => setState({ search });
  const onTextChange = (text: string) => setState({ text });
  const onReplySave = () => {
    setState({ toTemplate: false, showMobilePreview: false });

    if (state.toTemplate) {
      setState({ showAddModal: true });
    } else {
      setState({ text: '' });

      if ('answered' in filters && filters.answered === 'not_answered' && onCommentRemove) {
        onCommentRemove(comment.id);
      }
    }
  };

  const onReplyChange = (e: React.ChangeEvent<HTMLTextAreaElement>) =>
    setState({ text: maxLengthValidation(e.target.value) });
  const onSaveTemplateClick = ({ target }: CheckboxChangeEvent) => setState({ toTemplate: target.checked });
  const onShowAddModal = () => {
    setState(prevState => ({ showAddModal: !prevState.showAddModal, text: '' }));

    if ('answered' in filters && filters.answered === 'not_answered' && onCommentRemove) {
      onCommentRemove(comment.id);
    }
  };
  const onMobilePreviewOpen = () => setState({ showMobilePreview: true });
  const onMobilePreviewClose = () => setState({ showMobilePreview: false });
  const onAuthorChange = (id: number) => setState({ authorId: id });

  return (
    <>
      <Modal
        title="Please select a template title"
        visible={state.showAddModal}
        onCancel={onShowAddModal}
        footer={null}
        width={1000}
      >
        <AddTemplateModal
          onSubmitSuccess={onShowAddModal}
          options={{
            text: state.text,
            template_type: episode ? 'education_episode' : 'eod',
            language: filters.language,
          }}
        />
      </Modal>

      {state.showMobilePreview && (
        <Modal
          visible={true}
          onCancel={onMobilePreviewClose}
          footer={null}
          width={375}
          className="m-mobile-preview-modal"
          closable={false}
        >
          <MobilePreview
            comment={comment}
            selectedAuthor={state.selectedAuthor}
            lastCommentText={state.text}
            type={type}
            onRefresh={onRefresh}
            onReplySave={onReplySave}
          />
        </Modal>
      )}

      <div className="reply-wrapper">
        <div className="reply-container">
          <div className="reply">
            <div className="select-container">
              <Select
                showSearch
                className="select"
                onChange={onTextChange}
                onSearch={onSearchChange}
                placeholder="Select from template"
                optionFilterProp="children"
                loading={state.loading}
                style={{ minWidth: 180 }}
              >
                {!Array.isArray(state.templates) && state.templates.episode?.length > 0 && (
                  <OptGroup label="Custom">
                    {state.templates.episode.map(item => (
                      <Option value={item.text} key={item.id}>
                        {item.subject}
                      </Option>
                    ))}
                  </OptGroup>
                )}
                {!Array.isArray(state.templates) && state.templates.common?.length > 0 && (
                  <OptGroup label="Common">
                    {state.templates.common.map(item => (
                      <Option value={item.text} key={item.id}>
                        {item.subject}
                      </Option>
                    ))}
                  </OptGroup>
                )}
                {Array.isArray(state.templates) && (
                  <OptGroup label="Common">
                    {state.templates.map(item => (
                      <Option value={item.text} key={item.id}>
                        {item.subject}
                      </Option>
                    ))}
                  </OptGroup>
                )}
              </Select>

              <div className="d-flex">
                <Checkbox onChange={onSaveTemplateClick} checked={state.toTemplate}>
                  Save as a template
                </Checkbox>
              </div>

              <div className="mr-15">
                Reply as:{' '}
                <Select
                  onChange={onAuthorChange}
                  onSearch={moderators.onSearch}
                  loading={moderators.loading}
                  style={{ width: 150 }}
                  placeholder={t('author')}
                  dropdownClassName="m-dropdown-width-auto"
                  optionFilterProp="children"
                  allowClear
                  showSearch
                >
                  {moderators.data.data.map(i => (
                    <Select.Option key={i.id} value={i.id}>
                      {i.full_name} ({i.email})
                    </Select.Option>
                  ))}
                </Select>
              </div>
            </div>

            <Row type="flex" gutter={{ xs: 8, sm: 16, md: 24 }} style={{ flexWrap: 'nowrap' }}>
              <Col>
                <Avatar src={adminData.photo || ''} shape="circle" size={48} />
              </Col>
              <Col style={{ width: '100%' }}>
                <Form.Item className="mb-10">
                  <TextArea
                    value={state.text}
                    autoSize={{ minRows: 4 }}
                    onChange={onReplyChange}
                    className="textarea"
                    placeholder="No more than 5000 letters"
                  />
                </Form.Item>

                <Row>
                  <Col>
                    <Form.Item>
                      <Button htmlType="submit" onClick={onMobilePreviewOpen} type="primary" disabled={!state.text}>
                        {t('checkMobilePreview')}
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </>
  );
};
