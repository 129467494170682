import { Request } from '../../index';

export const EventsApi =  {
  list: (data: object) => {
    return Request.get('/education/dashboard/events/', data);
  },
  create: (data: object) => {
    return Request.post('/education/dashboard/events/', data);
  },
  byId: (id: string, data: object) => {
    return Request.get(`/education/dashboard/events/${id}/`, { ...data });
  },
  update: (id: string, data: object) => {
    return Request.patch(`/education/dashboard/events/${id}/`, { ...data });
  },
  delete: (id: string) => {
    return Request.delete(`/education/dashboard/events/${id}/`);
  },
};
