import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Tag, Tooltip, message } from 'antd';
import Table, { ColumnProps, TableProps, TableRowSelection } from 'antd/lib/table';
import { useIntl } from 'estafette-intl';
import moment from 'moment';

import { useIsRestrictedTo, useRequest, useStateHandlers } from 'hooks';
import { TasksApi } from 'lib/api';
import { Task, TaskStatus, taskStatuses } from 'lib/api/tasks/tasks.types';
import { $Object, withValues } from 'lib/object';
import { dateTimeFormat, objToQuery, putIfExist, withPagination } from 'lib/utils';
import { Space } from 'ui/atoms';
import { TaskActionModal } from './TaskActionModal';
import { BillingStatuses, statuses } from 'lib/entities/payments-management/orders';
import { UserGroups } from 'lib/entities/profile';

const formatDate = 'DD/MM/YYYY';

export interface TaskListTableProps<T extends Task = Task> extends Omit<TableProps<T>, 'rowSelection'> {
  filters?: Filters;
  onPageChange: (page: number) => void;
  paginationSize?: boolean;
  columnList?: Column[];
  rowSelection?: Omit<TableRowSelection<Task>, 'selectedRowKeys'>;
  getSelectedRowKeys?: (data: T[]) => TableRowSelection<T>['selectedRowKeys'];
  setTotalResults?: (count: number) => void;
  updateRequest?: React.MutableRefObject<(() => Promise<void>) | undefined>;
}

interface Filters extends $Object {
  page: number;
  page_size: number;
}

export type Column =
  | 'language'
  | 'full_name'
  | 'order_full_name'
  | 'email'
  | 'phone'
  | 'type'
  | 'order_date'
  | 'order_id'
  | 'order_billing_status'
  | 'due_date'
  | 'responsible'
  | 'group'
  | 'status'
  | 'action'
  | 'schedule'
  | 'follow_up';

export interface ModalState {
  task?: Task | null;
  modalType?: 'assignee' | 'status' | null;
}

const initialFilters = { page: 1, page_size: 10 };

const TaskListTable = ({
  filters = initialFilters,
  onPageChange,
  paginationSize = false,
  columnList,
  rowSelection,
  getSelectedRowKeys,
  setTotalResults,
  updateRequest,
  ...props
}: TaskListTableProps) => {
  const { t } = useIntl();

  const { request, data, loading } = useRequest<withPagination<Task>>({ data: [] });
  const [modalState, setModalState] = useStateHandlers<ModalState>({});
  const [waiting, setWaiting] = useState(false);
  const isRestrictedTo = useIsRestrictedTo();

  useEffect(() => {
    _makeCall();
    if (updateRequest)
      updateRequest.current = async () => {
        setWaiting(true);
        const hide = message.loading(t('actionInProgress'), 0);
        setTimeout(() => {
          _makeCall();
          setWaiting(false);
          hide();
        }, 2000);
      };
  }, [filters]);

  useEffect(() => {
    setTotalResults?.(data?.count);
  }, [data?.count, setTotalResults]);

  const _makeCall = async () => {
    try {
      await request(TasksApi.list(filters));
    } catch (e) {
      message.error(t('serverError'));
    }
  };

  const columns: ColumnProps<Task>[] = [
    { title: t('language'), dataIndex: 'language', key: 'language', render: language => language.toUpperCase() },
    {
      title: t('followUpDate'),
      dataIndex: 'follow_up',
      key: 'follow_up',
      render: (date, record) =>
        putIfExist(
          date && (
            <div style={{ textAlign: 'center' }}>
              <div>{moment(date).format(formatDate)}</div>
              {record.follow_up_tz && <div>{record.follow_up_tz}</div>}
            </div>
          ),
        ),
    },
    {
      title: t('clientName'),
      dataIndex: 'customer',
      key: 'full_name',
      render: user => putIfExist(user?.id && <Link to={`/users/detail/${user.id}`}>{user.full_name}</Link>),
    },
    {
      title: t('orderUser'),
      dataIndex: 'order.user',
      key: 'order_full_name',
      render: user => putIfExist(user?.id && <Link to={`/users/detail/${user.id}`}>{user.full_name}</Link>),
    },
    {
      title: t('email'),
      dataIndex: 'customer.email',
      key: 'email',
      className: 'fixed small',
      render: email =>
        email && email.length > 14 ? (
          <span>
            <Tooltip title={email}>{email}</Tooltip>
          </span>
        ) : (
          <span>{putIfExist(email)}</span>
        ),
    },
    { title: t('phone'), dataIndex: 'customer.phone', key: 'phone', render: putIfExist },
    {
      title: t('orderDate'),
      dataIndex: 'order.date',
      key: 'order_date',
      render: date => putIfExist(date && moment(date).format(formatDate)),
    },
    {
      title: t('orderId'),
      dataIndex: 'order.id',
      key: 'order_id',
      render: (id: string, record) =>
        putIfExist(
          id && (
            <Link to={`/orders/${id}/details${objToQuery(withValues({ user: record?.order?.user?.id }))}`}>{id}</Link>
          ),
        ),
    },
    {
      title: t('orderStatus'),
      dataIndex: 'order.billing_status',
      key: 'order_billing_status',
      render: (status: BillingStatuses) => {
        if (status) {
          const currentStatus = statuses[status];

          if (currentStatus) {
            return <Tag color={currentStatus.color}>{currentStatus.label}</Tag>;
          }
        }

        return '—';
      },
    },
    { title: t('type'), dataIndex: 'type.name', key: 'type' },
    {
      title: t('createdAt'),
      dataIndex: 'created_at',
      key: 'created_at',
      render: date => moment(date).format(dateTimeFormat),
    },
    {
      title: t('dueDate'),
      dataIndex: 'due_date',
      key: 'due_date',
      render: (date, record) =>
        putIfExist(
          !!(date && record?.order?.date && record.status.state !== TaskStatus.SCHEDULED) && (
            <Tooltip title={moment(moment().format('MM/DD/YYYY')).to(date)}>{moment(date).format(formatDate)}</Tooltip>
          ),
        ),
    },
    {
      title: t('scheduleDate'),
      dataIndex: 'schedule',
      key: 'schedule',
      render: (date, record) =>
        putIfExist(
          !!(date && record?.order?.date && record.status.state === TaskStatus.SCHEDULED) &&
            moment(date).format(formatDate),
        ),
    },
    {
      title: t('responsible'),
      dataIndex: 'responsible',
      key: 'responsible',
      render: responsible =>
        responsible ? <Link to={`/users/detail/${responsible.id}`}>{responsible.full_name}</Link> : t('unassigned'),
    },
    {
      title: t('group'),
      dataIndex: 'group.name',
      key: 'group',
      render: (name, record) =>
        putIfExist(name && <Link to={`/user-responsible-groups/${record.group?.id}/edit`}>{name}</Link>),
    },
    {
      title: t('status'),
      dataIndex: 'status.name',
      key: 'status',
      render: (status, record) => {
        const color = taskStatuses[record.status.state].color;
        return <Tag color={color}>{status}</Tag>;
      },
    },
    ...((isRestrictedTo([UserGroups.administrator, UserGroups.consilier, UserGroups.customerCare])
      ? [
          {
            title: t('actions'),
            key: 'action',
            render: (_, record) => {
              return (
                <Space space={8} direction="column">
                  <Button size="small" icon="user" type="primary" onClick={() => onAssigneeChange(record)}>
                    {t('changeItem', { item: t('responsible').toLowerCase() })}
                  </Button>
                  <Button size="small" icon="pushpin" onClick={() => onStatusChange(record)}>
                    {t('changeItem', { item: t('status').toLowerCase() })}
                  </Button>
                  <Link to={`/task-list/${record.id}/details`}>{t('view')}</Link>
                </Space>
              );
            },
          },
        ]
      : []) as ColumnProps<Task>[]),
  ];

  const onAssigneeChange = (task: Task) => setModalState({ modalType: 'assignee', task });
  const onStatusChange = (task: Task) => setModalState({ modalType: 'status', task });
  const onClose = () => setModalState({ modalType: null, task: null });
  const onSuccess = () => {
    _makeCall();
    onClose();
  };

  const filteredColumns = columnList ? columns.filter(({ key }) => columnList.includes(key as Column)) : columns;

  return (
    <div className="table-container" style={{ margin: 0 }}>
      <Table
        loading={loading || waiting}
        rowKey="id"
        dataSource={data?.results || []}
        columns={filteredColumns}
        className="orders-list scrolling-list"
        pagination={
          !!data.results?.length
            ? {
                current: filters.page,
                pageSize: filters.page_size,
                total: data?.count,
                onChange: onPageChange,
                position: 'both',
                ...(paginationSize && { className: 'mr-15', position: 'bottom' }),
              }
            : false
        }
        scroll={{ x: true }}
        {...(isRestrictedTo([UserGroups.administrator, UserGroups.consilier, UserGroups.customerCare])
          ? {
              rowSelection: {
                ...rowSelection,
                selectedRowKeys: getSelectedRowKeys?.(data.results),
              },
            }
          : {})}
        {...props}
      />

      <TaskActionModal onCancel={onClose} state={modalState} onSuccess={onSuccess} />
    </div>
  );
};

export default TaskListTable;
