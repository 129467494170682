import React, { useState } from 'react';
import { Alert, Button, Checkbox, Form, Icon, Input, notification } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { useIntl } from 'estafette-intl';
import { SessionApi } from 'lib/api';
import { getUnknownError } from 'lib/utils';
import { PasswordInput } from 'ui/atoms';
import { APIValidationError, Request } from 'lib';
import { useHistory } from 'react-router-dom';

interface defaultFormState {
  loading: boolean;
  error?: string;
}

const LoginForm = ({ form }: FormComponentProps) => {
  const { t } = useIntl();
  const { getFieldDecorator } = form;
  const history = useHistory();

  const [formState, setFormState] = useState<defaultFormState>({
    loading: false,
    error: undefined,
  });

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    form.validateFields((err, fields) => {
      if (!err) {
        setFormState({ loading: true, error: undefined });

        SessionApi.login(fields)
          .then(({ data }) => {
            if (data?.access && data?.refresh) {
              Request.loggedInCb(data);
            }

            if (data?.success) {
              localStorage.setItem('user-phone-number', data?.phone);
              localStorage.setItem('two-factor-token', data?.two_factor_token);

              history?.push('/sms-verification');
            }
          })
          .catch(({ response }) => {
            if (response?.detail) {
              return setFormState({ loading: false, error: response?.detail || t('somethingWentWrong') });
            }

            if (response?.data?.code === 'password_expired') {
              return history.push('/expired-password', { email: fields.email });
            }

            if (response?.data) {
              const errorMessage = getUnknownError(response?.data)?.[0];

              return setFormState({ loading: false, error: errorMessage || t('somethingWentWrong') });
            }

            if (err instanceof APIValidationError) {
              const errors = err.getFieldsError();

              form.setFields(errors);
            }

            if (response?.data?.reason === 'non_device_login') {
              notification.error({
                message: `Something is wrong: ${response?.data?.reason}`,
              });
            }
          })
          .finally(() => {
            setFormState(prev => ({ ...prev, loading: false }));
          });
      }
    });
  };

  return (
    <Form onSubmit={handleSubmit} className="login-form">
      {formState?.error && <Alert message={formState?.error} type="error" />}

      <Form.Item label="Email">
        {getFieldDecorator('email', {
          rules: [
            {
              required: true,
              message: 'Please enter a valid email',
              type: 'email',
            },
          ],
        })(<Input prefix={<Icon type="user" />} placeholder="Email" />)}
      </Form.Item>

      <Form.Item label="Password">
        {getFieldDecorator('password', {
          rules: [
            {
              required: true,
              message: 'Please input your Password',
            },
          ],
        })(<PasswordInput prefix={<Icon type="lock" />} placeholder="Password" />)}
      </Form.Item>

      <Form.Item style={{ display: 'flex' }} label="Trust browser for 30 days">
        {getFieldDecorator('trusted', {
          valuePropName: 'checked',
        })(<Checkbox />)}
      </Form.Item>

      <Button loading={formState.loading} type="primary" htmlType="submit" className="login-form-button">
        {t('logIn')}
      </Button>
    </Form>
  );
};

const WrappedLoginForm = Form.create<FormComponentProps>({ name: 'login' })(LoginForm);
export default WrappedLoginForm;
