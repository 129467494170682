import { Request } from '../index';

export const  ChildApi = {
  list: (params: { parent_id: string }) => {
    return Request.get('/children/dashboard/list', params);
  },
  delete: (id: string, data: object) => {
    return Request.post(`/children/child/${id}/delete-for-parent/`, data);
  },
};
