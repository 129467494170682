import React, { createContext, ReactElement, ReactNode, useEffect } from 'react';
import { Profile, UserGroupsConstNames } from 'lib/entities/profile';
import { useRefreshToken, useRequest, useStateHandlers } from 'hooks';
import { ProfileApi } from 'lib/api';
import { Request, SessionStorage } from 'lib';
import { useHistory } from 'react-router';
import { RequestResponse } from 'hooks/useRequest';

interface ProfileContextProps {
  admin: RequestResponse<Profile>;
  groups: { [key in UserGroupsConstNames]?: string };
}

const defaultUser = {
  blocked: false,
  child_count: 0,
  created_at: '',
  date_of_birth: '',
  email: '',
  families: [],
  full_name: '',
  groups: [],
  has_hided_my_posts: false,
  has_push_notifications: false,
  id: 0,
  is_staff: false,
  last_activity: '',
  member_count: 0,
  modified_at: '',
  phone: '',
  photo: '',
  post_count: 0,
  regular_login: false,
  role: '',
  unread_notifications: 0,
};

export const ProfileContext = createContext<ProfileContextProps>({
  admin: {
    request: () => Promise.resolve(),
    data: defaultUser,
    setData: () => null,
    loading: false,
    errors: {},
  },
  groups: {},
});

export const ProfileProvider = ({ children }: { children: ReactNode }): ReactElement => {
  const admin = useRequest<Profile>({ data: defaultUser });
  const { push, location } = useHistory();

  const [state, setState] = useStateHandlers({ groups: {} });

  useEffect(() => {
    const _fetchProfile = async () => {
      try {
        admin.request(ProfileApi.profile());
      } catch (err) {
        console.log(err);
      }
    };

    Request.setLoggedIn(({ access, refresh }: any) => {
      SessionStorage.setAccessToken(access);
      SessionStorage.setRefreshToken(refresh);
      push('/parenting-programs');
      _fetchProfile();
    });

    if (location.pathname === '/expired-password') return;

    const accessToken = SessionStorage.getAccessToken();
    if (accessToken) {
      _fetchProfile();
    } else {
      push('/login');
    }

    Request.setSessionExpiredCb(() => {
      SessionStorage.clearStorage();
      push('/login');
    });

    Request.setLoggedOut(() => {
      SessionStorage.clearStorage();
      push('/login');
      admin.setData(defaultUser);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (admin.data && admin.data.groups && admin.data.groups[0]) {
      const mapping = admin.data.groups
        .map(key => {
          const role = UserGroupsConstNames[key as keyof typeof UserGroupsConstNames];
          if (!role) return null;
          return [role, true];
        })
        .filter(v => v);

      setState({
        groups: Object.fromEntries(mapping as []),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [admin.data]);

  useRefreshToken();

  const values = {
    admin: admin,
    groups: state.groups,
  };

  return <ProfileContext.Provider value={values}>{children}</ProfileContext.Provider>;
};
