import { SelectProps } from 'antd/lib/select';
import { forwardRef } from 'react';
import { useIntl } from 'estafette-intl';
import { Select } from 'antd';

import { languages as defaultLanguages, pBotLanguages } from 'lib/globalVars';
import { Languages } from 'lib/entities';

import './LanguageSwitch.scss';

interface LanguageSwitchInterface extends SelectProps<Languages> {
  languages?: typeof pBotLanguages | typeof defaultLanguages;
}

const { Option } = Select;

export const LanguageSwitch = forwardRef<any, LanguageSwitchInterface>(
  ({ className = '', languages = defaultLanguages, ...props }, ref) => {
    const { t } = useIntl();

    return (
      <Select
        placeholder={t('language')}
        className={`languages-wrapper ${className}`}
        ref={ref}
        optionFilterProp="children"
        {...props}
      >
        {languages.map(item => (
          <Option key={item} value={item}>
            {t(item)}
          </Option>
        ))}
      </Select>
    );
  },
);
