import { useContext } from 'react';
import { ProfileContext } from 'contexts';
import { UserGroups } from 'lib/entities/profile';

export function useIsRestrictedTo() {
  const {
    admin: { data: adminData },
  } = useContext(ProfileContext);

  return (roles: UserGroups | UserGroups[]) => {
    if (!(roles instanceof Array)) roles = [roles];

    return adminData.groups.some(group => roles.includes(group));
  };
}
