import { createContext, useContext, useEffect, useState } from 'react';

import { withPagination } from 'lib/utils';
import { Order } from 'lib/entities/payments-management/orders';
import { PaymentInvoice } from 'lib/entities/payments-management/invoices';
import { InvoicesApi, OrdersApi } from 'lib/api';
import { useOrderOfferDetails, useOrderPaymentInfo } from 'hooks';
import { OpenedUserContext } from './OpenedUserContext';

interface OrderContextValue {
  orderPaymentInfo?: Order;
  orderOfferDetails?: Order;
  orders?: withPagination<Order>;
  invoices?: withPagination<PaymentInvoice>;
  isLoadingPayment: boolean;
  isLoadingInvoices: boolean;

  getPaymentInfo: () => Promise<void>;
  getInvoiceInfo: () => Promise<void>;
}

interface OrderProviderProps {
  userId: string;
  children: React.ReactNode;
}

const defaultValue = { count: 0, results: [], next: '', previous: '' };

export const OrderContext = createContext<OrderContextValue>({
  orders: defaultValue,
  invoices: defaultValue,

  isLoadingInvoices: false,
  isLoadingPayment: false,

  getInvoiceInfo: () => Promise.resolve(),
  getPaymentInfo: () => Promise.resolve(),
});

export const OrderProvider = ({ userId, ...props }: OrderProviderProps) => {
  const { user: contextUser } = useContext(OpenedUserContext);
  const [isLoadingPayment, setIsLoadingPayment] = useState(false);
  const [isLoadingInvoices, setIsLoadingInvoices] = useState(false);

  const [orders, setOrders] = useState<withPagination<Order>>(defaultValue);
  const [invoices, setInvoices] = useState<withPagination<PaymentInvoice>>(defaultValue);

  const getPaymentInfo = async () => {
    setIsLoadingPayment(true);
    OrdersApi.list({ user_id: userId })
      .then(res => {
        setOrders(res);
      })
      .finally(() => setIsLoadingPayment(false));
  };

  useEffect(() => {
    getPaymentInfo();
  }, [userId]);

  const getInvoiceInfo = async () => {
    setIsLoadingInvoices(true);

    InvoicesApi.list({ customer: contextUser.data?.billing_id })
      .then(res => {
        setInvoices(res);
      })
      .finally(() => setIsLoadingInvoices(false));
  };

  useEffect(() => {
    if (contextUser.data?.billing_id) {
      getInvoiceInfo();
    }
  }, [contextUser.data]);

  const orderPaymentInfo = useOrderPaymentInfo(orders.results);
  const orderOfferDetails = useOrderOfferDetails(orders.results);

  return (
    <OrderContext.Provider
      value={{
        orders,
        invoices,

        orderOfferDetails,
        orderPaymentInfo,

        isLoadingInvoices,
        isLoadingPayment,

        getInvoiceInfo,
        getPaymentInfo,
      }}
      {...props}
    />
  );
};
