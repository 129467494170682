import { Request } from '../../index';

export const Templates = {
  list: (data: object) => {
    return Request.get('/comments/admin-response-templates/', data);
  },
  create: (data: object) => {
    return Request.post('/comments/admin-response-templates/', data);
  },
  listById: (id: string, data: object) => {
    return Request.get(`/comments/admin-response-templates/${id}/`, data);
  },
  listByEpisode: (data: object) => {
    return Request.get('/comments/admin-response-templates/list_by_episode/', data);
  },
  update: (id: string, data: object) => {
    return Request.patch(`/comments/admin-response-templates/${id}/`, data);
  },
  delete: (id: string) => {
    return Request.delete(`/comments/admin-response-templates/${id}/`);
  },
};
