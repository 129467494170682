import moment from 'moment';

interface ParseJwt {
  email: string;
  exp: number;
  jti: string;
  token_type: 'access' | 'refresh';
  user_id: string;
  username: string;
}

export const parseJwt = (token?: string | null): ParseJwt | null => {
  if (!token) return null;

  try {
    return JSON.parse(atob(token.split('.')[1]));
  } catch (e) {
    return null;
  }
};

export const parseJwtExpirationTime = (time: number) => new Date(time * 1000);

export const remainedExpirationTime = (date: number) =>
  moment.duration(moment(parseJwtExpirationTime(date)).diff(moment()));
