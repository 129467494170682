import { Request } from '../../index';

export const CommentsEpisodes = {
  list: (data: object) => {
    return Request.get('/posts/dashboard/eod-comments/', data);
  },
  update: (id: number, data: {}) => {
    return Request.patch(`/posts/dashboard/eod-comments/${id}/`, data);
  },
  getReplies: (id: number) => {
    return Request.get(`/posts/dashboard/eod-comments/${id}/`);
  },
  delete: (id: number) => {
    return Request.delete(`/posts/dashboard/eod-comments/${id}/`);
  },
  createReply: (data: object, id: number) => {
    return Request.post(`/posts/dashboard/eod-comments/${id}/reply/`, data);
  },
  addToArchive: (id: number, data = {}) => {
    return Request.post(`/posts/dashboard/eod-comments/${id}/archive/`, data);
  },
  unArchive: (id: string) => {
    return Request.post(`/posts/dashboard/eod-comments/${id}/unarchive/`);
  },
  markCommentAnswered: (id: number) => {
    return Request.post(`/posts/dashboard/eod-comments/${id}/mark-as-answered/`);
  },
  setResponsible: (id: number, data = {}) => {
    return Request.post(`/posts/dashboard/eod-comments/${id}/set-responsible/`, data);
  },
};
