interface Family {
  id: string;
  name: string;
}

export enum UserGroups {
  administrator = 'administrator',
  customerCare = 'customer_care',
  moderator = 'moderator',
  contentCreator = 'content_creator',
  consilier = 'consilier',
  warehouseAdmin = 'warehouse_admin',
  salesSpecialist = 'sales_specialist',
}

export enum UserGroupsConstNames {
  administrator = 'isAdministrator',
  customer_care = 'isCustomerCare',
  moderator = 'isModerator',
  content_creator = 'isContentCreator',
  consilier = 'isConsilier',
  warehouse_admin = 'isWarehouseAdmin',
  sales_specialist = 'isSalesSpecialist',
}

export interface Profile {
  id: number;
  families: Family[];
  created_at: string;
  modified_at: string;
  email: string;
  full_name: string;
  date_of_birth: string;
  phone: string;
  role: string;
  photo: string;
  has_push_notifications: boolean;
  has_hided_my_posts: boolean;
  blocked: boolean;
  is_staff: boolean;
  regular_login: boolean;
  unread_notifications: number;
  last_activity: string;
  post_count: number;
  member_count: number;
  child_count: number;
  groups: UserGroups[];
  setup_link?: string;
  password_expires_at?: string;
}
