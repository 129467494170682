import Loadable from 'react-loadable';
import { LoadingPlaceholder } from 'ui/atoms';

import { Route } from 'lib/router/routes';

export const checkout: Route[] = [
  {
    path: '/texts-setup',
    text: 'textsSetup',
    icon: 'form',
    component: Loadable({
      loader: () => import('../../../../features/payments/texts-setup/TextsSetupPage'),
      loading: LoadingPlaceholder,
      delay: 300,
    }),
    children: [],
    groups: ['customer_care', 'moderator', 'content_creator', 'consilier'],
  },
  {
    path: '/offer/:id/notes',
    text: '',
    component: Loadable({
      loader: () => import('../../../../features/payments/notes/NotesPage'),
      loading: LoadingPlaceholder,
      delay: 300,
    }),
    children: [],
    groups: ['customer_care', 'moderator', 'consilier', 'content_creator'],
  },
];
