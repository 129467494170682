import { Request } from '../../../index';

export const OffersApi = {
  list: (data = {}, config?: RequestInit) => {
    return Request.get('/sales/dashboard/price/', data, config);
  },
  create: (data: object) => {
    return Request.post('/sales/dashboard/price/', data);
  },
  byId: (id: string) => {
    return Request.get(`/sales/dashboard/price/${id}/`);
  },
  update: (id: string, data: object) => {
    return Request.patch(`/sales/dashboard/price/${id}/`, data);
  },
  delete: (id: number) => {
    return Request.delete(`/sales/dashboard/price/${id}/`);
  },
  changeSubscriptionType: (id: number, data = {}) => {
    return Request.patch(`/sales/dashboard/price/${id}/change-subscription-type/`, data);
  },
  listPrices: (data = {}) => {
    return Request.get('/sales/dashboard/price-urls/', data);
  },
  createPrice: (data: object) => {
    return Request.post('/sales/dashboard/price-urls/', data);
  },
  byIdPrice: (id: string) => {
    return Request.get(`/sales/dashboard/price-urls/${id}/`);
  },
  updatePrice: (id: string, data: object) => {
    return Request.patch(`/sales/dashboard/price-urls/${id}/`, data);
  },
  deletePrice: (id: number) => {
    return Request.delete(`/sales/dashboard/price-urls/${id}/`);
  },
  changeAccessPeriod: (id: number, data = {}) => {
    return Request.patch(`/sales/dashboard/price/${id}/change-access-period/`, data);
  },
};
