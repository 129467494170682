import { useEffect } from 'react';
import { WrappedFormUtils } from 'antd/lib/form/Form';

export const useKeywordsSeparator = (form: WrappedFormUtils<any>) => {
  const { getFieldValue, setFieldsValue } = form;

  const keywords = getFieldValue('keywords');

  useEffect(() => {
    const onKeywordsChange = () => {
      const mustBeSplit = keywords?.find((i: string) => i.includes(','));

      if (keywords && mustBeSplit) {
        setFieldsValue({
          keywords: keywords.flatMap((i: string) => (i.includes(',') ? i.split(',').map(i => i.trim()) : i)),
        });
      }
    };

    onKeywordsChange();
  }, [keywords]);
};
