import { $Object } from 'lib/object';
import { capitalize } from 'lib/utils';

interface Props {
  errors: string | $Object;
}

export const ShowErrors = ({ errors }: Props) => {
  return (
    <div className="error-div">
      {typeof errors === 'string'
        ? errors
        : Object.keys(errors).map(i => (
            <div>
              {capitalize(i)} : {errors[i]}
            </div>
          ))}
    </div>
  );
};
