import { Request } from '../../index';
import { warehouseURL } from '../../request';
import { addToURL } from '../../utils';

export const WarehouseApi = {
  inventory: (data: object) => {
    return Request.get(`${warehouseURL}/analytics/product-stock-summary/`, data);
  },
  giftsList: (data: object) => {
    return Request.get(`${warehouseURL}/documents/order/items-list/`, data);
  },
  updateStock: (data: object) => {
    return Request.post(`${warehouseURL}/documents/receipt-products/new/`, data);
  },
  deleteDelivery: (id: number, data = {}) => {
    return Request.delete(`${warehouseURL}/documents/delivery/${id}/remove/`, data);
  },
  createAWB: (data: object, params = {}) => {
    return Request.post(addToURL(`${warehouseURL}/documents/order/create-deliveries-task/`, params), data);
  },
  setVerifiedAddresses: (id: string, data: object) => {
    return Request.post(`${warehouseURL}/documents/order/items-list/${id}/address-verified`, data);
  },
  productList: (data = {}) => {
    return Request.get(`${warehouseURL}/products/`, data);
  },
  providersList: (data: object) => {
    return Request.get(`${warehouseURL}/delivery/providers/`, data);
  },
  returnDelivery: (id: string, data: object = {}) => {
    return Request.post(`${warehouseURL}/documents/delivery/${id}/provider-return/`, data);
  },
  getCommonExportSettings: () => {
    return Request.get(`${warehouseURL}/common/export-settings/`);
  },
  updateCommonExportSettings: (data: object) => {
    return Request.patch(`${warehouseURL}/common/export-settings/`, data);
  },
  getOrdersStatistic: (data = {}) => {
    return Request.get(`${warehouseURL}/documents/order/items-list/statistics/`, data);
  },
  deactivateDelivery: (id: number, data: object = {}) => {
    return Request.post(`${warehouseURL}/documents/order/${id}/disable/`, data);
  },
  inventoryItemDetails: (data = {}) => {
    return Request.get(`${warehouseURL}/analytics/product-grouped-flows/`, data);
  },
  correctStock: (data = {}) => {
    return Request.post(`${warehouseURL}/documents/correct/new/`, data);
  },
  deleteDeliveryTasks: (data = {}, params = {}) => {
    return Request.post(addToURL(`${warehouseURL}/documents/order/delete-deliveries-task/`, params), data);
  },
  getOriginalAwb: (id: number) => {
    return Request.get(`${warehouseURL}/documents/delivery/${id}/`);
  },
};
