import { Request } from '../../../index';

export const SalesPagesApi = {
  list: (data = {}) => {
    return Request.get('/sales/dashboard/sales-pages/', data);
  },
  create: (data: object) => {
    return Request.post('/sales/dashboard/sales-pages/', data);
  },
  byId: (id: number) => {
    return Request.get(`/sales/dashboard/sales-pages/${id}/`);
  },
  update: (id: number, data: object) => {
    return Request.patch(`/sales/dashboard/sales-pages/${id}/`, data);
  },
  delete: (id: number) => {
    return Request.delete(`/sales/dashboard/sales-pages/${id}/`);
  },
};
