import Loadable from 'react-loadable';
import { LoadingPlaceholder } from 'ui/atoms';

import { Route } from 'lib/router/routes';

export const users: Route[] = [
  {
    path: '/users',
    text: 'users',
    icon: 'user',
    children: [
      {
        path: '/users',
        text: 'users',
        icon: 'user',
        component: Loadable({
          loader: () => import('../../../../features/users/UsersPage'),
          loading: LoadingPlaceholder,
          delay: 300,
        }),
        children: [],
        groups: ['content_creator', 'sales_specialist'],
      },
      {
        path: '/admin-users',
        text: 'adminUsers',
        icon: 'setting',
        component: Loadable({
          loader: () => import('../../../../features/users/admin-users/AdminUsersPage'),
          loading: LoadingPlaceholder,
          delay: 300,
        }),
        children: [],
        groups: ['customer_care', 'moderator', 'content_creator', 'consilier', 'sales_specialist', 'warehouse_admin'],
      },

      {
        path: '/invited-crm-users',
        text: 'crmInvited',
        icon: 'usergroup-add',
        component: Loadable({
          loader: () => import('../../../../features/users/invited-crm-users/InvitedCRMUsers'),
          loading: LoadingPlaceholder,
          delay: 300,
        }),
        children: [],
        groups: ['content_creator', 'moderator', 'sales_specialist'],
      },
      {
        path: '/users-retention',
        text: 'usersRetention',
        icon: 'smile',
        component: Loadable({
          loader: () => import('../../../../features/users/users-retention/UsersRetentionPage/UsersRetentionPage'),
          loading: LoadingPlaceholder,
          delay: 300,
        }),
        children: [],
        groups: ['content_creator', 'sales_specialist'],
      },
      {
        path: '/deleted-users',
        text: 'deletedUsers',
        icon: 'usergroup-delete',
        component: Loadable({
          loader: () => import('../../../../features/users/deletedUsers/DeletedUsersPage'),
          loading: LoadingPlaceholder,
          delay: 300,
        }),
        children: [],
        groups: ['content_creator', 'sales_specialist', 'moderator'],
      },
      {
        path: '/blacklisted-countries',
        text: 'blacklistedCountries',
        icon: 'exception',
        component: Loadable({
          loader: () => import('../../../../features/users/blacklisted-countries/BlacklistedCountriesPage'),
          loading: LoadingPlaceholder,
          delay: 300,
        }),
        children: [],
        groups: ['moderator', 'content_creator', 'consilier', 'sales_specialist', 'warehouse_admin'],
      },
    ],
    groups: ['content_creator', 'sales_specialist'],
  },
  {
    path: '/users/add',
    text: null,
    icon: 'question-circle',
    component: Loadable({
      loader: () => import('../../../../features/users/AddUserPage'),
      loading: LoadingPlaceholder,
      delay: 300,
    }),
    children: [],
    groups: ['sales_specialist', 'content_creator'],
  },
  {
    path: '/admin-users/add',
    text: null,
    component: Loadable({
      loader: () => import('../../../../features/users/admin-users/AddAdminPage'),
      loading: LoadingPlaceholder,
      delay: 300,
    }),
    children: [],
    groups: ['sales_specialist', 'warehouse_admin', 'content_creator'],
  },
  {
    path: '/admin-users/:id/edit',
    text: null,
    component: Loadable({
      loader: () => import('../../../../features/users/admin-users/EditAdminPage'),
      loading: LoadingPlaceholder,
      delay: 300,
    }),
    children: [],
    groups: ['sales_specialist', 'warehouse_admin', 'content_creator'],
  },
  {
    path: '/users/edit/:id',
    text: null,
    icon: 'question-circle',
    component: Loadable({
      loader: () => import('../../../../features/users/EditUserPage'),
      loading: LoadingPlaceholder,
      delay: 300,
    }),
    children: [],
    groups: ['sales_specialist', 'content_creator'],
  },
  {
    path: '/users/detail/:id',
    text: null,
    icon: 'question-circle',
    component: Loadable({
      loader: () => import('../../../../features/users/UsersDetailPage'),
      loading: LoadingPlaceholder,
      delay: 300,
    }),
    children: [],
    groups: ['sales_specialist', 'content_creator'],
  },
  {
    path: '/users/:userId/subscriptions',
    text: null,
    icon: undefined,
    component: Loadable({
      loader: () => import('../../../../features/users/UserSubscriptionsPage'),
      loading: LoadingPlaceholder,
      delay: 300,
    }),
    children: [],
    groups: ['sales_specialist', 'content_creator'],
  },
  {
    path: '/user-assignment/add',
    text: null,
    component: Loadable({
      loader: () => import('../../../../features/users/responsible-groups/user-assignment/ResponsibleGroupsAddEdit'),
      loading: LoadingPlaceholder,
      delay: 300,
    }),
    children: [],
    groups: ['customer_care', 'moderator', 'content_creator', 'consilier', 'warehouse_admin', 'sales_specialist'],
  },
  {
    path: '/user-assignment/:id/edit',
    text: null,
    component: Loadable({
      loader: () => import('../../../../features/users/responsible-groups/user-assignment/ResponsibleGroupsAddEdit'),
      loading: LoadingPlaceholder,
      delay: 300,
    }),
    children: [],
    groups: ['customer_care', 'moderator', 'content_creator', 'consilier', 'warehouse_admin', 'sales_specialist'],
  },
  {
    path: '/user-responsible-groups/add',
    text: null,
    component: Loadable({
      loader: () => import('../../../../features/users/responsible-groups/groups/GroupsAddEdit'),
      loading: LoadingPlaceholder,
      delay: 300,
    }),
    children: [],
    groups: ['customer_care', 'moderator', 'content_creator', 'consilier', 'warehouse_admin'],
  },
  {
    path: '/user-responsible-groups/:id/edit',
    text: null,
    component: Loadable({
      loader: () => import('../../../../features/users/responsible-groups/groups/GroupsAddEdit'),
      loading: LoadingPlaceholder,
      delay: 300,
    }),
    children: [],
    groups: ['customer_care', 'moderator', 'content_creator', 'consilier', 'warehouse_admin', 'sales_specialist'],
  },
  {
    path: '/user-task-statuses/add',
    text: null,
    component: Loadable({
      loader: () => import('../../../../features/users/responsible-groups/statuses/StatusesAddEdit'),
      loading: LoadingPlaceholder,
      delay: 300,
    }),
    children: [],
    groups: ['customer_care', 'moderator', 'content_creator', 'consilier', 'warehouse_admin', 'sales_specialist'],
  },
  {
    path: '/user-task-statuses/:id/edit',
    text: null,
    component: Loadable({
      loader: () => import('../../../../features/users/responsible-groups/statuses/StatusesAddEdit'),
      loading: LoadingPlaceholder,
      delay: 300,
    }),
    children: [],
    groups: ['customer_care', 'moderator', 'content_creator', 'consilier', 'warehouse_admin', 'sales_specialist'],
  },
  {
    path: '/blacklisted-countries/:phone',
    text: null,
    component: Loadable({
      loader: () => import('../../../../features/users/blacklisted-countries/BlacklistedPhonesPage'),
      loading: LoadingPlaceholder,
      delay: 300,
    }),
    children: [],
    groups: ['moderator', 'content_creator', 'consilier', 'sales_specialist', 'warehouse_admin'],
  },
];
