import React, { useEffect, useState } from 'react';

import { Layout } from 'antd';
import HeaderLayout from './header';
import SiderLayout from './sider';
import { ProfileContext, SiderContext } from 'contexts';
import { useScreenBreakPoint } from 'hooks';

const { Content } = Layout;

/**
 * Render main dashboard Layout which contains sidebar with routes + content route based
 * @example
 *
 *    <DefaultLayout>
 *      <OtherComponent />
 *    </DefaultLayout>
 *
 */
const DefaultLayout: React.FC = ({ children }) => {
  const defaultCollapsed = localStorage.getItem('collapsed');
  const [collapsed, setCollapsed] = useState<boolean>(defaultCollapsed ? JSON.parse(defaultCollapsed) : false);

  const isLaptop = useScreenBreakPoint('laptop');

  useEffect(() => {
    if (!isLaptop) return;
    setCollapsed(isLaptop);
  }, [isLaptop]);

  useEffect(() => {
    localStorage.setItem('collapsed', JSON.stringify(collapsed));
  }, [collapsed]);

  return (
    <Layout>
      <ProfileContext.Consumer>
        {({ admin: { data: adminData } }) => (
          <SiderContext.Provider value={[collapsed, setCollapsed]}>
            <SiderLayout collapsed={collapsed} setCollapsed={setCollapsed} profile={adminData} />
            <Layout>
              <HeaderLayout collapsed={collapsed} setCollapsed={setCollapsed} profile={adminData} />

              <Content className="main-content">{children}</Content>
            </Layout>
          </SiderContext.Provider>
        )}
      </ProfileContext.Consumer>
    </Layout>
  );
};

export default DefaultLayout;
